import { combineReducers } from "redux";
import landingPage from "../reducers/landingPage/landingPage";
import testDBReducer from "../reducers/testDBOperation/testDBReducer";
import selectWorkspaceReducer from "../reducers/selectWorkspace/workspaceReducer";
import dataOperationReducer from "../reducers/dataOperation/dataOperationReducer";
import dbOperationReducer from "../reducers/dbOperation/dbOperationReducer";
import authenticationReducer from "../reducers/authentication/authenticationReducer";
import sidebarMenuReducer from "../reducers/sidebarMenu/sidebarMenuReducer";
import queryManagerReducer from "./queryManager/queryManagerReducer";

export const appReducer = combineReducers({
  landingPage: landingPage,
  testDBdata: testDBReducer,
  testDBConnectionStatus: testDBReducer,
  selectWorkspace: selectWorkspaceReducer,
  searchOperation: dataOperationReducer,
  userAuthentication: authenticationReducer,
  tokenExpiration: authenticationReducer,
  dbProfileResult: dbOperationReducer,
  sidebarData: sidebarMenuReducer,
  queryManagerData: queryManagerReducer,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state: any, action: any) => {
  if (action.type === "LOGOUT") {
    state = {};
  }

  return appReducer(state, action);
};

export default rootReducer;
