/**Imports of React libraries */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
/**Imports of Reusable Components */
import { RootState } from "../../store/reducers/rootReducer";
/**Imports of Material UI Elements */
import {
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
/**Imports of API Actions */
import { generateData } from "../../../src/store/actions/testDBOperation/testDBAction";
/**Imports of SVG Icons */
import CheckedIconSvg from "../../assets/images/avatars/checked.svg";
import { ReactComponent as CrossIconSvg } from "../../assets/images/avatars/cross.svg";
import WorkProcess from "../../assets/images/avatars/work-process.svg";
/**Import of Config file */
import { config } from "../../config/clientConfig";
import { generateDataLog, logLevel } from "../../config/logConfig";
/**Import of Logger files */
import { logFunctionStart, logFunctionEnd, writeLog } from "../../logManager";

function GenerateData() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isGenerate, setisGenerate] = React.useState(false);
  const [generateSuccess, setgenerateSuccess] = React.useState(false);
  const [generateError, setgenerateError] = React.useState(false);
  const selectedWorkspace = useSelector(
    (state: RootState) => state.selectWorkspace.currentWorkspace
  );
  const datasource = useSelector(
    (state: RootState) => state.selectWorkspace.dataSourceValue
  );
  const apikey = useSelector(
    (state: RootState) => state.selectWorkspace.apikey
  );
  const isVerify = useSelector(
    (state: RootState) => state.selectWorkspace.isVerify
  );
  const generatedata = useSelector(
    (state: RootState) => state.testDBdata.generateData
  );
  const currentLogLevel = useSelector(
    (state: RootState) => state.landingPage.currentLogLevel
  );

  /**Function call API for Generate Data*/
  const handleGenerateData = async () => {
    const startTime = logFunctionStart(currentLogLevel, "handleGenerateData");
    try {
      const generatedata: any = await dispatch(
        generateData(apikey, selectedWorkspace)
      );
      if (
        generatedata !== undefined &&
        Object.keys(generatedata).length !== 0 &&
        generatedata.data !== undefined &&
        generatedata.messageCode === config.successCode
      ) {
        setgenerateSuccess(true);
        setgenerateError(false);
        writeLog(
          logLevel.Information,
          currentLogLevel,
          generatedata.userMessage,
          generateDataLog.generateData
        );
      } else {
        setgenerateSuccess(false);
        setgenerateError(false);
        writeLog(
          logLevel.Error,
          currentLogLevel,
          generatedata.userMessage,
          generateDataLog.generateData
        );
      }
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleGenerateData");
    }
  };

  /**Function to close confirmation popup message*/
  const closeGenerateData = () => {
    const startTime = logFunctionStart(currentLogLevel, "closeGenerateData");
    try {
      setisGenerate(false);
      setgenerateError(false);
      setgenerateSuccess(false);
      writeLog(
        logLevel.Information,
        currentLogLevel,
        "close Generate Data confirmation popup",
        generateDataLog.closepopup
      );
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "closeGenerateData");
    }
  };

  /**Function to open Confirmation popup*/
  const GenerateDataConfirmation = () => {
    const startTime = logFunctionStart(
      currentLogLevel,
      "GenerateDataConfirmation"
    );
    try {
      setisGenerate(true);
      writeLog(
        logLevel.Information,
        currentLogLevel,
        "open Confirmation popup",
        generateDataLog.GDconfirmation
      );
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "GenerateDataConfirmation");
    }
  };

  return (
    <div>
      <Paper className="paper">
        <div className="GB-AC">
          <Grid className="center-align">
            <label className="label1">
              {intl.formatMessage({ id: "Workspace" })}
            </label>
            <TextField
              className="form-input"
              disabled
              id="outlined"
              defaultValue={selectedWorkspace.fingerprintName}
              variant="outlined"
            />
          </Grid>
          <label className="label2">
            {intl.formatMessage({ id: "Datasource" })}
          </label>
          {isVerify &&
          datasource !== undefined &&
          Object.keys(datasource).length > 0 ? (
            <>
              <div
                style={{ overflowY: "scroll" }}
                className="key-comments-data"
              >
                <div>
                  <Typography className="key-comments-value">
                    sourceDatabase{" "}
                  </Typography>
                  <Typography className="key-comments-key">
                    ID :{" "}
                    <span className="key-comments-value">
                      {datasource[0].sourceDatabase.id}
                    </span>{" "}
                  </Typography>
                  <Typography className="key-comments-key">
                    Server:{" "}
                    <span className="key-comments-value">
                      {datasource[0].sourceDatabase.server}{" "}
                    </span>
                  </Typography>
                  <Typography className="key-comments-key">
                    username:{" "}
                    <span className="key-comments-value">
                      {datasource[0].sourceDatabase.username}
                    </span>{" "}
                  </Typography>
                  <Typography className="key-comments-key">
                    password:{" "}
                    <span className="key-comments-value">
                      {datasource[0].sourceDatabase.password}
                    </span>{" "}
                  </Typography>
                  <Typography className="key-comments-key">
                    port:{" "}
                    <span className="key-comments-value">
                      {datasource[0].sourceDatabase.port}
                    </span>{" "}
                  </Typography>
                  <Typography className="key-comments-key">
                    database:{" "}
                    <span className="key-comments-value">
                      {datasource[0].sourceDatabase.database}
                    </span>{" "}
                  </Typography>
                  <Typography className="key-comments-key">
                    databaseType:{" "}
                    <span className="key-comments-value">
                      {datasource[0].sourceDatabase.databaseType}
                    </span>{" "}
                  </Typography>
                  <Typography className="key-comments-key">
                    <span className="key-comments-value">
                      destinationDatabase
                    </span>{" "}
                  </Typography>
                  <Typography className="key-comments-key">
                    ID :{" "}
                    <span className="key-comments-value">
                      {datasource[0].destinationDatabase.id}
                    </span>{" "}
                  </Typography>
                  <Typography className="key-comments-key">
                    Server:{" "}
                    <span className="key-comments-value">
                      {datasource[0].destinationDatabase.server}
                    </span>{" "}
                  </Typography>
                  <Typography className="key-comments-key">
                    username:{" "}
                    <span className="key-comments-value">
                      {datasource[0].destinationDatabase.username}
                    </span>{" "}
                  </Typography>
                  <Typography className="key-comments-key">
                    password:{" "}
                    <span className="key-comments-value">
                      {datasource[0].destinationDatabase.password}
                    </span>{" "}
                  </Typography>
                  <Typography className="key-comments-key">
                    port:{" "}
                    <span className="key-comments-value">
                      {datasource[0].destinationDatabase.port}{" "}
                    </span>
                  </Typography>
                  <Typography className="key-comments-key">
                    database:{" "}
                    <span className="key-comments-value">
                      {datasource[0].destinationDatabase.database}
                    </span>{" "}
                  </Typography>
                  <Typography className="key-comments-key">
                    databaseType:{" "}
                    <span className="key-comments-value">
                      {datasource[0].destinationDatabase.databaseType}
                    </span>{" "}
                  </Typography>
                </div>
              </div>
              <div className="generate-button">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={GenerateDataConfirmation}
                >
                  {intl.formatMessage({ id: "GenerateData" })}
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="empty-datasource "></div>
              <div className="generate-button">
                <Button variant="contained" color="primary" disabled={true}>
                  {intl.formatMessage({ id: "GenerateData" })}
                </Button>
              </div>
            </>
          )}
        </div>
      </Paper>
      <div>
        <Dialog
          open={isGenerate}
          onClose={closeGenerateData}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className="popup">
            <DialogContentText id="alert-dialog-description">
              <img src={WorkProcess} alt="" className="delete-disable" />
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              {intl.formatMessage({ id: "GenerateConfirmation" })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleGenerateData} variant="contained">
              {intl.formatMessage({ id: "Yes" })}
            </Button>
            <Button
              onClick={closeGenerateData}
              color="primary"
              variant="contained"
              className="reindexpop"
            >
              {intl.formatMessage({ id: "No" })}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={generateSuccess}
          onClose={closeGenerateData}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className="popup">
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <img src={CheckedIconSvg} alt="" className="delete-disable" />
              </DialogContentText>
            </DialogContent>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <DialogTitle className="success-title">
                  <span>{intl.formatMessage({ id: "Success" })}</span>
                </DialogTitle>
              </DialogContentText>
              <DialogContentText
                id="alert-dialog-description"
                className="jobid"
              >
                {intl.formatMessage({ id: "JobID" })} {generatedata.data}
              </DialogContentText>
              <DialogContentText id="alert-dialog-description">
                {intl.formatMessage({ id: "GenerateSuccess" })}
              </DialogContentText>
            </DialogContent>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={closeGenerateData}
              color="primary"
              variant="contained"
              className="reindexpop"
            >
              {intl.formatMessage({ id: "OK" })}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={generateError}
          onClose={closeGenerateData}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className="popup">
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <CrossIconSvg className="delete-disable" />
              </DialogContentText>
            </DialogContent>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {intl.formatMessage({ id: "Failed" })}
              </DialogContentText>
              <DialogContentText id="alert-dialog-description">
                {intl.formatMessage({ id: "GenerateFailed" })}
              </DialogContentText>
            </DialogContent>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={closeGenerateData}
              color="primary"
              variant="contained"
              className="reindexpop"
            >
              {intl.formatMessage({ id: "OK" })}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default GenerateData;
