/**Imports of React libraries */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { useIntl } from "react-intl";
import { withRouter } from "react-router-dom";
/**Imports of Reusable Components */
import { RootState } from "../../store/reducers/rootReducer";
/**Imports of Material UI Elements */
import {
  Grid,
  Paper,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Typography,
  FormControl,
  InputAdornment,
  IconButton,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormGroup,
  FormControlLabel,
  TableSortLabel,
} from "@material-ui/core";
import { Autocomplete, Pagination } from "@material-ui/lab";
/**Imports of API Actions */
import {
  searchTestDb,
  inmemoryClearSearch,
} from "../../store/actions/dataOperation/dataOperationAction";
/**Imports of Material UI Icons */
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
/**Imports of SVG Icons */
import { ReactComponent as SearchIconSvg } from "../../assets/images/avatars/search-icon.svg";
import { ReactComponent as DownloadIconSvg } from "../../assets/images/avatars/download-icon.svg";
import { ReactComponent as NoDataSvg } from "../../assets/images/avatars/nodata.svg";
import { ReactComponent as NoDataArrowSvg } from "../../assets/images/avatars/nodata-arrow.svg";
/**Import of Config file */
import { search, facet } from "../../../src/config/configFacets.json";
import { searchLog, logLevel } from "../../config/logConfig";
/**Import of Logger files */
import { logFunctionStart, logFunctionEnd, writeLog } from "../../logManager";

/**Global Variables Declaration*/
let searchResultData = [] as Array<string>;
let facets = [] as Array<string>;
let facet2drill = [] as Array<number>;
let f2temp = [] as Array<number>;
let facetLists = [] as Array<number>;
let facetKeys = [] as Array<number>;
let selectFacets = [] as Array<number>;
let selectedFacet: any = {};
let checkedFacets = [] as Array<number>;
let searchCategory = "" as string;

function SearchOperation() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [checkedFacet, setCheckedFacet] = React.useState([] as Array<number>);
  const [page, setpage] = React.useState(0);
  const [rowsPerPage] = React.useState(25);
  const [searchkey, setsearchkey] = React.useState("");
  const [facet1st, setfacet1st] = React.useState([] as Array<string>);
  const [facet2, setfacet2] = React.useState([] as Array<number>);
  const [expanded, setexpanded] = React.useState("");
  const [facetArr, setfacetArr] = React.useState([] as Array<number>);
  const [searchResults, setsearchResults] = React.useState([]);
  const [selectFacetKey, setselectFacetKey] = React.useState("");
  const [order, setOrder] = React.useState("asc" as any);
  const [orderBy, setOrderBy] = React.useState("");
  const [categoryName, setcategoryName] = React.useState("");
  const currentLogLevel = useSelector(
    (state: RootState) => state.landingPage.currentLogLevel
  );

  /**Function to Change Category List and dynamic facet handling*/
  const handleCategories = async (event: string) => {
    const startTime = logFunctionStart(currentLogLevel, "handleCategories");
    try {
      facets = [];
      facet2drill = [];
      facetLists = [];
      selectFacets = [];
      selectedFacet = {};
      checkedFacets = [];
      setfacetArr([]);
      searchCategory = event;
      setcategoryName(event);
      if (facet.length > 0) {
        facet.forEach(async (obj: any) => {
          if (Object.keys(obj)[0] === searchCategory) {
            facet2drill = obj[searchCategory];
            obj[searchCategory].map((keys: any) => {
              facets.push(Object.keys(keys)[0]);
            });
            await setfacet1st(facets);
            writeLog(
              logLevel.Information,
              currentLogLevel,
              "Change Category List and dynamic facet handling",
              searchLog.handleCategories
            );
          }
        });
      }
      return false;
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleCategories");
    }
  };

  /**Function to Expansion panel and next level facets options*/
  const handleExpansion =
    (panel: string) => (event: any, isExpanded: boolean) => {
      const startTime = logFunctionStart(currentLogLevel, "handleExpansion");
      try {
        setselectFacetKey(panel);
        if (isExpanded) {
          setexpanded(panel);
          f2temp = [];
          if (facet2drill.length > 0) {
            facet2drill.forEach(async (obj: any) => {
              if (Object.keys(obj)[0] === panel) {
                obj[panel].map((keys: any) => {
                  f2temp.push(keys);
                });
                await setfacet2(f2temp);
              }
            });
          }
        } else {
          setexpanded("");
        }
        writeLog(
          logLevel.Information,
          currentLogLevel,
          "Expansion panel and next level facets options functionality",
          searchLog.handleExpansion
        );
      } finally {
        logFunctionEnd(currentLogLevel, startTime, "handleExpansion");
      }
    };

  /**Function to handle search functionality in UI*/
  const onChangeSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const startTime = logFunctionStart(currentLogLevel, "onChangeSearch");
    try {
      event.preventDefault();
      setsearchkey(event.target.value);
      writeLog(
        logLevel.Information,
        currentLogLevel,
        "Caturing Search Keyword",
        searchLog.onChangeSearch
      );
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "onChangeSearch");
    }
  };

  /**Function to handle checkbox enable/disable*/
  const isSelected = (facet: string, val: number) => {
    if (checkedFacets.length > 0) {
      if (checkedFacets !== undefined && checkedFacets.includes(val)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  /**Function to handle facets logics*/
  const handleFacet = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    facet: number
  ) => {
    let newSelected = [] as Array<number>;
    let newcheck = [] as Array<number>;
    const Selected = [] as Array<number>;
    const keys = [] as Array<string>;
    const startTime = logFunctionStart(currentLogLevel, "handleFacet");
    try {
      if (selectFacets.length > 0) {
        selectFacets.forEach((obj: any) => {
          keys.push(Object.keys(obj)[0]);
        });
        if (!keys.includes(selectFacetKey)) {
          facetLists = [];
        } else {
          selectFacets.forEach((obj: any) => {
            if (Object.keys(obj)[0] === selectFacetKey) {
              facetLists = obj[selectFacetKey];
            }
          });
        }
      }
      const selectedIndex = facetLists.indexOf(facet);
      const checkedIndex = checkedFacets.indexOf(facet);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(Selected, facet);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(facetLists.slice(1));
        facetLists = [];
      } else if (selectedIndex === facetLists.length - 1) {
        newSelected = newSelected.concat(facetLists.slice(0, -1));
        facetLists = [];
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          facetLists.slice(0, selectedIndex),
          facetLists.slice(selectedIndex + 1)
        );
        facetLists = [];
      }
      if (checkedIndex !== -1) {
        newcheck = newcheck.concat(
          checkedFacets.slice(0, checkedIndex),
          checkedFacets.slice(checkedIndex + 1, checkedFacets.length)
        );
        checkedFacets = newcheck;
      }
      facetLists = facetLists.concat(newSelected);
      facetKeys = [];
      facetKeys = facetLists.filter(function (obj: any) {
        return checkedFacets.indexOf(obj) === -1;
      });
      checkedFacets = checkedFacets.concat(facetKeys);
      if (selectFacets.length > 0) {
        if (keys.includes(selectFacetKey)) {
          selectFacets.forEach((obj: any, index: number) => {
            if (Object.keys(obj)[0] === selectFacetKey) {
              if (facetLists.length > 0) {
                obj[selectFacetKey] = facetLists;
              } else {
                selectFacets.splice(index, 1);
              }
            }
          });
        } else {
          selectedFacet[selectFacetKey] = facetLists;
          selectFacets.push(selectedFacet);
          selectedFacet = {};
        }
      } else {
        selectedFacet[selectFacetKey] = facetLists;
        selectFacets.push(selectedFacet);
        selectedFacet = {};
      }
      setfacetArr(selectFacets);
      setCheckedFacet(checkedFacets);
      writeLog(
        logLevel.Information,
        currentLogLevel,
        "Select/Unselect Facets Options",
        searchLog.handleFacet
      );
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleFacet");
    }
  };

  /**Function to call Search API */
  const handleSearch = async (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    searchkey: string
  ) => {
    searchResultData = [];
    let searchresults: any = [];
    const startTime = logFunctionStart(currentLogLevel, "handleSearch");
    try {
      if (categoryName === "") {
        searchresults = await dispatch(inmemoryClearSearch());
      } else {
        searchresults = await dispatch(
          searchTestDb(searchCategory, facetArr, searchkey)
        );
        if (searchresults !== undefined) {
          if (
            Object.keys(searchresults).length !== undefined &&
            searchresults.data1.docs !== undefined &&
            searchresults.data1.docs.length > 0
          ) {
            searchResultData = Object.keys(searchresults.data1.docs[0]);
            setsearchResults(searchresults.data1.docs);
            writeLog(
              logLevel.Information,
              currentLogLevel,
              searchresults.userMessage,
              searchLog.handleSearch
            );
          } else {
            setsearchResults([]);
            writeLog(
              logLevel.Error,
              currentLogLevel,
              searchresults.userMessage,
              searchLog.handleSearch
            );
          }
        }
      }
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleSearch");
    }
  };

  /**Function to handle page in pagination */
  const handleChangePage = (event: any, newPage: number) => {
    setpage(newPage - 1);
  };

  /**Function for Search Table sorting by asc/dsc*/
  const createSortHandler = (event: string) => () => {
    const isAsc = orderBy === event && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(event);
  };

  /**Default Material UI Function used for Search Table sorting by asc/dsc*/
  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  /**Default Material UI Function used for Search Table sorting by asc/dsc*/
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  type Order = "asc" | "desc";

  /**Default Material UI Function used for Search Table sorting by asc/dsc*/
  function getComparator<Key extends string | number | symbol>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  return (
    <div>
      <div className="container3">
        <div className="search-container3">
          <Grid container>
            <Grid xs={12} md={2} className="search-dropdown">
              <FormControl>
                <Autocomplete
                  key={`selectCategory-${intl.formatMessage({
                    id: "SelectCategory",
                  })}`}
                  id="combo-box-demo"
                  options={search.categories}
                  defaultValue={
                    categoryName === ""
                      ? intl.formatMessage({ id: "SelectCategory" })
                      : categoryName
                  }
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        "data-testid": "category",
                      }}
                    />
                  )}
                  onChange={(event: any, newValue: string) => {
                    handleCategories(newValue);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} md={5}>
              <TextField
                className="con3-right"
                value={searchkey}
                variant="outlined"
                type={"text"}
                placeholder={intl.formatMessage({ id: "SearchBy" })}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => onChangeSearch(event)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className="search"
                        aria-label="search"
                        edge="end"
                      >
                        <SearchIconSvg
                          data-testid="search"
                          onClick={(
                            event: React.MouseEvent<SVGSVGElement, MouseEvent>
                          ) => handleSearch(event, searchkey)}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid xs={12} md={5} container justifyContent="flex-end">
              <div className="DO-search-DB">
                {searchResults !== undefined &&
                searchResults !== null &&
                searchResults.length > 0 ? (
                  <CSVLink
                    data={searchResults}
                    filename={"SearchResult.csv"}
                    className="btn btn-primary download-icon"
                    target="_blank"
                  >
                    {" "}
                    <DownloadIconSvg />{" "}
                    <p>{intl.formatMessage({ id: "DownloadCSV" })}</p>
                  </CSVLink>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="container4">
        <Grid container justifyContent="space-between">
          <Grid xs={12} md={2}>
            <div className="container4-left">
              <Card className="panel">
                {facet1st.length > 0 ? (
                  <>
                    {facet1st.map((facet: string, index: number) => (
                      <Accordion
                        className="ExpansionPanel"
                        key={index}
                        expanded={expanded === facet}
                        data-testid="facet1"
                        onChange={handleExpansion(facet)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon className="expandicon" />}
                          IconButtonProps={{ edge: "start" }}
                        >
                          <Grid item xs={1}></Grid>
                          <Typography>{facet}</Typography>
                        </AccordionSummary>
                        {facet2.map((val: number, index1: number) => (
                          <AccordionDetails
                            key={index1}
                            className="facet-option"
                          >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value="checkedB"
                                    color="primary"
                                    checked={isSelected(facet, val)}
                                    data-testid="facet2"
                                    onClick={(
                                      e: React.MouseEvent<
                                        HTMLButtonElement,
                                        MouseEvent
                                      >
                                    ) => handleFacet(e, val)}
                                  />
                                }
                                label={val}
                              />
                            </FormGroup>
                          </AccordionDetails>
                        ))}
                      </Accordion>
                    ))}
                  </>
                ) : null}
              </Card>
            </div>
          </Grid>
          <Grid xs={12} md={10}>
            <div className="px-10">
              <Paper className="container4-right">
                {searchResults.length > 0 &&
                searchResults !== null &&
                searchResultData.length > 0 ? (
                  <Grid>
                    <Grid>
                      {
                        <TableContainer className="searchtable">
                          <Table
                            stickyHeader
                            aria-label="sticky table"
                            data-testid="searchTable"
                          >
                            <TableHead>
                              <TableRow>
                                {searchResults !== undefined &&
                                searchResults !== null ? (
                                  <>
                                    {searchResultData.map((data: string) => (
                                      <TableCell
                                        align="right"
                                        key={data}
                                        sortDirection={false}
                                      >
                                        <TableSortLabel
                                          active={orderBy === data}
                                          direction={
                                            orderBy === data ? order : "asc"
                                          }
                                          data-testid="sorting"
                                          onClick={createSortHandler(data)}
                                        ></TableSortLabel>
                                        {data}
                                      </TableCell>
                                    ))}
                                  </>
                                ) : null}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {stableSort(
                                searchResults,
                                getComparator(order, orderBy)
                              )
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row: any, index: number) => (
                                  <TableRow
                                    key={index}
                                    style={
                                      index % 2
                                        ? { background: "rgba(0, 0, 0, 0.02)" }
                                        : { background: "rgba(0, 0, 0, 0.08)" }
                                    }
                                  >
                                    {searchResultData.map(
                                      (cell: string, index1: number) => (
                                        <TableCell align="right" key={index1}>
                                          {row[cell]}
                                        </TableCell>
                                      )
                                    )}
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      }
                      <div className="pagination">
                        <Typography className="paginationtext">
                          <span>
                            {page * rowsPerPage + 1} {"-"}{" "}
                            {page * rowsPerPage + rowsPerPage <
                            searchResults.length ? (
                              <>{page * rowsPerPage + rowsPerPage}</>
                            ) : (
                              <>{searchResults.length}</>
                            )}
                          </span>
                          <p>{intl.formatMessage({ id: "of" })}</p>
                          <p>{searchResults.length}</p>
                        </Typography>
                        <Pagination
                          count={Math.ceil(searchResults.length / rowsPerPage)}
                          siblingCount={2}
                          variant="outlined"
                          shape="rounded"
                          data-testid="pagination"
                          onChange={handleChangePage}
                        />
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <div className="nodata-main">
                    <div className="nodata">
                      <NoDataArrowSvg />
                      <NoDataSvg />
                    </div>
                    <span>
                      <p>
                        {intl.formatMessage({ id: "NoData" })}{" "}
                        {intl.formatMessage({ id: "Please" })}
                      </p>
                      <p className="font">
                        {intl.formatMessage({ id: "SelectCategory" })}
                      </p>
                    </span>
                  </div>
                )}
              </Paper>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default SearchOperation;
