/**Imports of React libraries */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
/**Imports of Reusable Components */
import { RootState } from "../../store/reducers/rootReducer";
import SelectWorkspace from "../selectworkspace/selectworkspace";
import GenerateData from "../DBOperation/generateData";
import ViewJobs from "../DBOperation/viewJobs";
import TonicAiLandingPage from "./tonicAiLandingPage";
/**Imports of Material UI Elements */
import { Tab, Tabs, Box } from "@material-ui/core";
/**Imports of API Actions */
import { viewJobListDetails } from "../../store/actions/testDBOperation/testDBAction";
/**Import of Config file */
import { administrationLog, logLevel } from "../../config/logConfig";
/**Import of Logger files */
import { logFunctionStart, logFunctionEnd, writeLog } from "../../logManager";

/**Interface of Material UI tab component props */
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

/**Function to manage Tab Changes*/
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function TonicAi() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [tab, settab] = React.useState(0);
  const verifiedData = useSelector((state: RootState) => state.selectWorkspace);
  const apikey = useSelector(
    (state: RootState) => state.selectWorkspace.apikey
  );
  const selectedWorkspace = useSelector(
    (state: RootState) => state.selectWorkspace.currentWorkspace
  );
  const currentLogLevel = useSelector(
    (state: RootState) => state.landingPage.currentLogLevel
  );
  const connectionStatus = useSelector(
    (state: RootState) => state.testDBdata.connectionButtonStatus
  );
  const currentConfigValue = useSelector(
    (state: RootState) => state.testDBdata.dbConfig
  );

  /**Function to Change Tabs and Get Job List*/
  const handleTab = async (event: any, newValue: any) => {
    const startTime = logFunctionStart(currentLogLevel, "handleTab");
    try {
      settab(newValue);
      if (
        apikey !== "" &&
        selectedWorkspace.id !== undefined &&
        newValue === 2
      ) {
        const jobList: any = await dispatch(
          viewJobListDetails(apikey, selectedWorkspace.id)
        );
        if (jobList !== undefined) {
          writeLog(
            logLevel.Information,
            currentLogLevel,
            jobList.userMessage,
            administrationLog.handleTab
          );
        } else {
          writeLog(
            logLevel.Error,
            currentLogLevel,
            jobList.userMessage,
            administrationLog.handleTab
          );
        }
      }
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleTab");
    }
  };

  return (
    <div>
      <div className="navPageTabs">
        <Tabs
          value={tab}
          aria-label="admin-tab"
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTab}
        >
          <Tab
            label={intl.formatMessage({ id: "TonicAI" })}
            className="tabtext"
          />
          <Tab
            label={intl.formatMessage({ id: "Setworkspace" })}
            className="tabtext"
          />
          <Tab
            label={intl.formatMessage({ id: "GenerateData" })}
            disabled={
              verifiedData.isVerify === false ||
              verifiedData.setWorkspace === false
            }
            className={
              verifiedData.isVerify === false ||
              verifiedData.setWorkspace === false
                ? "tabdisable"
                : "tabtext"
            }
          />
          <Tab
            label={intl.formatMessage({ id: "ViewJobs" })}
            disabled={
              verifiedData.isVerify === false ||
              verifiedData.setWorkspace === false
            }
            className={
              verifiedData.isVerify === false ||
              verifiedData.setWorkspace === false
                ? "tabdisable"
                : "tabtext"
            }
          />
        </Tabs>
      </div>
      <div className="midContentShadow">
        <TabPanel value={tab} index={0}>
          <TonicAiLandingPage />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <SelectWorkspace />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <GenerateData />
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <ViewJobs />
        </TabPanel>
      </div>
    </div>
  );
}

export default TonicAi;
