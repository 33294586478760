/**Imports of React libraries */
import { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
/**Imports of Reusable Components */
import { RootState } from "../../../store/reducers/rootReducer";
import AddQueryPopup from "../../Popup/addQueryPopup";
import { editQueryRecordContext } from "./queryManager";
/**Import of config files */
import { config } from "../../../config/clientConfig";
/**Imports of Material UI Elements */
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  Grid,
  DialogContentText,
  DialogActions,
  Snackbar,
} from "@material-ui/core";
import { Alert, Pagination } from "@material-ui/lab";
/**Imports of API Actions */
import {
  deleteQuery,
  getQueryLists,
} from "../../../store/actions/queryManager/queryManagerAction";
/**Import of Logger files */
import { logLevel, queryManagerLog } from "../../../config/logConfig";
import {
  logFunctionStart,
  logFunctionEnd,
  writeLog,
} from "../../../logManager";

function ListsOfQueries(): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [page, setpage] = useState<number>(0);
  const [rowsPerPage] = useState<number>(25);

  const { value }: any = useContext(editQueryRecordContext);
  const [recordForEdit, setRecordForEdit] = value;

  // Add Query popup state
  const [isAddQueryPopupEnabled, setIsAddQueryPopupEnabled] =
    useState<boolean>(false);
  // Delete and maximum Query popup
  const [isOpenDeletePopup, setIsOpenDeletePopup] = useState<boolean>(false);
  const [recordForDelete, setRecordForDelete] = useState(null);
  
  /** state for handle Success message */
  const [successMessage, setSuccessMessage] = useState("");
  /** state for handle Success message Snackbar visibility */
  const [successMessageSnackbar, setSuccessMessageSnackbar] = useState(false);
  /** state for handle Error message */
  const [errorMessage, setErrorMessage] = useState("");
  /** state for handle Error message Snackbar visibility */
  const [errorMessageSnackbar, setErrorMessageSnackbar] = useState(false);

  // Get the db Query values for the logged in user from sqlite
  const queryList = useSelector(
    (state: RootState) => state.queryManagerData.queryList
  );

  const currentLogLevel = useSelector(
    (state: RootState) => state.landingPage.currentLogLevel
  );

  /** Calling the get call After query edited and save */
  useEffect(() => {
    dispatch(getQueryLists());
  }, [isAddQueryPopupEnabled]);

  /**Function to Change the page in Pagination*/
  const handleTestDBPageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newPage: number
  ) => {
    setpage(newPage - 1);
  };

  /**Function to handle edit database Query*/
  const handleQueryEdit = (dbRecord: any) => {
    // Displaying edit Query popup
    setIsAddQueryPopupEnabled(true);
    setRecordForEdit(dbRecord);
  };

  /**Function to handle delete Query*/
  const handleQueryDelete = async (dbRecord: any) => {
    const startTime = logFunctionStart(currentLogLevel, "handleQueryDelete");
    try {
      const deleteQueryData: any = await dispatch(
        deleteQuery(dbRecord["QueryName"])
      );
      handleIsOpenDeletePopup();
      if (
        deleteQueryData.messageCode === config.clientFailureCode ||
        deleteQueryData.messageCode === config.serverErrorCode
      ) {
        setErrorMessage(deleteQueryData.userMessage);
        setErrorMessageSnackbar(true);
      } else {
        writeLog(
          logLevel.Information,
          currentLogLevel,
          "Handle Query Delete",
          queryManagerLog.deleteQuery
        );
        // Fetching logged in user db connection Details
        await dispatch(getQueryLists());
        setSuccessMessage(deleteQueryData.userMessage);
        setSuccessMessageSnackbar(true);
      }
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleQueryDelete");
    }
  };

  // handle add connection popup
  const handleAddQueryPopup = () => {
    setIsAddQueryPopupEnabled(true);
  };

  // handle delete popup
  const handleIsOpenDeletePopup = () => {
    setIsOpenDeletePopup(!isOpenDeletePopup);
  };

  /** This function for close snackbar */
  const handleIsSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
        return;
    }
    setErrorMessageSnackbar(false);
    setSuccessMessageSnackbar(false);
  };

  return (
    <div className="listsOfDBs listOfQuery">
      <div className="addConnectionButton">
        <button onClick={handleAddQueryPopup}>
          {intl.formatMessage({ id: "AddQuery" })}
        </button>
      </div>
      <TableContainer className="listsOfDBstableContainer">
        <Table stickyHeader aria-label="sticky table" className="joblist-table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 160 }}>
                {intl.formatMessage({ id: "QueryName" })}
              </TableCell>
              <TableCell>{intl.formatMessage({ id: "DBName" })}</TableCell>
              <TableCell>{intl.formatMessage({ id: "Type" })}</TableCell>
              <TableCell>{intl.formatMessage({ id: "Description" })}</TableCell>
              <TableCell>{intl.formatMessage({ id: "Actions" })}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {queryList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index: number) => (
                <TableRow data-testid="jobsrow" key={index}>
                  <TableCell>
                    <Typography>{row.QueryName}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{row.Database}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{row.Type}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{row.Description}</Typography>
                  </TableCell>

                  <TableCell>
                    <div className="listsOfDBsActions">
                      <button
                        onClick={() => handleQueryEdit(row)}
                        className="listsOfDBsEdit"
                        title="Edit"
                      >
                        {/* Showing eye icon for the default Query */}
                        {row.connectionname === "autodb" ||
                        row.connectionname === "faximmedb" ? (
                          <svg
                            className="MuiSvgIcon-root"
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path>
                          </svg>
                        ) : (
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.116 3.02344L1.21212 12.928C1.16229 12.978 1.12631 13.0411 1.10918 13.1089L0.0114342 17.515C-0.0214026 17.6476 0.0175732 17.7887 0.11437 17.8855C0.187611 17.9587 0.287406 17.9993 0.389486 17.9993C0.420752 17.9993 0.452732 17.9954 0.483856 17.9876L4.88998 16.8897C4.95865 16.8726 5.02104 16.8367 5.07087 16.7869L14.9756 6.88304L11.116 3.02344Z"
                              fill="white"
                            />
                            <path
                              d="M17.4291 1.67239L16.3267 0.569932C15.5899 -0.166897 14.3057 -0.166183 13.5697 0.569932L12.2192 1.92038L16.0787 5.77984L17.4291 4.42939C17.7972 4.06148 17.9999 3.57178 17.9999 3.05096C17.9999 2.53014 17.7972 2.04045 17.4291 1.67239Z"
                              fill="white"
                            />
                          </svg>
                        )}
                      </button>
                      <button
                        onClick={() => {
                          handleIsOpenDeletePopup();
                          setRecordForDelete(row);
                        }}
                        className={
                          row.connectionname === "autodb" ||
                          row.connectionname === "faximmedb"
                            ? "listsOfDBsDelete disabled"
                            : "listsOfDBsDelete"
                        }
                        title="Delete"
                        disabled={
                          row.connectionname === "autodb" ||
                          row.connectionname === "faximmedb"
                        }
                      >
                        <svg
                          width="18"
                          height="23"
                          viewBox="0 0 18 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.73414 1.33082H10.4188V1.95081H11.7494V1.24402C11.7496 0.558095 11.1918 0 10.5062 0H6.64667C5.96108 0 5.40332 0.558095 5.40332 1.24402V1.95081H6.73414V1.33082Z"
                            fill="white"
                          />
                          <path
                            d="M14.8213 7.22266H2.32927C1.98698 7.22266 1.71752 7.51466 1.74511 7.85594L2.78948 20.7698C2.84767 21.4907 3.449 22.046 4.17142 22.046H12.979C13.7014 22.046 14.3027 21.4907 14.3609 20.7697L15.4053 7.85594C15.4331 7.51466 15.1636 7.22266 14.8213 7.22266ZM5.35405 20.6686C5.34009 20.6694 5.32613 20.6699 5.31234 20.6699C4.96349 20.6699 4.67065 20.3983 4.64895 20.0454L3.99448 9.44377C3.97194 9.07692 4.25098 8.7612 4.61766 8.73866C4.98317 8.71646 5.30023 8.99483 5.32277 9.36185L5.97707 19.9635C5.99978 20.3303 5.72073 20.6459 5.35405 20.6686ZM9.2481 20.0045C9.2481 20.3719 8.95021 20.6697 8.58269 20.6697C8.21517 20.6697 7.91729 20.3719 7.91729 20.0045V9.40272C7.91729 9.0352 8.21517 8.73732 8.58269 8.73732C8.95005 8.73732 9.2481 9.0352 9.2481 9.40272V20.0045ZM13.1561 9.44192L12.5312 20.0435C12.5105 20.3971 12.2172 20.6697 11.8677 20.6697C11.8546 20.6697 11.8413 20.6694 11.828 20.6687C11.4611 20.647 11.1812 20.3322 11.2029 19.9653L11.8276 9.36353C11.8492 8.99668 12.163 8.7168 12.5309 8.73849C12.8978 8.76002 13.1776 9.07507 13.1561 9.44192Z"
                            fill="white"
                          />
                          <path
                            d="M17.1226 5.17046L16.6856 3.86051C16.5704 3.51519 16.2471 3.28223 15.883 3.28223H1.26805C0.904064 3.28223 0.580611 3.51519 0.465561 3.86051L0.0285717 5.17046C-0.0556976 5.4231 0.0539702 5.68079 0.258672 5.8093C0.3421 5.86161 0.440835 5.89306 0.549326 5.89306H16.6019C16.7104 5.89306 16.8093 5.86161 16.8925 5.80913C17.0972 5.68062 17.2069 5.42294 17.1226 5.17046Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {queryList.length > 25 && (
        <div className="pagination">
          <Typography className="paginationtext">
            <span>
              {page * rowsPerPage + 1} {"-"}{" "}
              {page * rowsPerPage + rowsPerPage < queryList.length ? (
                <>{page * rowsPerPage + rowsPerPage}</>
              ) : (
                <>{queryList.length}</>
              )}
            </span>
            <p>{intl.formatMessage({ id: "of" })}</p>
            <p>{queryList.length}</p>
          </Typography>
          <Pagination
            count={Math.ceil(queryList.length / rowsPerPage)}
            siblingCount={2}
            variant="outlined"
            shape="rounded"
            data-testid="pagination"
            onChange={handleTestDBPageChange}
          />
        </div>
      )}

      {isAddQueryPopupEnabled && (
        <AddQueryPopup setIsAddQueryPopupEnabled={setIsAddQueryPopupEnabled} />
      )}

      {/* Delete popup on DB Query list page*/}
      <div>
        <Dialog
          open={isOpenDeletePopup}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              handleIsOpenDeletePopup();
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="accessRequiredPopup DBSureDeletePopup"
        >
          <DialogContent>
            <Grid container>
              <div>
                <svg
                  width="18"
                  height="23"
                  viewBox="0 0 18 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.73414 1.33082H10.4188V1.95081H11.7494V1.24402C11.7496 0.558095 11.1918 0 10.5062 0H6.64667C5.96108 0 5.40332 0.558095 5.40332 1.24402V1.95081H6.73414V1.33082Z"
                    fill="white"
                  />
                  <path
                    d="M14.8213 7.22266H2.32927C1.98698 7.22266 1.71752 7.51466 1.74511 7.85594L2.78948 20.7698C2.84767 21.4907 3.449 22.046 4.17142 22.046H12.979C13.7014 22.046 14.3027 21.4907 14.3609 20.7697L15.4053 7.85594C15.4331 7.51466 15.1636 7.22266 14.8213 7.22266ZM5.35405 20.6686C5.34009 20.6694 5.32613 20.6699 5.31234 20.6699C4.96349 20.6699 4.67065 20.3983 4.64895 20.0454L3.99448 9.44377C3.97194 9.07692 4.25098 8.7612 4.61766 8.73866C4.98317 8.71646 5.30023 8.99483 5.32277 9.36185L5.97707 19.9635C5.99978 20.3303 5.72073 20.6459 5.35405 20.6686ZM9.2481 20.0045C9.2481 20.3719 8.95021 20.6697 8.58269 20.6697C8.21517 20.6697 7.91729 20.3719 7.91729 20.0045V9.40272C7.91729 9.0352 8.21517 8.73732 8.58269 8.73732C8.95005 8.73732 9.2481 9.0352 9.2481 9.40272V20.0045ZM13.1561 9.44192L12.5312 20.0435C12.5105 20.3971 12.2172 20.6697 11.8677 20.6697C11.8546 20.6697 11.8413 20.6694 11.828 20.6687C11.4611 20.647 11.1812 20.3322 11.2029 19.9653L11.8276 9.36353C11.8492 8.99668 12.163 8.7168 12.5309 8.73849C12.8978 8.76002 13.1776 9.07507 13.1561 9.44192Z"
                    fill="white"
                  />
                  <path
                    d="M17.1226 5.17046L16.6856 3.86051C16.5704 3.51519 16.2471 3.28223 15.883 3.28223H1.26805C0.904064 3.28223 0.580611 3.51519 0.465561 3.86051L0.0285717 5.17046C-0.0556976 5.4231 0.0539702 5.68079 0.258672 5.8093C0.3421 5.86161 0.440835 5.89306 0.549326 5.89306H16.6019C16.7104 5.89306 16.8093 5.86161 16.8925 5.80913C17.0972 5.68062 17.2069 5.42294 17.1226 5.17046Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div>
                <DialogContentText id="alert-dialog-description">
                  <Typography>
                    {intl.formatMessage({ id: "DeleteAlert" })}
                  </Typography>
                </DialogContentText>
              </div>
            </Grid>
          </DialogContent>
          <DialogActions className="ssoPopupsButton">
            <button onClick={handleIsOpenDeletePopup}>
              {intl.formatMessage({ id: "Cancel" })}
            </button>
            <button
              onClick={() => handleQueryDelete(recordForDelete)}
              className="reindexpop"
            >
              {intl.formatMessage({ id: "Delete" })}
            </button>
          </DialogActions>
        </Dialog>

        {/* success message indicator snackbar */}
        <Snackbar
            open={successMessageSnackbar}
            autoHideDuration={3000}
            onClose={handleIsSnackbarClose}
            className="schemaSavedSnackbarSection"
            action={
              <Grid container alignItems="center">
                  <Grid item>
                      <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15 0C6.76113 0 0 6.76113 0 15C0 23.2389 6.76113 30 15 30C23.2389 30 30 23.2389 30 15C30 6.76113 23.2389 0 15 0ZM13.1847 21.8227L6.61605 15.2541L9.10172 12.7684L13.2997 16.9664L21.7274 9.30516L24.0929 11.9058L13.1847 21.8227Z" fill="white" />
                      </svg>
                  </Grid>
                  <Grid>
                      <Typography>
                          {successMessage}
                      </Typography>
                  </Grid>
              </Grid>
            }
        />
        {/* Error message indicator snackbar */}
        <Snackbar autoHideDuration={6000} open={errorMessageSnackbar} onClose={handleIsSnackbarClose} className="errorMessageSnachbar">
            <Alert onClose={handleIsSnackbarClose} severity="error">
                {errorMessage}
            </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default ListsOfQueries;
