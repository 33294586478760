/**Imports of React libraries */
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
/**Imports of Reusable Components */
import { RootState } from "../../store/reducers/rootReducer";
import TestDB from "../DBOperation/testDB";
/**Imports of Material UI Elements */
import { Typography } from "@material-ui/core";

function DbOperation() {
  const history = useHistory();
  const intl = useIntl();
  const currentLogLevel = useSelector(
    (state: RootState) => state.landingPage.currentLogLevel
  );

  return (
    <div>
      <div className="TestDBTitle">
        <Typography className="tabtextTestDB">
          {intl.formatMessage({ id: "TestDB" })}
        </Typography>
      </div>
      <div className="midContentShadowTestDB">
        <TestDB />
      </div>
    </div>
  );
}

export default DbOperation;
