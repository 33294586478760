/**Imports of React libraries */
import { useState, useEffect } from "react";
/**Imports of Reusable Components */
import ComparisonForm from "./comparisonForm";
import { useIntl } from "react-intl";
/** Import of Icons */
import { ReactComponent as Warning } from "../../../assets/images/avatars/Invalid.svg";
import { ReactComponent as SourceIcon } from "../../../assets/images/avatars/dc-source-icon.svg";
import { ReactComponent as TargetIcon } from "../../../assets/images/avatars/dc-target-icon.svg";
/**Imports of Material UI Elements */
import { Dialog, FormControl, Grid, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

/** type for the source and target properties */
export interface InputsType {
  connectionType: string;
  dbType: string;
  connection: string;
  key: string;
  tableName: string;
  query: string[];
  filePath: string;
}

/** Initial state for the source and target forms */
const initialState: InputsType = {
  connectionType: "",
  dbType: "",
  connection: "",
  key: "",
  tableName: "",
  query: [],
  filePath: "",
};

function DataComparisonForm(): JSX.Element {
  const intl = useIntl();
  /** State for storing source form values */
  const [sourceInputs, setSourceInputs] = useState(initialState);
  /** State for storing target form values */
  const [targetInputs, setTargetInputs] = useState(initialState);
  /** State for validate source form values */
  const [isSourceFormValidate, setIsSourceFormValidate] = useState(
    true as boolean
  );
  /** State for validate target form values */
  const [isTargetFormValidate, setIsTargetFormValidate] = useState(
    true as boolean
  );
  /** State for show the compare data json as a popup */
  const [isJsonPopupVisible, setIsJsonPopupVisible] = useState(
    false as boolean
  );
  /** State for store the compare data json */
  const [compareJSON, setCompareJSON] = useState({});

  /** Function for choose source connection type
   * When changing the connection type, that time we've clearing the Query and file path data for fix generate JSON issue.
   */
  const handleSourceConnectionType = (newValue: string) => {
    setSourceInputs(() =>
      newValue === "File"
        ? {
            ...sourceInputs,
            connectionType: newValue,
            dbType: "CSV",
            query: [],
          }
        : {
            ...sourceInputs,
            connectionType: newValue,
            dbType: "",
            filePath: "",
          }
    );
  };

  /** Function for choose target connection type
   * When changing the connection type, that time we've clearing the Query and file path data for fix generate JSON issue.
   */
  const handleTargetConnectionType = (newValue: string) => {
    setTargetInputs(() =>
      newValue === "File"
        ? {
            ...targetInputs,
            connectionType: newValue,
            dbType: "CSV",
            query: [],
          }
        : {
            ...targetInputs,
            connectionType: newValue,
            dbType: "",
            filePath: "",
          }
    );
  };

  /** validation for the compare functionality
   * All the input fields are required to compare.
   * So, here we're checking the validations for Source and Target forms
   */
  useEffect(() => {
    setIsSourceFormValidate(false);
    sourceInputs["connectionType"] === "File"
      ? sourceInputs.key &&
        sourceInputs.tableName &&
        sourceInputs.filePath &&
        setIsSourceFormValidate(true)
      : sourceInputs.key &&
        sourceInputs.tableName &&
        sourceInputs.query.length > 0 &&
        setIsSourceFormValidate(true);
  }, [sourceInputs]);

  useEffect(() => {
    setIsTargetFormValidate(false);
    targetInputs["connectionType"] === "File"
      ? targetInputs.key &&
        targetInputs.tableName &&
        targetInputs.filePath &&
        setIsTargetFormValidate(true)
      : targetInputs.key &&
        targetInputs.tableName &&
        targetInputs.query.length > 0 &&
        setIsTargetFormValidate(true);
  }, [targetInputs]);

  /** Function for handle Compare Data
   * Currently, we're showing the JSON on the Browser console
   * This functionality not yet completed
   */
  const handleCompare = () => {
    const postDetails = { source: sourceInputs, target: targetInputs };
    handleIsOpenJsonPopup();
    setCompareJSON(JSON.stringify(postDetails));
    console.log("postDetails", JSON.stringify(postDetails));
  };

  const handleIsOpenJsonPopup = () => {
    setIsJsonPopupVisible(!isJsonPopupVisible);
  }

  return (
    <section className="dataCompasisonSection">
      <Grid container spacing={3}>
        {/*
         * Source Form
         */}
        <Grid item xs={12} md={6}>
          <div className="dataCompasisonSource">
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className="dataCompasisonTitle"
            >
              <Grid item>
                <SourceIcon />
              </Grid>
              <Grid item>
                <Typography> {intl.formatMessage({ id: "Source" })}</Typography>
              </Grid>
            </Grid>

            <div className="dataCompasisonForm">
              <div className="tablelist">
                <label className="label1">
                  {intl.formatMessage({ id: "Connection" })}{" "}
                  {intl.formatMessage({ id: "Type" })}
                </label>
                <FormControl>
                  <Autocomplete
                    fullWidth
                    id="combo-box-demo"
                    options={["Database", "File"]}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={intl.formatMessage({
                          id: "SelectConnectionType",
                        })}
                        inputProps={{
                          ...params.inputProps,
                          "data-testid": "object",
                        }}
                      />
                    )}
                    onChange={(event: any, newValue: any) => {
                      handleSourceConnectionType(newValue);
                    }}
                  />
                </FormControl>
              </div>

              {/* Common form for comparison input details */}
              <ComparisonForm
                connectionType={sourceInputs.connectionType}
                formInputs={sourceInputs}
                setFormInputs={setSourceInputs}
              />
            </div>
          </div>
        </Grid>

        {/*
         * Target Form
         */}
        <Grid item xs={12} md={6}>
          <div className="dataCompasisonTarget">
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className="dataCompasisonTitle"
            >
              <Grid item>
                <TargetIcon />
              </Grid>
              <Grid item>
                <Typography> {intl.formatMessage({ id: "Target" })}</Typography>
              </Grid>
            </Grid>

            <div className="dataCompasisonForm">
              <div className="tablelist">
                <label className="label1">
                  {intl.formatMessage({ id: "Connection" })}{" "}
                  {intl.formatMessage({ id: "Type" })}
                </label>
                <FormControl>
                  <Autocomplete
                    fullWidth
                    id="combo-box-demo"
                    options={["Database", "File"]}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={intl.formatMessage({
                          id: "SelectConnectionType",
                        })}
                        inputProps={{
                          ...params.inputProps,
                          "data-testid": "object",
                        }}
                      />
                    )}
                    onChange={(event: any, newValue: any) => {
                      handleTargetConnectionType(newValue);
                    }}
                  />
                </FormControl>
              </div>

              {/* Common form for comparison input details */}
              <ComparisonForm
                connectionType={targetInputs.connectionType}
                formInputs={targetInputs}
                setFormInputs={setTargetInputs}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <div className="dataCompasisonNote">
        <Grid
          container
          wrap="nowrap"
          justifyContent="center"
          alignItems="center"
        >
          <Warning />
          <Typography>
            {" "}
            {intl.formatMessage({ id: "CompareDataMsg" })}
            <span> {intl.formatMessage({ id: "CompareData" })}</span>
            {"” "}
            {intl.formatMessage({ id: "button" })}
          </Typography>
        </Grid>
      </div>
      <div className="dataCompasisonBtn newDBConnectionBtns">
        <div className="newDBConnectionTestBtn">
          {/* Button red class = failedBtn */}
          {/* Button green class = successBtn */}
          {/* Button disabled class = disabledBtn and need to add disabled attribute */}
          <button
            onClick={handleCompare}
            className={`${
              isSourceFormValidate && isTargetFormValidate ? "" : "disabledBtn"
            }`}
            disabled={
              isSourceFormValidate && isTargetFormValidate ? false : true
            }
          >
            <div>
              <Typography>
                {intl.formatMessage({ id: "CompareData" })}
              </Typography>
            </div>
          </button>
        </div>
      </div>

      <Dialog
      open={isJsonPopupVisible}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleIsOpenJsonPopup();
        }
      }}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="addQueryPopup newConnectionPopup"
      >
        <button
          className="newConnectionPopupCloseIcon"
          onClick={handleIsOpenJsonPopup}
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.87424 7.49996L14.7149 1.65908C15.0951 1.27916 15.0951 0.664869 14.7149 0.284944C14.335 -0.0949813 13.7207 -0.0949813 13.3408 0.284944L7.49992 6.12583L1.65922 0.284944C1.27911 -0.0949813 0.665003 -0.0949813 0.285078 0.284944C-0.0950259 0.664869 -0.0950259 1.27916 0.285078 1.65908L6.12579 7.49996L0.285078 13.3408C-0.0950259 13.7208 -0.0950259 14.3351 0.285078 14.715C0.474417 14.9045 0.723371 14.9997 0.972147 14.9997C1.22092 14.9997 1.4697 14.9045 1.65922 14.715L7.49992 8.8741L13.3408 14.715C13.5303 14.9045 13.7791 14.9997 14.0279 14.9997C14.2767 14.9997 14.5254 14.9045 14.7149 14.715C15.0951 14.3351 15.0951 13.7208 14.7149 13.3408L8.87424 7.49996Z"
              fill="#ABABAB"
            />
          </svg>
        </button>
        <div className="newConnectionPopupHead">
          <div className="newConnectionPopupHeadName">
            <Typography>
              Comparison JSON for call the EX08 API
            </Typography>
          </div>
        </div>
        <section className="queryManagerSection">
          <Typography>
            {compareJSON}
          </Typography>
          <br />
          <i><Typography>
            <strong>Note:</strong> Compare results will be populated on the next launch!
          </Typography></i>
        </section>
      </Dialog>

    </section>
  );
}

export default DataComparisonForm;
