import { useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
  Plugin
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Tooltip,
  Legend
);
/** Import of config file */
import { variables } from "../../../config/variables.config";

//plugin for displaying a doughnut value at center
const textCenter: Plugin[] = [{
  id: 'textCenter',
  afterDatasetsDraw(chart, args, options){
    const {ctx, data, chartArea: {top, right, bottom, left, width, height}, scales: {x, y} } = chart;
    ctx.save();
    ctx.textAlign = 'center';
    //first value
    const bgClr = data.datasets[0]?.backgroundColor;
    ctx.fillStyle = bgClr[0];
    ctx.font = 'bold 14px Montserrat';
    ctx.fillText(data.datasets[0]?.data[0] + '% ', chart.getDatasetMeta(0).data[0].x - 30, chart.getDatasetMeta(0).data[0].y);

    //second value
    ctx.fillStyle = bgClr[1];
    ctx.font = '14px Montserrat';
    ctx.fillText('100% ', chart.getDatasetMeta(0).data[0].x + 30, chart.getDatasetMeta(0).data[0].y);

    //line
    ctx.beginPath();
    ctx.strokeStyle = '#a8a8a8';
    ctx.lineWidth = 0;
    ctx.moveTo(chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y - 10);
    ctx.lineTo(chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y + 10);
    ctx.stroke();
  }
}];

interface Props {
  doughnutValues: string;
  doughnutCategory: string;
}

const TableDoughnutChart = ({doughnutValues, doughnutCategory}: Props) => {
  
  /** Doughnut chart data props */
  const doughnutNullData: ChartData<'doughnut'> = {
    labels:  ["% of Null    ", "% of Non Null    "],
    datasets: [
      {
        label: "# of null values",
        data: [
          parseInt(doughnutValues),
          100 - parseInt(doughnutValues),
          
        ],
        backgroundColor: ["#009086", "#c8c8c8"],
        borderColor: ["#009086", "#c8c8c8"],
        rotation: 180,
        datalabels: {
          display: false
        }
      },
    ],
  };
    
  const doughnutUniqueData: ChartData<'doughnut'> = {
    labels: ["% of Unique", "% of Non Unique"],
    datasets: [
      {
        label: "# of null values",
        data: [
          parseInt(doughnutValues),
          100 - parseInt(doughnutValues),
        ],
        backgroundColor: ["#0078d4", "#c8c8c8"],
        borderColor: ["#0078d4", "#c8c8c8"],
        rotation: 180,
        datalabels: {
          display: false
        }
      },
    ],
  };

  /** Doughnut chart option and data props */
  const doughnutOptions: ChartOptions<'doughnut'> = {
    cutout: "80%",
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    radius: 80,
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 10,
          font:{
            weight: '800',
            family: 'Montserrat',
            size: 12
          }
        }
      }
    }
  };

  useEffect(() => {
    doughnutValues && ChartJS.register(textCenter);
    return () => {
      ChartJS.unregister(textCenter);
    };
  },[]);

  return <Doughnut data={doughnutCategory === variables.dataProfilingNullChartText ? doughnutNullData : doughnutUniqueData} options={doughnutOptions} />;
}

export default TableDoughnutChart;