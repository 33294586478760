//Landing Page
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_LOG = "SET_LOG";
export const SET_CURRENT_LOG_LEVEL = "SET_CURRENT_LOG_LEVEL";
export const SET_LOADING = "SET_LOADING";

//Adminstration
export const CURRENT_WORKSPACE = "CURRENT_WORKSPACE";
export const GET_WORKSPACE = "GET_WORKSPACE";
export const GET_DATASOURCE = "GET_DATASOURCE";
export const SET_WORKSPACE = "SET_WORKSPACE";
export const SELECTED_APIKEY = "SELECTED_APIKEY";
export const ISVERIFIED_APIKEY = "ISVERIFIED_APIKEY";

//Test DB Operation
export const GET_TESTDB_DATA = "GET_TESTDB_DATA";
export const DELETE_TESTDB_ITEM = "DELETE_TESTDB_ITEM";
export const SELECTED_TESTDATA = "SELECTED_TESTDATA";
export const GENERATE_DATA = "GENERATE_DATA";
export const VIEW_JOB_LIST = "VIEW_JOB_LIST";
export const TABLE_COLUMN_LIST = "TABLE_COLUMN_LIST";
export const JOB_ID = "JOB_ID";
export const CONNECT_TESTDB = "CONNECT_TESTDB";
export const CONNECTION_LIST = "CONNECTION_LIST";
export const CONNECTION_INSERT = "CONNECTION_INSERT";
export const CONNECTED_TESTDB = "CONNECTED_TESTDB";
export const CONNECTEDDB_BUTTON_STATUS = "CONNECTEDDB_BUTTON_STATUS";

//Data Operation
export const INDEX_DATA_RESULT = "INDEX_DATA_RESULT";
export const SEARCH_DATA_RESULT = "SEARCH_DATA_RESULT";
export const SYNTHETIC_DATA_RESULT = "SYNTHETIC_DATA_RESULT";
export const SELECTED_BUSINESS_OBJECT_VALUE = "SELECTED_BUSINESS_OBJECT_VALUE";
export const COUNT_VALUE = "COUNT_VALUE";
export const CLEAR_SEARCH_RECORD = "CLEAR_SEARCH_RECORD";
export const GET_SCHEMA_NAME = "GET_SCHEMA_NAME";

//Authentication Operation
export const CONNECT_AUTHAPP = "CONNECT_AUTHAPP";
export const USER_NAME = "USER_NAME";
export const USER_LOGGEDIN = "USER_LOGGEDIN";
export const GET_TOKEN_EXPIRY = "GET_TOKEN_EXPIRY";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

//Db Profile Operation
export const DB_PROFILE = "DB_PROFILE";

// DB Connection Test
export const TEST_DBCONNECTION = "TEST_DBCONNECTION";

// Sidebar Menu
export const MENU_OPEN = "MENU_OPEN";
export const MENU_CLOSE = "MENU_CLOSE";

// Query Manager
export const GET_QUERY = "GET_QUERY";