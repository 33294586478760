import {
  SEARCH_DATA_RESULT,
  SYNTHETIC_DATA_RESULT,
  SELECTED_BUSINESS_OBJECT_VALUE,
  COUNT_VALUE,
  CLEAR_SEARCH_RECORD,
  GET_SCHEMA_NAME
} from "../actiontypes";
import fetch from "../../../utils/leoFetch";
import { config } from "../../../config/clientConfig";
import axios from 'axios';
import { SchemaDataDetailsTypes } from "../../../components/DataOperation/syntheticDataSchema/schemaCreation";

/** Start Index Test Data */

export const searchTestDb =
  (category: any, facets: any, searchkey: any) => (dispatch: any) => {
    const payload = {
      category: category,
      facets: facets,
      searchkey: searchkey,
    };
    return new Promise(function (resolve, reject) {
      fetch(config.apiRootPath + config.solrsearchurl, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          dispatch({
            payload: res.data1.docs,
            type: SEARCH_DATA_RESULT,
          });
          return resolve(res);
        });
    });
  };

/** Start Index Test Data */

export const GenerateSyntheticData =
  (object: any, count: any) => (dispatch: any) => {
    return new Promise(function (resolve, reject) {
      fetch(
        config.apiRootPath + config.mockarooURL + "/" + object + "/" + count,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((res) => {
          dispatch({
            payload: res.data,
            type: SYNTHETIC_DATA_RESULT,
          });
          return resolve(res);
        });
    });
  };

/** Inmemory for Selected value of Business Object  */
export const inmemoryBusinessObjectValue =
  (selectedValue: any) => (dispatch: any) => {
    dispatch({
      payload: selectedValue,
      type: SELECTED_BUSINESS_OBJECT_VALUE,
    });
  };

/** Inmemory for value of Record count */
export const inmemoryRecordCount = (countValue: any) => (dispatch: any) => {
  dispatch({
    payload: countValue,
    type: COUNT_VALUE,
  });
};

/** Inmemory to clear Search Records */
export const inmemoryClearSearch = () => (dispatch: any) => {
  dispatch({
    payload: [],
    type: CLEAR_SEARCH_RECORD,
  });
};

/** Get list of Schema Names */
export const getListOfSchemaNames = () => (dispatch: any) => {
  return new Promise(function (resolve, reject) {
    axios({
      method: "GET",
      url: config.apiRootPath + config.getSchemaNames,
    })
      .then((res) => {
        const namesArr = res.data.data?.split(",")
        dispatch({
          payload: namesArr,
          type: GET_SCHEMA_NAME,
        });
        return resolve(res);
      });
  });
}

/** Schema Creation */
export const downloadSchemaData = (count: number, payload: Array<SchemaDataDetailsTypes>) => (dispatch: any) => {
  return new Promise(function (resolve, reject) {
    axios({
      method: "POST",
      params: {
        count
      },
      url: config.apiRootPath + config.downloadSchema,
      data: payload
    })
      .then((res: any) => {
          return resolve(res.data);
      });
  });
}

/** Schema Creation */
export const saveNewSchema = (name: string, payload: Array<SchemaDataDetailsTypes>) => (dispatch: any) => {
  return new Promise(function (resolve, reject) {
    axios({
      method: "POST",
      params: {
        name
      },
      url: config.apiRootPath + config.saveNewSchema,
      data: payload
    })
      .then((res) => {
        return resolve(res.data);
      });
  });
}