/**Imports of React libraries */
import { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
/**Imports of Reusable Components */
import { RootState } from "../../store/reducers/rootReducer";
import DBConnectionPopup from "../Popup/dbConnectionPopup";
/**Imports of Material UI Elements */
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  Grid,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
/**Imports of SVG Icons */
import MariaDBImg from "../../assets/images/avatars/maria-db.png";
import MysqlDBImg from "../../assets/images/avatars/MySQL.png";
import SqlDBImg from "../../assets/images/avatars/SQL_Server.png";
import PostgreSqlDBImg from "../../assets/images/avatars/Postgre_SQL.png";
import MongoDBImg from "../../assets/images/avatars/Mongo_DB.png";
import OracleDBImg from "../../assets/images/avatars/Oracle.png";
/**Imports of API Actions */
import {
  deleteDBConnection,
  dbConnectionList,
} from "../../store/actions/testDBOperation/testDBAction";
/**Import of Config files */
import {
  logLevel,
  viewJobsLog,
  dbRecordEditLog,
  dbRecordDeleteLog,
} from "../../config/logConfig";
/**Import of Logger files */
import { logFunctionStart, logFunctionEnd, writeLog } from "../../logManager";
import { editDBRecordContext } from "./SetTestDB";

function ListsOfDBs() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [page, setpage] = useState<number>(0);
  const [rowsPerPage] = useState<number>(25);

  const { value }: any = useContext(editDBRecordContext);
  const [recordForEdit, setRecordForEdit] = value;

  // Add connection popup state
  const [isAddConnectionPopupEnabled, setIsAddConnectionPopupEnabled] =
    useState<boolean>(false);
  // Delete and maximum connection popup
  const [isOpenDeletePopup, setIsOpenDeletePopup] = useState<boolean>(false);
  const [recordForDelete, setRecordForDelete] = useState(null);

  // Get the db connection values for the logged in user from sqlite
  const listOfDBs = useSelector(
    (state: RootState) => state.testDBdata.connectionList
  );

  const currentLogLevel = useSelector(
    (state: RootState) => state.landingPage.currentLogLevel
  );

  /**Function to Change the page in Pagination*/
  const handleTestDBPageChange = (event: any, newPage: number) => {
    const startTime = logFunctionStart(
      currentLogLevel,
      "handleTestDBPageChange"
    );
    try {
      setpage(newPage - 1);
      writeLog(
        logLevel.Information,
        currentLogLevel,
        "Changing Page in View Jobs Table",
        viewJobsLog.PageChange
      );
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleTestDBPageChange");
    }
  };

  /**Function to handle edit database connection*/
  const handleDBConnectionEdit = (dbRecord: any) => {
    // Displaying add connection popup
    setIsAddConnectionPopupEnabled(true);
    const startTime = logFunctionStart(
      currentLogLevel,
      "handleDBConnectionEdit"
    );
    try {
      setRecordForEdit(dbRecord);
      writeLog(
        logLevel.Information,
        currentLogLevel,
        "Handle DB Connection Edit",
        dbRecordEditLog.dbRecordEditData
      );
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleDBConnectionEdit");
    }
  };

  /**Function to handle delete database connection*/
  const handleDBConnectionDelete = async (dbRecord: any) => {
    const startTime = logFunctionStart(
      currentLogLevel,
      "handleDBConnectionDelete"
    );
    try {
      await dispatch(deleteDBConnection(dbRecord["connectionname"]));
      writeLog(
        logLevel.Information,
        currentLogLevel,
        "Handle DB Connection Delete",
        dbRecordDeleteLog.dbRecordDeleteData
      );
      handleIsOpenDeletePopup();

      // Fetching logged in user db connection Details
      await dispatch(dbConnectionList());
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleDBConnectionDelete");
    }
  };

  // handle add connection popup
  const handleAddConnectionPopup = () => {
    setIsAddConnectionPopupEnabled(true);
  };

  // handle delete popup
  const handleIsOpenDeletePopup = () => {
    setIsOpenDeletePopup(!isOpenDeletePopup);
  };

  return (
    <div className="listsOfDBs">
      <div className="addConnectionButton">
        <button onClick={handleAddConnectionPopup}>
          {intl.formatMessage({ id: "AddConnection" })}
        </button>
      </div>
      <TableContainer className="listsOfDBstableContainer">
        <Table stickyHeader aria-label="sticky table" className="joblist-table">
          <TableHead>
            <TableRow>
              <TableCell>
                {intl.formatMessage({ id: "ConnectionList" })}
              </TableCell>
              <TableCell>{intl.formatMessage({ id: "DBType" })}</TableCell>
              <TableCell>{intl.formatMessage({ id: "Actions" })}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listOfDBs
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index: number) => (
                <TableRow data-testid="jobsrow" key={index}>
                  <TableCell>
                    <Typography>{row.connectionname}</Typography>
                  </TableCell>
                  <TableCell>
                    <div className="DBConnectionType">
                      <div>
                        {row.dbtype === "Maria DB" ? (
                          <img src={MariaDBImg} alt="maria db img" />
                        ) : row.dbtype === "MySQL" ? (
                          <img src={MysqlDBImg} alt="mysql db img" />
                        ) : row.dbtype === "SQL Server" ? (
                          <img src={SqlDBImg} alt="sql db img" />
                        ) : row.dbtype === "PostgreSQL" ? (
                          <img src={PostgreSqlDBImg} alt="postgresql db img" />
                        ) : row.dbtype === "mongodb" ? (
                          <img src={MongoDBImg} alt="mongo db img" />
                        ) : row.dbtype === "Oracle" ? (
                          <img src={OracleDBImg} alt="oracle db img" />
                        ) : null}
                      </div>
                      <div>
                        <Typography>{row.dbtype}</Typography>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="listsOfDBsActions">
                      <button
                        onClick={() => handleDBConnectionEdit(row)}
                        className="listsOfDBsEdit"
                        title="Edit"
                      >
                        {/* Showing eye icon for the default connection */}
                        {row.connectionname === "autodb" ||
                        row.connectionname === "faximmedb" ? (
                          <svg
                            className="MuiSvgIcon-root"
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path>
                          </svg>
                        ) : (
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.116 3.02344L1.21212 12.928C1.16229 12.978 1.12631 13.0411 1.10918 13.1089L0.0114342 17.515C-0.0214026 17.6476 0.0175732 17.7887 0.11437 17.8855C0.187611 17.9587 0.287406 17.9993 0.389486 17.9993C0.420752 17.9993 0.452732 17.9954 0.483856 17.9876L4.88998 16.8897C4.95865 16.8726 5.02104 16.8367 5.07087 16.7869L14.9756 6.88304L11.116 3.02344Z"
                              fill="white"
                            />
                            <path
                              d="M17.4291 1.67239L16.3267 0.569932C15.5899 -0.166897 14.3057 -0.166183 13.5697 0.569932L12.2192 1.92038L16.0787 5.77984L17.4291 4.42939C17.7972 4.06148 17.9999 3.57178 17.9999 3.05096C17.9999 2.53014 17.7972 2.04045 17.4291 1.67239Z"
                              fill="white"
                            />
                          </svg>
                        )}
                      </button>
                      <button
                        onClick={() => {
                          handleIsOpenDeletePopup();
                          setRecordForDelete(row);
                        }}
                        className={
                          row.connectionname === "autodb" ||
                          row.connectionname === "faximmedb"
                            ? "listsOfDBsDelete disabled"
                            : "listsOfDBsDelete"
                        }
                        title="Delete"
                        disabled={
                          row.connectionname === "autodb" ||
                          row.connectionname === "faximmedb"
                        }
                      >
                        <svg
                          width="18"
                          height="23"
                          viewBox="0 0 18 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.73414 1.33082H10.4188V1.95081H11.7494V1.24402C11.7496 0.558095 11.1918 0 10.5062 0H6.64667C5.96108 0 5.40332 0.558095 5.40332 1.24402V1.95081H6.73414V1.33082Z"
                            fill="white"
                          />
                          <path
                            d="M14.8213 7.22266H2.32927C1.98698 7.22266 1.71752 7.51466 1.74511 7.85594L2.78948 20.7698C2.84767 21.4907 3.449 22.046 4.17142 22.046H12.979C13.7014 22.046 14.3027 21.4907 14.3609 20.7697L15.4053 7.85594C15.4331 7.51466 15.1636 7.22266 14.8213 7.22266ZM5.35405 20.6686C5.34009 20.6694 5.32613 20.6699 5.31234 20.6699C4.96349 20.6699 4.67065 20.3983 4.64895 20.0454L3.99448 9.44377C3.97194 9.07692 4.25098 8.7612 4.61766 8.73866C4.98317 8.71646 5.30023 8.99483 5.32277 9.36185L5.97707 19.9635C5.99978 20.3303 5.72073 20.6459 5.35405 20.6686ZM9.2481 20.0045C9.2481 20.3719 8.95021 20.6697 8.58269 20.6697C8.21517 20.6697 7.91729 20.3719 7.91729 20.0045V9.40272C7.91729 9.0352 8.21517 8.73732 8.58269 8.73732C8.95005 8.73732 9.2481 9.0352 9.2481 9.40272V20.0045ZM13.1561 9.44192L12.5312 20.0435C12.5105 20.3971 12.2172 20.6697 11.8677 20.6697C11.8546 20.6697 11.8413 20.6694 11.828 20.6687C11.4611 20.647 11.1812 20.3322 11.2029 19.9653L11.8276 9.36353C11.8492 8.99668 12.163 8.7168 12.5309 8.73849C12.8978 8.76002 13.1776 9.07507 13.1561 9.44192Z"
                            fill="white"
                          />
                          <path
                            d="M17.1226 5.17046L16.6856 3.86051C16.5704 3.51519 16.2471 3.28223 15.883 3.28223H1.26805C0.904064 3.28223 0.580611 3.51519 0.465561 3.86051L0.0285717 5.17046C-0.0556976 5.4231 0.0539702 5.68079 0.258672 5.8093C0.3421 5.86161 0.440835 5.89306 0.549326 5.89306H16.6019C16.7104 5.89306 16.8093 5.86161 16.8925 5.80913C17.0972 5.68062 17.2069 5.42294 17.1226 5.17046Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {listOfDBs.length > 25 && (
        <div className="pagination">
          <Typography className="paginationtext">
            <span>
              {page * rowsPerPage + 1} {"-"}{" "}
              {page * rowsPerPage + rowsPerPage < listOfDBs.length ? (
                <>{page * rowsPerPage + rowsPerPage}</>
              ) : (
                <>{listOfDBs.length}</>
              )}
            </span>
            <p>{intl.formatMessage({ id: "of" })}</p>
            <p>{listOfDBs.length}</p>
          </Typography>
          <Pagination
            count={Math.ceil(listOfDBs.length / rowsPerPage)}
            siblingCount={2}
            variant="outlined"
            shape="rounded"
            data-testid="pagination"
            onChange={handleTestDBPageChange}
          />
        </div>
      )}

      {isAddConnectionPopupEnabled && (
        <DBConnectionPopup
          setIsAddConnectionPopupEnabled={setIsAddConnectionPopupEnabled}
        />
      )}

      {/* Delete popup on DB connection list page*/}
      <div>
        <Dialog
          open={isOpenDeletePopup}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              handleIsOpenDeletePopup();
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="accessRequiredPopup DBSureDeletePopup"
        >
          <DialogContent>
            <Grid container>
              <div>
                <svg
                  width="18"
                  height="23"
                  viewBox="0 0 18 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.73414 1.33082H10.4188V1.95081H11.7494V1.24402C11.7496 0.558095 11.1918 0 10.5062 0H6.64667C5.96108 0 5.40332 0.558095 5.40332 1.24402V1.95081H6.73414V1.33082Z"
                    fill="white"
                  />
                  <path
                    d="M14.8213 7.22266H2.32927C1.98698 7.22266 1.71752 7.51466 1.74511 7.85594L2.78948 20.7698C2.84767 21.4907 3.449 22.046 4.17142 22.046H12.979C13.7014 22.046 14.3027 21.4907 14.3609 20.7697L15.4053 7.85594C15.4331 7.51466 15.1636 7.22266 14.8213 7.22266ZM5.35405 20.6686C5.34009 20.6694 5.32613 20.6699 5.31234 20.6699C4.96349 20.6699 4.67065 20.3983 4.64895 20.0454L3.99448 9.44377C3.97194 9.07692 4.25098 8.7612 4.61766 8.73866C4.98317 8.71646 5.30023 8.99483 5.32277 9.36185L5.97707 19.9635C5.99978 20.3303 5.72073 20.6459 5.35405 20.6686ZM9.2481 20.0045C9.2481 20.3719 8.95021 20.6697 8.58269 20.6697C8.21517 20.6697 7.91729 20.3719 7.91729 20.0045V9.40272C7.91729 9.0352 8.21517 8.73732 8.58269 8.73732C8.95005 8.73732 9.2481 9.0352 9.2481 9.40272V20.0045ZM13.1561 9.44192L12.5312 20.0435C12.5105 20.3971 12.2172 20.6697 11.8677 20.6697C11.8546 20.6697 11.8413 20.6694 11.828 20.6687C11.4611 20.647 11.1812 20.3322 11.2029 19.9653L11.8276 9.36353C11.8492 8.99668 12.163 8.7168 12.5309 8.73849C12.8978 8.76002 13.1776 9.07507 13.1561 9.44192Z"
                    fill="white"
                  />
                  <path
                    d="M17.1226 5.17046L16.6856 3.86051C16.5704 3.51519 16.2471 3.28223 15.883 3.28223H1.26805C0.904064 3.28223 0.580611 3.51519 0.465561 3.86051L0.0285717 5.17046C-0.0556976 5.4231 0.0539702 5.68079 0.258672 5.8093C0.3421 5.86161 0.440835 5.89306 0.549326 5.89306H16.6019C16.7104 5.89306 16.8093 5.86161 16.8925 5.80913C17.0972 5.68062 17.2069 5.42294 17.1226 5.17046Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div>
                <DialogContentText id="alert-dialog-description">
                  <Typography>
                    {intl.formatMessage({ id: "DeleteAlert" })}
                  </Typography>
                </DialogContentText>
              </div>
            </Grid>
          </DialogContent>
          <DialogActions className="ssoPopupsButton">
            <button onClick={handleIsOpenDeletePopup}>
              {intl.formatMessage({ id: "Cancel" })}
            </button>
            <button
              onClick={() => handleDBConnectionDelete(recordForDelete)}
              className="reindexpop"
            >
              {intl.formatMessage({ id: "Delete" })}
            </button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default ListsOfDBs;
