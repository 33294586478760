/**Imports of React libraries */
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
/**Imports of Reusable Components */
import SetTestDB from "./SetTestDB";
import { RootState } from "../../store/reducers/rootReducer";
/**Imports of Material UI Elements */
import { Typography } from "@material-ui/core";
/**Import disconnectTestDB to disconnect the connected connection in view db */
import { dbConnectionList } from "../../../src/store/actions/testDBOperation/testDBAction";

function DBConnections() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const connectionStatus = useSelector(
    (state: RootState) => state.testDBdata.connectionButtonStatus
  );
  const currentConfigvalue = useSelector(
    (state: RootState) => state.testDBdata.dbConfig
  );
  // Fetching Connection Details
  useEffect(() => {
    dispatch(dbConnectionList());
  }, []);

  return (
    <div>
      <div className="TestDBTitle">
        <Typography className="tabtextTestDB">
          {intl.formatMessage({ id: "SetTestDB" })}
        </Typography>
      </div>
      <div className="midContentShadowTestDB">
        <SetTestDB />
      </div>
    </div>
  );
}

export default DBConnections;
