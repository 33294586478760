/**Imports of React libraries */
import { useState, createContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
/**Imports of Reusable Components */
import { RootState } from "../../store/reducers/rootReducer";
import ListsOfDBs from "./listsOfDBs";
import DBConnectionPopup from "../Popup/dbConnectionPopup";
/**Imports of Material UI Elements */
import { Grid, Paper, Typography } from "@material-ui/core";
import { ReactComponent as NoDataSvg } from "../../assets/images/avatars/nodata.svg";

// To get the value of edited content from this component
export const editDBRecordContext = createContext(null);

function SetTestDB() {
  const intl = useIntl();

  // Edit record
  const [recordForEdit, setRecordForEdit] = useState(null);

  // Add connection popup state
  const [isAddConnectionPopupEnabled, setIsAddConnectionPopupEnabled] =
    useState<boolean>(false);

  const connectionList = useSelector(
    (state: RootState) => state.testDBdata.connectionList
  );

  const handleAddConnectionPopup = () => {
    setIsAddConnectionPopupEnabled(true);
  };

  return (
    <Grid>
      <Paper className="paper setdbform setTestDBMain">
        {/* lists of DB connections */}
        {connectionList.length > 0 ? (
          <editDBRecordContext.Provider
            value={{ value: [recordForEdit, setRecordForEdit] }}
          >
            <ListsOfDBs />
          </editDBRecordContext.Provider>
        ) : (
          <div className="setTestDBNoData">
            {/* No Database Connection Screen */}
            <NoDataSvg />
            <br />
            <Typography>
              <i>
                <strong>{intl.formatMessage({ id: "NoConnection" })}</strong>{" "}
                <br />
                {intl.formatMessage({ id: "NoConnectionMsg" })}
              </i>
            </Typography>
            <br />
            <div className="addConnectionButton">
              <button onClick={handleAddConnectionPopup}>
                {intl.formatMessage({ id: "AddConnection" })}
              </button>
            </div>
          </div>
        )}
      </Paper>

      {/* Add Connection Popup */}
      {isAddConnectionPopupEnabled && (
        <editDBRecordContext.Provider
          value={{ value: [recordForEdit, setRecordForEdit] }}
        >
          <DBConnectionPopup
            setIsAddConnectionPopupEnabled={setIsAddConnectionPopupEnabled}
          />
        </editDBRecordContext.Provider>
      )}
    </Grid>
  );
}

export default SetTestDB;
