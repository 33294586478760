/**Imports of React libraries */
import React, { useEffect, useState } from "react";
/** Import of Reuseable component */
import { useIntl } from "react-intl";
/**Imports of Material UI Elements */
import { Grid, TextField, Tooltip, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
/**Import of Config file */
import SchemaTypes from "../../../config/configSchemaType.json";
/** Import of Types */
import {SchemaDataDetailsTypes} from "./schemaCreation";
/**Imports of SVG Icons */
import { ReactComponent as Warning } from "../../../assets/images/avatars/Invalid.svg";

/** Type declaration for the param Details */
interface ParamDetailsTypes {
    value?: string | undefined
    paramName: string
    toolTip: string
    fun: (e: React.ChangeEvent<HTMLInputElement>, indexFun: number) => void

}

/** Type declaration for the schema functions */
interface SchemaCreationFunctionTypes{
    function: string
    paramCount: number
    paramName?: string[]
    tooltip?: string[]
    defaultValue?: string[]
}

/** Types for the parent component props */
interface Props {
    item: SchemaDataDetailsTypes
    indexId: number
    isFilledRequiredFields: boolean
    deleteItem: (item: SchemaDataDetailsTypes, indexId: number) => void
    setSchemaValues: React.Dispatch<React.SetStateAction<Array<SchemaDataDetailsTypes>>>
}

/** Main Function */
function SchemaForm({ item, indexId, isFilledRequiredFields, deleteItem, setSchemaValues }: Props): JSX.Element {
    /** Lang intl */
    const intl = useIntl();
    /** Storing Schema type config file into local state */
    const [typesOfSchema, setTypesOfSchema] = useState(SchemaTypes || []);

    /** based on the Type selection we're storing number of params in this state */
    const [typesOfSchemaCount, setTypesOfSchemaCount] = useState(0);

    /** based on the Type selection we're storing Tooltip details in this state */
    const [typesOfSchemaTooltip, setTypesOfSchemaTooltip] = useState<string[]>([]);

    /** based on the Type selection we're storing Tooltip details in this state */
    const [typesOfSchemaParamNames, setTypesOfSchemaParamNames] = useState<string[]>([]);

    // /** based on the Type selection we're storing Default value details in this state */
    // const [typesOfSchemaParamDefaultValues, setTypesOfSchemaParamDefaultValues] = useState<string[]>([]);

    /** Storing which type use has selected in this state */
    const [selectedType, setSelectedType] = useState("");

    /** Storing Schema types in this state from the typesOfSchema state */
    const [types, setTypes] = useState<string[]>([]);

    /** Storing Schema types in this state from the typesOfSchema state */
    useEffect(() => {
        let typeOfSchema = [] as string[];
        typesOfSchema.map((item:  SchemaCreationFunctionTypes) => {
            typeOfSchema = [...typeOfSchema, item.function];
        })
        setTypes(typeOfSchema);
    }, [typesOfSchema])

    /** Listening when the drag function get ends, we're restoring 
     * the total param counts
     * the tooltip details 
     * the param name details
     * the default value details
     on the appropriate state */
    useEffect(() => {
        typesOfSchema.map((data:  SchemaCreationFunctionTypes) => {
            if (data.function === item.function) {
                setSelectedType(item.function);
                data.tooltip ? setTypesOfSchemaTooltip(data.tooltip) : setTypesOfSchemaTooltip([]);
                data.paramCount ? setTypesOfSchemaCount(data.paramCount) : setTypesOfSchemaCount(0);
                data.paramName ? setTypesOfSchemaParamNames(data.paramName) : setTypesOfSchemaParamNames([]);
                // data.defaultValue ? setTypesOfSchemaParamDefaultValues(data.defaultValue) : setTypesOfSchemaParamDefaultValues([]);
            } else if (item.function === "") {
                setSelectedType("");
                setTypesOfSchemaTooltip([]);
                setTypesOfSchemaParamNames([]);
                // setTypesOfSchemaParamDefaultValues([]);
                setTypesOfSchemaCount(0);
            }
        });
    }, [item])

    /** Listening when the dragg function get ends, we're restoring the Param input values to the paramDetails satate */
    useEffect(() => {
        setParamDetails((prev: Array<ParamDetailsTypes>) => {
            const value = prev.map((data: ParamDetailsTypes, index: number) => {
                if (index == 0) {
                    return { ...data, value: item.param1, paramName: typesOfSchemaParamNames[0], toolTip: typesOfSchemaTooltip[0] }
                } else if (index == 1) {
                    return { ...data, value: item.param2, paramName: typesOfSchemaParamNames[1], toolTip: typesOfSchemaTooltip[1] }
                } else if (index == 2) {
                    return { ...data, value: item.param3, paramName: typesOfSchemaParamNames[2], toolTip: typesOfSchemaTooltip[2] }
                } else if (index == 3) {
                    return { ...data, value: item.param4, paramName: typesOfSchemaParamNames[3], toolTip: typesOfSchemaTooltip[3] }
                } else if (index == 4) {
                    return { ...data, value: item.param5, paramName: typesOfSchemaParamNames[4], toolTip: typesOfSchemaTooltip[4] }
                } else if (index == 5) {
                    return { ...data, value: item.param6, paramName: typesOfSchemaParamNames[5], toolTip: typesOfSchemaTooltip[5] }
                }
                return data
            })
            return value
        })
    }, [item, typesOfSchemaTooltip])


    /** This function for handle schema form name value and 
     * we're storing user entering data into our main SchemaValues state */
    const handleSchemaName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSchemaValues((prev: Array<SchemaDataDetailsTypes>) => {
            const value = prev.map((data: SchemaDataDetailsTypes, index: number) => {
                if (index == indexId) {
                    return { ...data, name: e.target.value }
                }
                return data
            })
            return value
        })
    }

    /** This function for handle schema form Type selection value and 
     * we're storing user selected data into our main SchemaValues state */
    const handleSchemaType = async (e: React.ChangeEvent<HTMLInputElement>, typeValue: string) => {
        // Storing selected data in to state
        setSelectedType(typeValue);
        // Likewise storing Tooltip and Number of param count into state
        typesOfSchema.map((data:  SchemaCreationFunctionTypes, index: number) => {
            if (data.function == typeValue) {
                data.tooltip ? setTypesOfSchemaTooltip(data.tooltip) : setTypesOfSchemaTooltip([]);
                data.paramCount ? setTypesOfSchemaCount(data.paramCount) : setTypesOfSchemaCount(0);
                data.paramName ? setTypesOfSchemaParamNames(data.paramName) : setTypesOfSchemaParamNames([]);
                // data.defaultValue ? setTypesOfSchemaParamDefaultValues(data.defaultValue) : setTypesOfSchemaParamDefaultValues([]);
            }
        });

        // we're storing user selected data into our main SchemaValues state
        setSchemaValues((prev: Array<SchemaDataDetailsTypes>) => {
            const value = prev.map((data: SchemaDataDetailsTypes, index: number) => {
                if (index == indexId) {
                    return { ...data, function: typeValue, param1: "", param2: "", param3: "", param4: "", param5: "", param6: ""}
                }
                return data
            })
            return value
        })
    }

    /** This function is common for all the params.
     * It's helps to store schema Params values into paramFunctions object state */
    const schemaParamFunHandler = (e: React.ChangeEvent<HTMLInputElement>, indexFun: number) => {
        setParamDetails((prev: Array<ParamDetailsTypes>) => {
            const value = prev.map((data: ParamDetailsTypes, index: number) => {
                if (index == indexFun) {
                    return { ...data, value: e.target.value }
                }
                return data;
            })
            return value
        })
    }

    /** Storing param details inside this state only. It contains,
     * Param input value
     * Input handler function
     * Tooltip details
     */
    const [paramDetails, setParamDetails] = useState<Array<ParamDetailsTypes>>([
        {
            value: item.param1,
            paramName: "",
            toolTip: "",
            fun: (e: React.ChangeEvent<HTMLInputElement>, indexFun: number) => {
                schemaParamFunHandler(e, indexFun);
                setSchemaValues((prev: Array<SchemaDataDetailsTypes>) => {
                    const value = prev.map((data: SchemaDataDetailsTypes, index: number) => {
                        if (index == indexId) {
                            return { ...data, param1: e.target.value }
                        }
                        return data
                    })
                    return value
                })
            }
        },
        {
            value: item.param2,
            paramName: "",
            toolTip: "",
            fun: (e: React.ChangeEvent<HTMLInputElement>, indexFun: number) => {
                schemaParamFunHandler(e, indexFun);
                setSchemaValues((prev: Array<SchemaDataDetailsTypes>) => {
                    const value = prev.map((data: SchemaDataDetailsTypes, index: number) => {
                        if (index == indexId) {
                            return { ...data, param2: e.target.value }
                        }
                        return data
                    })
                    return value
                })
            }
        },
        {
            value: item.param3,
            paramName: "",
            toolTip: "",
            fun: (e: React.ChangeEvent<HTMLInputElement>, indexFun: number) => {
                schemaParamFunHandler(e, indexFun);
                setSchemaValues((prev: Array<SchemaDataDetailsTypes>) => {
                    const value = prev.map((data: SchemaDataDetailsTypes, index: number) => {
                        if (index == indexId) {
                            return { ...data, param3: e.target.value }
                        }
                        return data
                    })
                    return value
                })
            }
        },
        {
            value: item.param4,
            paramName: "",
            toolTip: "",
            fun: (e: React.ChangeEvent<HTMLInputElement>, indexFun: number) => {
                schemaParamFunHandler(e, indexFun);
                setSchemaValues((prev: Array<SchemaDataDetailsTypes>) => {
                    const value = prev.map((data: SchemaDataDetailsTypes, index: number) => {
                        if (index == indexId) {
                            return { ...data, param4: e.target.value }
                        }
                        return data
                    })
                    return value
                })
            }
        },
        {
            value: item.param5,
            paramName: "",
            toolTip: "",
            fun: (e: React.ChangeEvent<HTMLInputElement>, indexFun: number) => {
                schemaParamFunHandler(e, indexFun);
                setSchemaValues((prev: Array<SchemaDataDetailsTypes>) => {
                    const value = prev.map((data: SchemaDataDetailsTypes, index: number) => {
                        if (index == indexId) {
                            return { ...data, param5: e.target.value }
                        }
                        return data
                    })
                    return value
                })
            }
        },
        {
            value: item.param6,
            paramName: "",
            toolTip: "",
            fun: (e: React.ChangeEvent<HTMLInputElement>, indexFun: number) => {
                schemaParamFunHandler(e, indexFun);
                setSchemaValues((prev: Array<SchemaDataDetailsTypes>) => {
                    const value = prev.map((data: SchemaDataDetailsTypes, index: number) => {
                        if (index == indexId) {
                            return { ...data, param6: e.target.value }
                        }
                        return data
                    })
                    return value
                })
            }
        },
    ]);

    return (
        <div className="schemaForm">
            <Grid container alignItems="stretch" wrap="nowrap">
                <Grid item>
                    <div className="schemaFormDragSection">
                        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <ellipse cx="1.33333" cy="8.71428" rx="1.31429" ry="1.33333" transform="rotate(-90 1.33333 8.71428)" fill="white" fillOpacity="0.8" />
                            <ellipse cx="1.33333" cy="2.14299" rx="1.31429" ry="1.33333" transform="rotate(-90 1.33333 2.14299)" fill="white" fillOpacity="0.8" />
                            <ellipse cx="8.00033" cy="8.71428" rx="1.31429" ry="1.33333" transform="rotate(-90 8.00033 8.71428)" fill="white" fillOpacity="0.8" />
                            <ellipse cx="8.00033" cy="2.14299" rx="1.31429" ry="1.33333" transform="rotate(-90 8.00033 2.14299)" fill="white" fillOpacity="0.8" />
                            <ellipse cx="14.6663" cy="8.71428" rx="1.31429" ry="1.33333" transform="rotate(-90 14.6663 8.71428)" fill="white" fillOpacity="0.8" />
                            <ellipse cx="14.6663" cy="2.14299" rx="1.31429" ry="1.33333" transform="rotate(-90 14.6663 2.14299)" fill="white" fillOpacity="0.8" />
                        </svg> <br />
                        <Typography>
                            {indexId + 1}
                        </Typography>
                    </div>
                </Grid>
                <Grid item className="schemaFormInputSection">
                    <Grid container className="schemaFormInputSectionFlex">
                        <Grid item xs={6}>
                            <div>
                                <label className="label1">
                                {intl.formatMessage({ id: "FieldName"})} *
                                </label>
                                <TextField fullWidth variant="outlined"
                                    placeholder={intl.formatMessage({ id: "PlaceHolderFieldName"})} value={item.name} 
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSchemaName(event)} />
                                    {isFilledRequiredFields && item.name == "" ?
                                        <div className="key-helper-text">
                                            <Grid container className="invalid-key" alignItems="center">
                                                <Warning />
                                                <p>{intl.formatMessage({ id: "FieldNameRequired"})}</p>
                                            </Grid>
                                        </div> : null
                                    }
                            </div>
                        </Grid>
                        <Grid item xs={6} className="schemaFormInputSectionType">
                            <div>
                                <label className="label1">
                                {intl.formatMessage({ id: "Function"})} *
                                </label>
                                <Autocomplete
                                    options={types}
                                    disableClearable
                                    value={item.function}
                                    defaultValue={item.function}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, data) => handleSchemaType(event, data)} // (event: React.ChangeEvent<{}>
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder={intl.formatMessage({ id: "PlaceHolderFunction"})}
                                            inputProps={{
                                                ...params.inputProps,
                                                "data-testid": "workspace",
                                            }}
                                        />
                                    )}
                                />
                                {isFilledRequiredFields && selectedType == "" ?
                                    <div className="key-helper-text">
                                        <Grid container className="invalid-key" alignItems="center">
                                            <Warning />
                                            <p>{intl.formatMessage({ id: "FunctionFieldRequired"})}</p>
                                        </Grid>
                                    </div> : null
                                }
                            </div>
                        </Grid>
                    </Grid>

                    {/* Parems */}
                    {typesOfSchema.map((data:  SchemaCreationFunctionTypes, index: number) => {
                        // Checking selected type and typesOfSchema is maching or not
                        if (data.function == selectedType) {
                            return (
                                <Grid container className="schemaFormParemSectionHead" key={index}>
                                    {paramDetails.slice(0, typesOfSchemaCount).map((paramData: ParamDetailsTypes, index: number) => (
                                        <Grid item className="schemaFormParemSection" key={index}>
                                            <div>
                                                <label className="label1">
                                                    {paramData.paramName} *
                                                </label>
                                                <Tooltip title={paramData.toolTip} arrow>
                                                    <TextField fullWidth variant="outlined" placeholder={`Type ${paramData.paramName} value`} value={paramData.value} onChange={(e: React.ChangeEvent<HTMLInputElement>, indexFun: number = index) => paramData.fun(e, indexFun)} />
                                                </Tooltip>
                                                {isFilledRequiredFields && paramData.value == "" ?
                                                    <div className="key-helper-text">
                                                        <Grid container className="invalid-key" alignItems="center">
                                                            <Warning />
                                                            <p>{paramData.paramName} {intl.formatMessage({ id: "FieldRequired"})}</p>
                                                        </Grid>
                                                    </div> : null
                                                }
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            )
                        }
                    })}
                </Grid>

                {/* Delete button */}
                <Grid item>
                    <button className={`schemaFormDeleteSection ${indexId == 0 ? "disabled" : ""}`} disabled={indexId == 0} onClick={() => deleteItem(item, indexId)}>
                        <svg width="19" height="25" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.45962 1.47427H11.5414V2.1611H13.0155V1.37812C13.0157 0.618254 12.3978 0 11.6383 0H7.36273C6.60323 0 5.98535 0.618254 5.98535 1.37812V2.1611H7.45962V1.47427Z" fill="#ACACAC" />
                            <path d="M16.4186 8.00122H2.57995C2.20077 8.00122 1.90226 8.3247 1.93282 8.70277L3.08976 23.0087C3.15423 23.8073 3.82038 24.4224 4.62068 24.4224H14.3777C15.178 24.4224 15.8441 23.8073 15.9086 23.0085L17.0655 8.70277C17.0963 8.3247 16.7978 8.00122 16.4186 8.00122ZM5.93079 22.8965C5.91532 22.8975 5.89986 22.898 5.88458 22.898C5.49812 22.898 5.17372 22.5971 5.14968 22.2062L4.42466 10.4618C4.39969 10.0554 4.70881 9.70561 5.11502 9.68064C5.51992 9.65605 5.87116 9.96443 5.89613 10.371L6.62097 22.1154C6.64612 22.5218 6.337 22.8714 5.93079 22.8965ZM10.2446 22.1609C10.2446 22.5678 9.9146 22.8978 9.50746 22.8978C9.10032 22.8978 8.77032 22.5678 8.77032 22.1609V10.4163C8.77032 10.0091 9.10032 9.67915 9.50746 9.67915C9.91441 9.67915 10.2446 10.0091 10.2446 10.4163V22.1609ZM14.5739 10.4597L13.8816 22.2041C13.8587 22.5958 13.5338 22.8978 13.1466 22.8978C13.132 22.8978 13.1173 22.8975 13.1026 22.8967C12.6962 22.8727 12.3861 22.5239 12.4102 22.1175L13.1022 10.3729C13.1261 9.96648 13.4738 9.65642 13.8813 9.68046C14.2877 9.70431 14.5977 10.0533 14.5739 10.4597Z" fill="#ACACAC" />
                            <path d="M18.9683 5.72777L18.4843 4.2766C18.3566 3.89406 17.9985 3.63599 17.5951 3.63599H1.40474C1.00152 3.63599 0.643198 3.89406 0.515746 4.2766L0.0316516 5.72777C-0.0617015 6.00764 0.0597879 6.2931 0.286556 6.43546C0.378977 6.49341 0.488355 6.52825 0.60854 6.52825H18.3915C18.5116 6.52825 18.6212 6.49341 18.7134 6.43527C18.9402 6.29291 19.0617 6.00745 18.9683 5.72777Z" fill="#ACACAC" />
                        </svg>
                    </button>
                </Grid>
            </Grid>
        </div>
    )
}

export default SchemaForm;