import {
  CURRENT_WORKSPACE,
  GET_WORKSPACE,
  GET_DATASOURCE,
  SET_WORKSPACE,
  SELECTED_APIKEY,
  ISVERIFIED_APIKEY,
} from "../../actions/actiontypes";

const initialState = {
  currentWorkspace: {},
  Workspacelist: {},
  dataSourceValue: {},
  setWorkspace: false,
  apikey: "",
  isVerify: false,
};

export default function workspaceReducer(state = initialState, action: any) {
  switch (action.type) {
    case CURRENT_WORKSPACE:
      return {
        ...state,
        currentWorkspace: action.payload,
      };
    case GET_WORKSPACE:
      return {
        ...state,
        Workspacelist: action.payload,
      };
    case GET_DATASOURCE:
      return {
        ...state,
        dataSourceValue: action.payload,
      };
    case SET_WORKSPACE:
      return {
        ...state,
        setWorkspace: action.payload,
      };
    case SELECTED_APIKEY:
      return {
        ...state,
        apikey: action.payload,
      };
    case ISVERIFIED_APIKEY:
      return {
        ...state,
        isVerify: action.payload,
      };
    default:
      return state;
  }
}
