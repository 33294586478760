import {
  SET_LANGUAGE,
  SET_LOG,
  SET_CURRENT_LOG_LEVEL,
  SET_LOADING,
} from "../actiontypes";

/* Select Language */
export const inmemoryhandleLanguageChange =
  (objData: any) => (dispatch: any) => {
    dispatch({
      payload: objData,
      type: SET_LANGUAGE,
    });
  };

/* Select Log Level */
export const inmemoryLogType = (log: any) => (dispatch: any) => {
  dispatch({
    payload: log,
    type: SET_LOG,
  });
};

/* Select Current Log Level*/
export const inmemoryCurrentLogLevel =
  (currentLogLevel: any) => (dispatch: any) => {
    dispatch({
      payload: currentLogLevel,
      type: SET_CURRENT_LOG_LEVEL,
    });
  };

/**Set Current Loading Screen */
export const inmemoryLoading = (IsLoading: any) => (dispatch: any) => {
  dispatch({
    payload: IsLoading,
    type: SET_LOADING,
  });
};
