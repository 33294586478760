import { GET_QUERY } from "../../actions/actiontypes";

const initialState = {
  queryList: [],
};

export default function queryManagerReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_QUERY:
      return {
        ...state,
        queryList: action.payload,
      };
    default:
      return state;
  }
}
