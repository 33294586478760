  import { config } from "../../../config/clientConfig";
  import axios, { AxiosError } from 'axios';
  import { Dispatch } from 'redux';
  import { GET_QUERY } from "../actiontypes";

  /** Get List form Queries */
  export const getQueryLists = () => async (dispatch: Dispatch) => {
    const response = await axios({
      method: "GET",
      url: config.apiRootPath + config.getQuery
    }).then((res) => {
          dispatch({
            payload: res.data?.data || [],
            type: GET_QUERY,
          });
          return res.data;
      }).catch((err: AxiosError) => err.response?.data);
    return response;
  }

  /** Test the Query */
  export const testQuery = (payload: any) => async (dispatch: Dispatch) => {
      const response = await axios({
        method: "POST",
        url: config.apiRootPath + config.testDBConnectionURL,
        data: payload
      }).then((res) => res.data).catch((err: AxiosError) => err.response?.data);
      return response;
  }
  
  /** Save the new Query */
  export const saveNewQuery = (payload: any) => async (dispatch: Dispatch) => {
    const response = await axios({
      method: "POST",
      url: config.apiRootPath + config.saveQuery,
      data: payload
    }).then((res) => res.data).catch((err: AxiosError) => err.response?.data);
    return response;
  }

  /** Save the new Query */
  export const updateQuery = (payload: any) => async (dispatch: Dispatch) => {
      const response  = await axios({
        method: "PUT",
        url: config.apiRootPath + config.editQuery,
        data: payload
      }).then((res) => res.data).catch((err: AxiosError) => err.response?.data);
      return response
  }

  /** Save the new Query */
  export const deleteQuery = (queryName: string) => (dispatch: Dispatch) => {
      const response = axios({
        method: "DELETE",
        url: config.apiRootPath + config.deleteQuery + "/" + queryName,
      }).then((res) => res.data).catch((err: AxiosError) => err.response?.data);
      return response;
  }