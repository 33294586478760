/**Imports of React libraries */
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
/**Imports of Reusable Components */
import JailerLogo from "../../assets/images/avatars/jailer-logo.png";
import { RootState } from "../../store/reducers/rootReducer";
/**Imports of Material UI Elements */
import { Grid, Link, Typography } from "@material-ui/core";

function JailerLandingPage() {
  const intl = useIntl();
  const dispatch = useDispatch();

  const connectiontStatus = useSelector(
    (state: RootState) => state.testDBdata.connectionButtonStatus
  );
  const currentConfigValue = useSelector(
    (state: RootState) => state.testDBdata.dbConfig
  );

  return (
    <section className="jailerLandingPageSection">
      <div className="jailerLandingPageHeading">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center">
              <div className="jailerLandingPageHeadingIcon">
                <img src={JailerLogo} alt="Image of jailer logo" />
              </div>
              <div className="jailerLandingPageHeadingCon">
                <Typography>
                  <span>{intl.formatMessage({ id: "jailerLPTitle" })}</span>
                </Typography>
                <Typography>
                  {intl.formatMessage({ id: "jailerLPSubTitle" })}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid item>
            <div className="jailerLandingPageBtn">
              <Link
                href="https://dataguruchatbot.blob.core.windows.net/xplatformcontainer/Jailer.zip"
                download
              >
                {intl.formatMessage({ id: "Download" })}
              </Link>
            </div>
          </Grid>
        </Grid>
      </div>
      <hr />
      <div className="jailerLandingPageMainCon">
        <div className="jailerLandingPageMainConHeading">
          <Typography>{intl.formatMessage({ id: "Description" })}</Typography>
        </div>
        <div className="jailerLandingPageMainSubCon">
          <Typography>
            {intl.formatMessage({ id: "jailerLPConTitle" })}
          </Typography>
          <ul>
            <li>{intl.formatMessage({ id: "jailerLPConPointOne" })}</li>
            <li>{intl.formatMessage({ id: "jailerLPConPointTwo" })}</li>
            <li>{intl.formatMessage({ id: "jailerLPConPointThree" })}</li>
            <li>{intl.formatMessage({ id: "jailerLPConPointFour" })}</li>
            <li>{intl.formatMessage({ id: "jailerLPConPointFive" })}</li>
          </ul>
        </div>
      </div>
      <div className="jailerLandingPageLinks">
        <Grid container alignItems="center">
          <Grid item className="jailerLandingPageLinkOne">
            <Grid container alignItems="center">
              <div>
                <svg
                  width="30"
                  height="31"
                  viewBox="0 0 30 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.386 9.55226C19.3309 4.65195 17.0807 1.55176 14.9996 1.55176C12.9185 1.55176 10.6683 4.65195 9.61328 9.55226H20.386Z"
                    fill="#6946C6"
                  />
                  <path
                    d="M9 15.5525C8.99975 16.8904 9.08896 18.2268 9.26702 19.5527H20.7337C20.9118 18.2268 21.001 16.8904 21.0008 15.5525C21.001 14.2146 20.9118 12.8782 20.7337 11.5522H9.26702C9.08896 12.8782 8.99975 14.2146 9 15.5525Z"
                    fill="#6946C6"
                  />
                  <path
                    d="M9.61328 21.5532C10.6683 26.4535 12.9185 29.5537 14.9996 29.5537C17.0807 29.5537 19.3309 26.4535 20.386 21.5532H9.61328Z"
                    fill="#6946C6"
                  />
                  <path
                    d="M22.4344 9.55232H28.7348C27.7882 7.39795 26.3498 5.49541 24.5351 3.99731C22.7204 2.49921 20.5798 1.44724 18.2852 0.925781C20.1823 2.59489 21.6704 5.68108 22.4344 9.55232Z"
                    fill="#6946C6"
                  />
                  <path
                    d="M29.4534 11.5522H22.753C22.9179 12.8793 23.0004 14.2153 23 15.5525C23.0001 16.8898 22.9173 18.2257 22.752 19.5527H29.4524C30.1817 16.9358 30.1817 14.1692 29.4524 11.5522H29.4534Z"
                    fill="#6946C6"
                  />
                  <path
                    d="M18.2852 30.1798C20.5802 29.6586 22.7211 28.6067 24.5362 27.1086C26.3513 25.6105 27.79 23.7078 28.7368 21.5532H22.4364C21.6704 25.4245 20.1823 28.5107 18.2852 30.1798Z"
                    fill="#6946C6"
                  />
                  <path
                    d="M7.56504 21.5532H1.26465C2.2115 23.7078 3.65017 25.6105 5.46525 27.1086C7.28032 28.6067 9.42126 29.6586 11.7163 30.1798C9.81719 28.5107 8.32909 25.4245 7.56504 21.5532Z"
                    fill="#6946C6"
                  />
                  <path
                    d="M11.7144 0.925781C9.41931 1.44696 7.27837 2.4988 5.46329 3.99692C3.64822 5.49503 2.20955 7.39772 1.2627 9.55232H7.56309C8.32914 5.68108 9.81723 2.59489 11.7144 0.925781Z"
                    fill="#6946C6"
                  />
                  <path
                    d="M6.99939 15.5525C6.99926 14.2152 7.08209 12.8793 7.24741 11.5522H0.546986C-0.182329 14.1692 -0.182329 16.9358 0.546986 19.5527H7.24741C7.08209 18.2257 6.99926 16.8898 6.99939 15.5525Z"
                    fill="#6946C6"
                  />
                </svg>
              </div>
              <div>
                <Link
                  href="https://wisser.github.io/Jailer/home.htm"
                  target="_blank"
                >
                  {intl.formatMessage({ id: "jailerLPDocLinkOne" })}
                </Link>
              </div>
            </Grid>
          </Grid>
          <Grid item className="jailerLandingPageLinkTwo">
            <Grid container alignItems="center">
              <div>
                <svg
                  width="29"
                  height="20"
                  viewBox="0 0 29 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.5455 3.08132C27.2214 1.87643 26.2714 0.926627 25.0667 0.602187C22.8658 0 14.0622 0 14.0622 0C14.0622 0 5.25885 0 3.05798 0.579356C1.87643 0.903452 0.903282 1.8766 0.579185 3.08132C0 5.28201 0 9.84597 0 9.84597C0 9.84597 0 14.4329 0.579185 16.6106C0.903625 17.8153 1.85326 18.7651 3.05815 19.0896C5.28202 19.6919 14.0625 19.6919 14.0625 19.6919C14.0625 19.6919 22.8658 19.6919 25.0667 19.1126C26.2716 18.7883 27.2214 17.8385 27.5458 16.6338C28.1248 14.4329 28.1248 9.86914 28.1248 9.86914C28.1248 9.86914 28.148 5.28201 27.5455 3.08132ZM11.2593 14.0623V5.62963L18.5799 9.84597L11.2593 14.0623Z"
                    fill="#6946C6"
                  />
                </svg>
              </div>
              <div>
                <Link
                  href="https://wisser.github.io/Jailer/videos.html"
                  target="_blank"
                >
                  {intl.formatMessage({ id: "jailerLPDocLinkTwo" })}
                </Link>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </section>
  );
}

export default JailerLandingPage;