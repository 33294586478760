/**Imports of React libraries */
import React from "react";

// Pro sidebar
import { MenuItem, SubMenu } from "react-pro-sidebar";

// Import navigation link
import { Link, useLocation } from "react-router-dom";

interface Props {
  items?: any;
}

function SidebarMenuChilds({ items }: Props) {
  const location = useLocation();
  return (
    <>
      {items.map(({ path, title, children }: any, index: number) => (
          children ? (
            <SubMenu key={index} title={title}>
              <SidebarMenuChilds items={children} />
            </SubMenu>
          ) : (
            <MenuItem
              key={index}
              active={location.pathname === path}
              className="proMenuItems"
            >
              {title}
              {path && <Link to={path} />}
            </MenuItem>
          )
      ))}
    </>
  );
}

export default SidebarMenuChilds;
