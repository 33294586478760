/**Imports of React libraries */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { useIntl } from "react-intl";
import { CSVLink } from "react-csv";
/**Imports of Reusable Components */
import { RootState } from "../../store/reducers/rootReducer";
/**Imports of Material UI Elements */
import {
  Grid,
  TextField,
  Typography,
  FormControl,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { Autocomplete, Pagination } from "@material-ui/lab";
/**Imports of API Actions */
import {
  GenerateSyntheticData,
  inmemoryBusinessObjectValue,
  inmemoryRecordCount,
  getListOfSchemaNames,
} from "../../store/actions/dataOperation/dataOperationAction";
/**Imports of SVG Icons */
import { ReactComponent as NoDataSvg } from "../../assets/images/avatars/nodata.svg";
import { ReactComponent as NoDataArrowSvg } from "../../assets/images/avatars/nodata-arrow.svg";
import { ReactComponent as DownloadIconSvg } from "../../assets/images/avatars/download-icon.svg";
/**Import of Config file */
import { config } from "../../config/clientConfig";
import { configBusinessObjects } from "../../config/configBusinessObjects.json";
import { syntheticLog, logLevel } from "../../config/logConfig";
/**Import of Logger files */
import { logFunctionStart, logFunctionEnd, writeLog } from "../../logManager";
/** Import of Utils file import */
import { parseArrayOfNestedObject } from "../../utils/parseObjects";

let syntheticColumn = [] as Array<string>;

function SyntheticData() {
  const intl = useIntl();
  const dispatch = useDispatch();

  // Fetching List of Schema names
  React.useEffect(() => {
    dispatch(getListOfSchemaNames());
  }, []);

  const selectedBusinessValue = useSelector(
    (state: RootState) => state.searchOperation.businessObjectValue
  );
  const schemaNames = useSelector(
    (state: RootState) => state.searchOperation.schemaNames
  );
  const recordCount = useSelector(
    (state: RootState) => state.searchOperation.countValue
  );
  const [object, setobject] = React.useState("");
  const [count, setcount] = React.useState(0);
  const [syntheticData, setsyntheticData] = React.useState([]);
  const [syntheticCol, setsyntheticCol] = React.useState([] as Array<string>);
  const [page, setpage] = React.useState(0);
  const [rowsPerPage] = React.useState(25);
  const [order, setOrder] = React.useState("asc" as any);
  const [orderBy, setOrderBy] = React.useState("");
  const [isClicked, setisClicked] = React.useState(false);
  const currentLogLevel = useSelector(
    (state: RootState) => state.landingPage.currentLogLevel
  );
  const connectionStatus = useSelector(
    (state: RootState) => state.testDBdata.connectionButtonStatus
  );
  const currentConfigValue = useSelector(
    (state: RootState) => state.testDBdata.dbConfig
  );

  /**Function to Change Business Object List */
  const handleSyntheticDataObjects = async (event: string) => {
    const startTime = logFunctionStart(
      currentLogLevel,
      "handleSyntheticDataObjects"
    );
    try {
      setobject(event);
      dispatch(inmemoryBusinessObjectValue(event));
      writeLog(
        logLevel.Information,
        currentLogLevel,
        "Changing Business Object",
        syntheticLog.businessObjects
      );
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleSyntheticDataObjects");
    }
  };

  /**Function to count */
  const handleSyntheticDataCount = (event: any) => {
    const startTime = logFunctionStart(
      currentLogLevel,
      "handleSyntheticDataObjects"
    );
    try {
      setcount(event.target.value);
      dispatch(inmemoryRecordCount(event.target.value));
      writeLog(
        logLevel.Information,
        currentLogLevel,
        "Synthetic Data Count",
        syntheticLog.dataCount
      );
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleSyntheticDataObjects");
    }
  };

  /**Function to Call API */
  const handleSyntheticDataGenerate = async () => {
    const startTime = logFunctionStart(
      currentLogLevel,
      "handleSyntheticDataGenerate"
    );
    try {
      setisClicked(true);
      setsyntheticCol([]);
      const SyntheticData: any = await dispatch(
        GenerateSyntheticData(selectedBusinessValue, recordCount)
      );

      getDriverData(SyntheticData);

      if (SyntheticData !== undefined && SyntheticData.data.length > 0) {
        if (
          Object.keys(SyntheticData.data[0]).length !== 0 &&
          SyntheticData.messageCode === config.successCode
        ) {
          syntheticColumn = Object.keys(SyntheticData.data[0]).filter((e) => {
            return !(e === "Address" || e === "Address2");
          });

          setsyntheticCol(syntheticColumn);
          setsyntheticData(SyntheticData.data);
          writeLog(
            logLevel.Information,
            currentLogLevel,
            SyntheticData.userMessage,
            syntheticLog.generateData
          );
        } else {
          setsyntheticData([]);
          writeLog(
            logLevel.Error,
            currentLogLevel,
            SyntheticData.userMessage,
            syntheticLog.generateData
          );
        }
      }
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleSyntheticDataGenerate");
    }
  };

  /**Function to handle page in pagination */
  const handleChangePage = (event: any, newPage: number) => {
    setpage(newPage - 1);
  };

  /**Function for Synthetic Table sorting by asc/desc*/
  const createSortHandler = (event: string) => () => {
    const isAsc = orderBy === event && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(event);
  };

  /**Default Material UI Function used for Synthetic Table sorting by asc/desc*/
  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  /**Default Material UI Function used for Synthetic Table sorting by asc/dsc*/
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  type Order = "asc" | "desc";

  /**Default Material UI Function used for Synthetic Table sorting by asc/desc*/
  function getComparator<Key extends string | number | symbol>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  /**Function to get the driver data */
  const getDriverData = (SyntheticData: { data: any }) => {
    if (SyntheticData.data[0].Address) {
      for (
        let dataCount = 0;
        dataCount < SyntheticData.data.length;
        ++dataCount
      ) {
        SyntheticData.data[dataCount].street =
          SyntheticData.data[dataCount].Address.street + "";
        SyntheticData.data[dataCount].state =
          SyntheticData.data[dataCount].Address.state + "";
        SyntheticData.data[dataCount].country =
          SyntheticData.data[dataCount].Address.country + "";
        SyntheticData.data[dataCount].latitude =
          SyntheticData.data[dataCount].Address.latitude + "";
        SyntheticData.data[dataCount].longitude =
          SyntheticData.data[dataCount].Address.longitude + "";
      }
    }else{
      // Here we're converting the nested object into a flat object.
      SyntheticData.data = parseArrayOfNestedObject(SyntheticData.data);
    }
  };

  return (
    <Grid className="syntheticcontainer">
      <Grid container alignItems="center" className="synthetic-top">
        <Grid
          container
          alignItems="center"
          item
          xs={12}
          md={10}
          className="synthetic-left"
        >
          <Grid xs={12} md={3} item className="dropdown-list">
            <FormControl>
              <Autocomplete
                key={`SelectSchema-${intl.formatMessage({
                  id: "SelectSchema",
                })}`}
                id="combo-box-demo"
                options={schemaNames}
                defaultValue={
                  selectedBusinessValue === ""
                    ? intl.formatMessage({
                        id: "SelectSchema",
                      })
                    : selectedBusinessValue
                }
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      "data-testid": "object",
                    }}
                  />
                )}
                onChange={(event: any, newValue: string) => {
                  handleSyntheticDataObjects(newValue);
                }}
              />
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} md={3} item className="count">
            <Typography>{intl.formatMessage({ id: "Count" })}</Typography>
            <FormControl>
              <TextField
                value={recordCount !== 0 ? recordCount : count}
                variant="outlined"
                type="number"
                inputProps={{
                  "data-testid": "count",
                }}
                onChange={handleSyntheticDataCount}
              />
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} md={4} item className="synthetic-button">
            <Button
              className={
                (object !== "" && count !== 0) || recordCount !== 0
                  ? "enable"
                  : "disable"
              }
              variant="contained"
              data-testid="generate"
              onClick={handleSyntheticDataGenerate}
              disabled={
                (object !== "" && count !== 0) || recordCount !== 0
                  ? false
                  : true
              }
            >
              {intl.formatMessage({ id: "Generate" })}
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" item xs={12} md={2}>
          {syntheticData !== undefined &&
          syntheticData !== null &&
          syntheticData.length > 0 ? (
            <CSVLink
              data={syntheticData}
              filename={"SyntheticData.csv"}
              className="btn btn-primary download-icon"
              target="_blank"
            >
              {" "}
              <DownloadIconSvg />{" "}
              <p>{intl.formatMessage({ id: "DownloadCSV" })}</p>
            </CSVLink>
          ) : null}
        </Grid>
      </Grid>

      {syntheticData.length > 0 && syntheticColumn.length > 0 ? (
        <Grid className="syntheticTablecontainer">
          {isClicked && syntheticCol.length === 0 ? (
            <div className="preloader">
              <div className="loaderVector"></div>
              <div className="loaderBrand">
                <span>Loading...</span>
              </div>
            </div>
          ) : null}
          {
            <TableContainer className="searchtable">
              <Table
                stickyHeader
                aria-label="sticky table"
                data-testid="syntheticTable"
              >
                <TableHead>
                  <TableRow>
                    {syntheticData !== undefined && syntheticData !== null ? (
                      <>
                        {syntheticColumn.map((data: any) => (
                          <TableCell
                            align="right"
                            key={data}
                            sortDirection={false}
                          >
                            <TableSortLabel
                              active={orderBy === data}
                              direction={orderBy === data ? order : "asc"}
                              data-testid="sorting"
                              onClick={createSortHandler(data)}
                            ></TableSortLabel>
                            {data}
                          </TableCell>
                        ))}
                      </>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort(syntheticData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index: number) => (
                      <TableRow
                        key={index}
                        style={
                          index % 2
                            ? { background: "rgba(0, 0, 0, 0.02)" }
                            : { background: "rgba(0, 0, 0, 0.08)" }
                        }
                      >
                        {syntheticColumn.map((cell: string, index1: number) => (
                          <TableCell align="right" key={index1}>
                            {row[cell]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
          <div className="pagination">
            <Typography className="paginationtext">
              <span>
                {page * rowsPerPage + 1} {"-"}{" "}
                {page * rowsPerPage + rowsPerPage < syntheticData.length ? (
                  <>{page * rowsPerPage + rowsPerPage}</>
                ) : (
                  <>{syntheticData.length}</>
                )}
              </span>
              <p>{intl.formatMessage({ id: "of" })}</p>
              <p>{syntheticData.length}</p>
            </Typography>
            <Pagination
              count={Math.ceil(syntheticData.length / rowsPerPage)}
              siblingCount={2}
              variant="outlined"
              shape="rounded"
              data-testid="pagination"
              onChange={handleChangePage}
            />
          </div>
        </Grid>
      ) : (
        <Grid>
          <div className="nodata-main">
            <div className="nodata">
              <NoDataArrowSvg />
              <NoDataSvg />
            </div>
            <span>
              <p>
                {intl.formatMessage({ id: "NoData" })}{" "}
                {intl.formatMessage({ id: "Please" })}
              </p>
              <p className="font">
                {intl.formatMessage({ id: "SelectBusinessObject" })}
              </p>
            </span>
          </div>
        </Grid>
      )}
    </Grid>
  );
}

export default SyntheticData;
