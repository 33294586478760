import {
  GET_TESTDB_DATA,
  DELETE_TESTDB_ITEM,
  GENERATE_DATA,
  VIEW_JOB_LIST,
  INDEX_DATA_RESULT,
  TABLE_COLUMN_LIST,
  CONNECT_TESTDB,
  CONNECTED_TESTDB,
  TEST_DBCONNECTION,
  CONNECTION_LIST,
  CONNECTION_INSERT,
  CONNECTEDDB_BUTTON_STATUS,
} from "../../actions/actiontypes";

const initialState = {
  testdata: [],
  deletedStatus: {},
  generateData: {},
  jobList: [],
  indexdata: {},
  columnArr: [],
  jobId: "",
  dbConnection: {},
  testDBConnection: {},
  dbConfig: {},
  connectionList: {},
  connectionInsert: {},
  connectionButtonStatus: false,
};

export default function testDBReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_TESTDB_DATA:
      return {
        ...state,
        testdata: action.payload,
      };
    case DELETE_TESTDB_ITEM:
      return {
        ...state,
        deletedStatus: action.payload,
      };
    case GENERATE_DATA:
      return {
        ...state,
        generateData: action.payload,
      };
    case VIEW_JOB_LIST:
      return {
        ...state,
        jobList: action.payload,
      };
    case INDEX_DATA_RESULT:
      return {
        ...state,
        indexdata: action.payload,
      };
    case TABLE_COLUMN_LIST:
      return {
        ...state,
        columnArr: action.payload,
      };
    case CONNECT_TESTDB:
      return {
        ...state,
        dbConnection: action.payload,
      };
    case CONNECTION_LIST:
      return {
        ...state,
        connectionList: action.payload,
      };
    case CONNECTION_INSERT:
      return {
        ...state,
        connectionInsert: action.payload,
      };
    case CONNECTED_TESTDB:
      return {
        ...state,
        dbConfig: action.payload,
      };
    case TEST_DBCONNECTION:
      return {
        ...state,
        testDBConnection: action.payload,
      };
    case CONNECTEDDB_BUTTON_STATUS:
      return {
        ...state,
        connectionButtonStatus: action.payload,
      };
    default:
      return state;
  }
}
