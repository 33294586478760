/**Imports of React libraries */
import React from "react";
import { useIntl } from "react-intl";
/**Imports of Material UI Elements */
import { Typography } from "@material-ui/core";
import QueryManager from "./queryManager";

function QueryManagerIndex() {
  const intl = useIntl();

  return (
    <div>
      <div className="TestDBTitle">
        <Typography className="tabtextTestDB">
          {intl.formatMessage({ id: "QueryManager" })}
        </Typography>
      </div>
      <div className="midContentShadowTestDB">
        <QueryManager />
      </div>
    </div>
  );
}

export default QueryManagerIndex;
