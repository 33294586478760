/**Imports of React libraries */
import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
/**Imports of Reusable Components */
import { RootState } from "../../store/reducers/rootReducer";
/**Imports of Material UI Elements */
import {
  Typography,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
/**Imports of SVG Icons */
import { ReactComponent as SuccessIconSvg } from "../../assets/images/avatars/success.svg";
import { ReactComponent as ErrsymbolSvg } from "../../assets/images/avatars/errsymbol.svg";
import { ReactComponent as ClockIconSvg } from "../../assets/images/avatars/clock.svg";
/**Import of Config files */
import { logLevel, viewJobsLog } from "../../config/logConfig";
/**Import of Logger files */
import { logFunctionStart, logFunctionEnd, writeLog } from "../../logManager";

function ViewJobs() {
  const intl = useIntl();
  const [page, setpage] = React.useState(0);
  const [rowsPerPage] = React.useState(25);
  const jobList = useSelector((state: RootState) => state.testDBdata.jobList);
  const currentLogLevel = useSelector(
    (state: RootState) => state.landingPage.currentLogLevel
  );

  /**Function to Change the page in Pagination*/
  const handleTestDBPageChange = (event: any, newPage: number) => {
    const startTime = logFunctionStart(
      currentLogLevel,
      "handleTestDBPageChange"
    );
    try {
      setpage(newPage - 1);
      writeLog(
        logLevel.Information,
        currentLogLevel,
        "Changing Page in View Jobs Table",
        viewJobsLog.PageChange
      );
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleTestDBPageChange");
    }
  };

  return (
    <div className="viewJobTableHead">
      <div>
        <TableContainer className="searchtable">
          <Table aria-label="table" className="joblist-table">
            <TableHead>
              <TableRow>
                <TableCell align="right">
                  {intl.formatMessage({ id: "Status" })}
                </TableCell>
                <TableCell align="right">
                  {intl.formatMessage({ id: "SubmitDate" })}
                </TableCell>
                <TableCell align="right">
                  {intl.formatMessage({ id: "CompletedDate" })}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: number) => (
                  <TableRow
                    data-testid="jobsrow"
                    key={index}
                    style={
                      index % 2
                        ? { background: "rgba(0, 0, 0, 0.02)" }
                        : { background: "rgba(0, 0, 0, 0.08)" }
                    }
                  >
                    <TableCell align="right" className="job-table">
                      {row.status === "Completed" ? (
                        <div className="success">
                          <SuccessIconSvg />
                          <p>{row.status}</p>
                        </div>
                      ) : (
                        <>
                          {row.status === "Failed" ? (
                            <div className="failed">
                              <ErrsymbolSvg />
                              <p>{row.status}</p>
                            </div>
                          ) : (
                            <>
                              {row.status === "Running" ? (
                                <div className="running">
                                  <ClockIconSvg />
                                  <p>{row.status}</p>
                                </div>
                              ) : null}
                            </>
                          )}
                        </>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {row.startScanTime.datetime}
                    </TableCell>
                    <TableCell align="right">
                      {row.endScanTime.datetime}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="pagination">
          <Typography className="paginationtext">
            <span>
              {page * rowsPerPage + 1} {"-"}{" "}
              {page * rowsPerPage + rowsPerPage < jobList.length ? (
                <>{page * rowsPerPage + rowsPerPage}</>
              ) : (
                <>{jobList.length}</>
              )}
            </span>
            <p>{intl.formatMessage({ id: "of" })}</p>
            <p>{jobList.length}</p>
          </Typography>
          <Pagination
            count={Math.ceil(jobList.length / rowsPerPage)}
            siblingCount={2}
            variant="outlined"
            shape="rounded"
            data-testid="pagination"
            onChange={handleTestDBPageChange}
          />
        </div>
      </div>
    </div>
  );
}

export default ViewJobs;
