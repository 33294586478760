/**Imports of React libraries */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
/**Imports of Reusable Components */
import { RootState } from "../../store/reducers/rootReducer";
/**Imports of Material UI Elements */
import {
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
/**Imports of API Actions */
import { StartIndexData } from "../../../src/store/actions/testDBOperation/testDBAction";
/**Imports of SVG Icons */
import CheckedIconSvg from "../../assets/images/avatars/checked.svg";
import { ReactComponent as CrossIconSvg } from "../../assets/images/avatars/cross.svg";
import WorkProcess from "../../assets/images/avatars/work-process.svg";
import { ReactComponent as DatabaseSvg } from "../../assets/images/avatars/database.svg";
import { ReactComponent as HostSvg } from "../../assets/images/avatars/hosting-server.svg";
import { ReactComponent as UsbPortSvg } from "../../assets/images/avatars/usb-port.svg";
import { ReactComponent as UserIconSvg } from "../../assets/images/avatars/userIcon.svg";
import { ReactComponent as PasswordSvg } from "../../assets/images/avatars/password.svg";
/**Import of Config file */
import { config } from "../../config/clientConfig";
import { variables } from "../../config/variables.config";
import { indexDataLog, logLevel } from "../../config/logConfig";
/**Import of Logger files */
import { logFunctionStart, logFunctionEnd, writeLog } from "../../logManager";

function ReindexTestdata() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isIndex, setisIndex] = React.useState(false);
  const [indexsuccess, setindexsuccess] = React.useState(false);
  const [indexError, setindexError] = React.useState(false);
  const [isBusy, setisBusy] = React.useState(false);
  const currentLogLevel = useSelector(
    (state: RootState) => state.landingPage.currentLogLevel
  );
  // const dbConfig = useSelector((state: RootState) => state.testDBdata.dbConfig);
  const dbConfig = variables.reindexDBConfig;
  const indexdata = useSelector(
    (state: RootState) => state.testDBdata.indexdata
  );

  /**Function Index Confirmation popup*/
  const indexConfirmation = () => {
    const startTime = logFunctionStart(currentLogLevel, "indexConfirmation");
    try {
      setisIndex(true);
      writeLog(
        logLevel.Information,
        currentLogLevel,
        "Indexing Confirmation",
        indexDataLog.indexConfirmation
      );
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "indexConfirmation");
    }
  };

  /**Function call API for Indexing Records*/
  const handleStartIndex = async () => {
    const startTime = logFunctionStart(currentLogLevel, "handleStartIndex");
    try {
      let indexdata: any = [];
      setisBusy(true);
      setisIndex(false);
      setindexError(false);
      setindexsuccess(false);
      indexdata = await dispatch(StartIndexData());
      setTimeout(async () => {
        indexdata = await dispatch(StartIndexData());
        if (
          indexdata.messageCode !== undefined &&
          indexdata.messageCode === config.successCode
        ) {
          setindexsuccess(true);
          setindexError(false);
          setisBusy(false);
          writeLog(
            logLevel.Information,
            currentLogLevel,
            indexdata.userMessage,
            indexDataLog.startIndex
          );
        } else {
          setindexsuccess(false);
          setindexError(true);
          setisBusy(false);
          writeLog(
            logLevel.Error,
            currentLogLevel,
            indexdata.userMessage,
            indexDataLog.startIndex
          );
        }
      }, 5000);
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleStartIndex");
    }
  };

  /**Function to close Indexing popup message*/
  const handleCloseIndex = () => {
    const startTime = logFunctionStart(currentLogLevel, "handleCloseIndex");
    try {
      setindexsuccess(false);
      setindexError(false);
      setisIndex(false);
      writeLog(
        logLevel.Information,
        currentLogLevel,
        "Close Indexing popup message",
        indexDataLog.closeIndex
      );
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleCloseIndex");
    }
  };

  return (
    <Grid>
      <Paper className="paper setdbform">
        <Grid className="setdbform-top">
          <Grid container className="testdbform">
            <Grid container alignItems="center" item sm={4} xs={12}>
              <DatabaseSvg />
              <Typography>{intl.formatMessage({ id: "Database" })}</Typography>
            </Grid>
            <Grid item xs={1} className="setdbform-top-CT">
              <Typography>:</Typography>
            </Grid>
            <Grid item sm={7} xs={12}>
              <TextField
                className="form-input"
                disabled
                defaultValue="autodb"
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container className="testdbform">
            <Grid container alignItems="center" item sm={4} xs={12}>
              <HostSvg />
              <Typography>{intl.formatMessage({ id: "Host" })}</Typography>
            </Grid>
            <Grid item xs={1} className="setdbform-top-CT">
              <Typography>:</Typography>
            </Grid>
            <Grid item sm={7} xs={12}>
              <TextField
                className="form-input"
                type="password"
                disabled
                defaultValue={dbConfig.hostname}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container className="testdbform">
            <Grid container alignItems="center" item sm={4} xs={12}>
              <UsbPortSvg />
              <Typography>{intl.formatMessage({ id: "Port" })}</Typography>
            </Grid>
            <Grid item xs={1} className="setdbform-top-CT">
              <Typography>:</Typography>
            </Grid>
            <Grid item sm={7} xs={12}>
              {" "}
              <TextField
                className="form-input"
                type="password"
                disabled
                defaultValue={dbConfig.port}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container className="testdbform">
            <Grid container alignItems="center" item sm={4} xs={12}>
              <UserIconSvg />
              <Typography>{intl.formatMessage({ id: "Username" })}</Typography>
            </Grid>
            <Grid item xs={1} className="setdbform-top-CT">
              <Typography>:</Typography>
            </Grid>
            <Grid item sm={7} xs={12}>
              {" "}
              <TextField
                className="form-input"
                type="password"
                disabled
                defaultValue={dbConfig.username}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container className="testdbform">
            <Grid container alignItems="center" item sm={4} xs={12}>
              <PasswordSvg />
              <Typography>{intl.formatMessage({ id: "Password" })}</Typography>
            </Grid>
            <Grid item xs={1} className="setdbform-top-CT">
              <Typography>:</Typography>
            </Grid>
            <Grid item sm={7} xs={12}>
              <TextField
                className="form-input"
                type="password"
                disabled
                defaultValue={dbConfig.password}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container className="connect">
            <Grid xs={7} className="connect-button">
              <Button
                className={isBusy ? "indexing-button" : "connect-enable"}
                variant="contained"
                color="primary"
                onClick={indexConfirmation}
                disabled={isBusy}
              >
                {isBusy ? (
                  <CircularProgress className={"loader"} size={20} />
                ) : null}
                {isBusy
                  ? intl.formatMessage({ id: "Indexing" })
                  : intl.formatMessage({ id: "IndexData" })}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <div>
        <div>
          <Dialog
            open={isIndex}
            onClose={handleCloseIndex}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent className="popup">
              <DialogContentText id="alert-dialog-description">
                <img src={WorkProcess} alt="" className="delete-disable" />
              </DialogContentText>
              <DialogContentText id="alert-dialog-description">
                {intl.formatMessage({ id: "IndexConfirmation" })}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleStartIndex} variant="contained">
                {intl.formatMessage({ id: "Yes" })}
              </Button>
              <Button
                onClick={handleCloseIndex}
                variant="contained"
                color="primary"
                className="reindexpop"
              >
                {intl.formatMessage({ id: "No" })}
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <Dialog
          open={indexsuccess}
          onClose={handleCloseIndex}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className="popup">
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <img src={CheckedIconSvg} alt="" className="delete-disable" />
              </DialogContentText>
            </DialogContent>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <DialogTitle className="success-title">
                  <span>{intl.formatMessage({ id: "Success" })}</span>
                </DialogTitle>
              </DialogContentText>
              <DialogContentText id="alert-dialog-description">
                {intl.formatMessage({ id: "IndexingSuccess" })}
              </DialogContentText>
            </DialogContent>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseIndex}
              color="primary"
              variant="contained"
              className="reindexpop"
            >
              {intl.formatMessage({ id: "OK" })}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={indexError}
          onClose={handleCloseIndex}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className="popup">
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <CrossIconSvg className="delete-disable" />
              </DialogContentText>
            </DialogContent>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <DialogTitle className="success-title">
                  <span className="alertInfoMarginLeft">
                    {" "}
                    {intl.formatMessage({ id: "Failed" })}
                  </span>
                </DialogTitle>
              </DialogContentText>
              <DialogContentText
                id="alert-dialog-description"
                style={{ marginLeft: "10px" }}
              >
                {intl.formatMessage({ id: "IndexingFailed" })}
                {indexdata.messageCode}
              </DialogContentText>
            </DialogContent>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseIndex} variant="contained">
              {intl.formatMessage({ id: "Cancel" })}
            </Button>
            <Button
              onClick={handleStartIndex}
              color="primary"
              variant="contained"
              className="index-bu reindexpop"
            >
              {intl.formatMessage({ id: "TryAgain" })}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Grid>
  );
}

export default ReindexTestdata;
