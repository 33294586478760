/**Imports of React libraries */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useIntl } from "react-intl";
import { CSVLink } from "react-csv";
/**Imports of Reusable Components */
import { RootState } from "../../store/reducers/rootReducer";
/**Imports of SVG Icons */
import { ReactComponent as NoDataSvg } from "../../assets/images/avatars/nodata.svg";
import { ReactComponent as NoDataArrowSvg } from "../../assets/images/avatars/nodata-arrow.svg";
import { ReactComponent as DownloadIconSvg } from "../../assets/images/avatars/download-icon.svg";
/**Import of Config file */
import { config } from "../../config/clientConfig";
import { configBusinessObjects } from "../../config/configBusinessObjects.json";
import { dbProfileLog, logLevel } from "../../config/logConfig";

/**Imports of API Actions */
import { dbProfileGenerateDataAction } from "../../store/actions/dbOperation/dbOperationAction";

/**Import of Logger files */
import { logFunctionStart, logFunctionEnd, writeLog } from "../../logManager";

/**Import of dbConnectionList to avoid No DB connection Available at App start */
import {
  dbConnectionList,
  testDBConnection,
} from "../../../src/store/actions/testDBOperation/testDBAction";
import { DataProfile } from "../../../src/store/actions/dbOperation/dbOperationAction";
import ProfilingResult from "../DBOperation/dataProfiling/profilingResult";
/** import from utils file */
import { parseArrayOfNestedObject } from "../../utils/parseObjects";



function DataProfiling() {
  const intl = useIntl();
  const dispatch = useDispatch();
  //let dbProfileGenerateData: any;

  // useEffect(() => {
  //   dispatch(dbConnectionList());
  // }, []);

  const currentLogLevel = useSelector(
    (state: RootState) => state.landingPage.currentLogLevel
  );

  const connectionList = useSelector(
    (state: RootState) => state.testDBdata.connectionList
  );

  const [isclicked, setisclicked] = React.useState<boolean>(false);
  const [DBConnectionValue, setDBConnectionValue] = React.useState({
    DBType: "",
    Username: "",
    Password: "",
    Hostname: "",
    Port: "",
    Database: "",
    DBFile: "",
  });
  const [isProfilingLoading, setIsProfilingLoading] =
    React.useState<boolean>(false);

  const [profiledData, setProfiledData] = React.useState();

  const connectiontStatus = useSelector(
    (state: RootState) => state.testDBdata.connectionButtonStatus
  );
  const currentConfigValue = useSelector(
    (state: RootState) => state.testDBdata.dbConfig
  );
  //state for store generated data
  const [generateData, setGenerateData] = React.useState([]);

  // useEffect(() => {
  //   const data = dispatch(DataProfile(values));
  //   console.log("data", data);
  // });

  /**Function to call POST API for selected DB for Data Profiling */
  const handleDBProfileGenerateData = async () => {
    if(DBConnectionValue.DBType === "mysql"){
      setisclicked(true);
      setIsProfilingLoading(true);
      const startTime = logFunctionStart(
        currentLogLevel,
        "handleDBProfileGenerateData"
      );
      try {
        const dbProfileGenerateData: any = await dispatch(
          DataProfile(DBConnectionValue)
        );
        setProfiledData(dbProfileGenerateData.response);
        //converting nested array of object to flat object
        const convertedData = parseArrayOfNestedObject(dbProfileGenerateData.response.TableInfo);
        if(convertedData.length > 0){
          setGenerateData(convertedData.length > 0 ? convertedData : dbProfileGenerateData.response);
          setIsProfilingLoading(false);
          writeLog(
            logLevel.Information,
            currentLogLevel,
            "dbProfileGenerateData",
            dbProfileLog.dbProfileGenerateData
          );
        }
        else{
          setisclicked(false);
          alert("selected Database in MySQL has no table info, Please select another Database in MySQL!");
        }
      } finally {
        logFunctionEnd(currentLogLevel, startTime, "handleDBProfileGenerateData");
      }
    }else {
      alert("Data Guru currently profiling for MySQL DBs only!!");
    }
  };

  const handleDBConnections = (newValue: any) => {
    setDBConnectionValue({
      DBType:  newValue["dbtype"] === "MySQL" ? "mysql" : newValue["dbtype"],
      Username: newValue["username"],
      Password: newValue["password"],
      Hostname: newValue["hostname"],
      Port: newValue["port"],
      Database: newValue["database"],
      DBFile: "",
    });
    setGenerateData([]);
    setisclicked(false);
  };

  return Object.keys(connectionList).length ? (
    <div className="dataProfilingHead">
      <Grid className="syntheticcontainer">
        <Grid container alignItems="center" className="synthetic-top">
          <Grid
            container
            alignItems="center"
            xs={12}
            md={10}
            className="synthetic-left"
          >
            <Grid xs={12} sm={3} className="dropdown-list">
              <FormControl>
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  options={connectionList ? connectionList : []}
                  disableClearable
                  getOptionLabel={(connectionList) =>
                    connectionList.connectionname
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={intl.formatMessage({ id: "SelectDatabse" })}
                      inputProps={{
                        ...params.inputProps,
                        "data-testid": "object",
                      }}
                    />
                  )}
                  onChange={(
                    event: any,
                    newValue: { connectionname: string }
                  ) => {
                    handleDBConnections(newValue);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6} className="synthetic-button">
              <Button
                className={generateData.length > 0 || DBConnectionValue.DBType === "" ? "disable" : "enable"}
                disabled={generateData.length > 0 || DBConnectionValue.DBType === "" ? true : false}
                variant="contained"
                data-testid="generate"
                onClick={() => handleDBProfileGenerateData()}
              >
                {intl.formatMessage({ id: "GenerateProfile" })}
              </Button>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" item xs={12} md={2}>
          {generateData !== undefined &&
          generateData !== null &&
          generateData.length > 0 ? (
            <CSVLink
              data={generateData}
              filename={"DataProfilingResult.csv"}
              className="btn btn-primary download-icon"
              target="_blank"
            >
              {" "}
              <DownloadIconSvg />{" "}
              <p>{intl.formatMessage({ id: "DownloadCSV" })}</p>
            </CSVLink>
          ) : null}
        </Grid>
          {/* <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            className="btn btn-primary download-icon cursor-pointer"
            xs={12}
            md={2}
          >
            <DownloadIconSvg />{" "}
            <p>{intl.formatMessage({ id: "DownloadReport" })}</p>
          </Grid> */}
        </Grid>
      </Grid>

      <div className="dataProfilingReport">
        {isclicked ? 
        (
          isProfilingLoading ? (
            <div className="boxShadow"><span>{intl.formatMessage({ id: "Loading" })}</span></div>
          ) :  
          (
            <ProfilingResult profiledData={profiledData} />
          )
        ) : (
          <div className="boxShadow">
            <Grid>
              <div className="nodata-main">
                <div className="nodata">
                  <NoDataArrowSvg />
                  <NoDataSvg />
                </div>
                <span>
                  <p>{intl.formatMessage({ id: "SelectDBConnectionMsg" })}</p>
                  <p className="font">
                    {intl.formatMessage({ id: "GenerateProfileDataMsg" })}
                  </p>
                </span>
              </div>
            </Grid>
          </div>
        )}
      </div>
    </div>
  ) : (
    "No DB Connection Available"
  );
}

export default DataProfiling;
