/**Imports of React libraries */
import React from "react";

// Pro sidebar
import { MenuItem, SubMenu, Menu } from "react-pro-sidebar";

// Import navigation link
import { Link, useLocation } from "react-router-dom";

// Import array for sidebar structure to implment in sidebar
import appMenuItems from "../../config/sidebarConfig";

// Import childer elements maping
import SidebarMenuChilds from "./sidebarMenuChilds";

function SidebarMenuHead() {
  const location = useLocation();
  return (
    <>
      {appMenuItems.map(
        ({ path, title, children, Icon }: any, index: number) => {
          return (
            children ? (
                <Menu subMenuBullets={true} key={index}>
                  <SubMenu
                    title={title}
                    icon={<Icon />}
                    className="firstLevelMenu"
                  >
                    <SidebarMenuChilds items={children} />
                  </SubMenu>
                </Menu>
              ) : (
                <MenuItem
                  key={index}
                  active={location.pathname === path}
                  icon={<Icon />}
                >
                  {title}
                  {path && <Link to={path} />}
                </MenuItem>
              )
          );
        }
      )}
    </>
  );
}

export default SidebarMenuHead;
