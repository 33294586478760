/**Imports of React libraries */
import { useState, createContext } from "react";
import { useSelector } from "react-redux";
/**Imports of Reusable Components */
import { useIntl } from "react-intl";
import { RootState } from "../../../store/reducers/rootReducer";
import AddQueryPopup from "../../Popup/addQueryPopup";
import ListsOfQueries from "./listOfQueries";
/**Imports of Material UI Elements */
import { Grid, Paper, Typography } from "@material-ui/core";
/** Import of Icons */
import { ReactComponent as NoDataSvg } from "../../../assets/images/avatars/no-query-vector.svg";

/** To get the value of edited content from this component */
export const editQueryRecordContext = createContext(null);

function QueryManger(): JSX.Element {
  const intl = useIntl();

  /** Edit record */
  const [recordForEdit, setRecordForEdit] = useState(null);

  /** Add Query popup state */
  const [isAddQueryPopupEnabled, setIsAddQueryPopupEnabled] =
    useState<boolean>(false);

  /** Get list of queries from the redux store */
  const queryList = useSelector(
    (state: RootState) => state.queryManagerData.queryList
  );

  /** This function for handle add query popup visibility */
  const handleAddQueryPopup = () => {
    setIsAddQueryPopupEnabled(true);
  };

  return (
    <Grid>
      <Paper className="paper setdbform setTestDBMain">
        {/* lists of DB Querys */}
        {queryList.length > 0 ? (
          <editQueryRecordContext.Provider
            value={{ value: [recordForEdit, setRecordForEdit] }}
          >
            <ListsOfQueries />
          </editQueryRecordContext.Provider>
        ) : (
          <div className="setTestDBNoData">
            {/* No Database Query Screen */}
            <NoDataSvg />
            <br />
            <Typography>
              <i>
                <strong>{intl.formatMessage({ id: "NoQuery" })}</strong>
                <br />
                {intl.formatMessage({ id: "NoQueryMsg" })}
              </i>
            </Typography>
            <br />
            <div className="addConnectionButton">
              <button onClick={handleAddQueryPopup}>
                {intl.formatMessage({ id: "AddQuery" })}
              </button>
            </div>
          </div>
        )}
      </Paper>

      {/* Add Query Popup */}
      {isAddQueryPopupEnabled && (
        <editQueryRecordContext.Provider
          value={{ value: [recordForEdit, setRecordForEdit] }}
        >
          <AddQueryPopup
            setIsAddQueryPopupEnabled={setIsAddQueryPopupEnabled}
          />
        </editQueryRecordContext.Provider>
      )}
    </Grid>
  );
}

export default QueryManger;
