/**Imports of React libraries */
import { useEffect, useState } from "react";
//import AutoProfileData from "../../../config/autoDBProfileConfig.json";
/** Import of config file */
import { variables } from "../../../config/variables.config";
/** Imports of Chart JS  */
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
/** Imports of Material UI  */
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import TableBarChart from "./tableBarChart";
import TableDoughnutChart from "./tableDoughnutChart";
/** chartjs registering the initial needs */
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

// Initial value of DB table columns
const columnDetails = {
  nullValues: "",
  uniqueValues: "",
  topFiveValues: [],
  bottomFiveValues: [],
}
// Initial value of DB table record drilldown
const dbTableDrillDownDetails = {
  tableName: "",
  countOfRecords: 0 as number,
  label: [] as any,
  values: [] as any,
}

const ProfilingResult = ({ profiledData }: any) => {
  const intl = useIntl();
  /** Get DB name from the config */
  const ProfileDBTitle = profiledData.DBName;
  const dbProfiledData = profiledData.TableInfo;
  /** State for store top level table names */
  const [tableLabels, setTableLabels] = useState([]);
  const [isDrilldown, setIsDrilldown] = useState(true);
  /** State for store the table label and its values */
  const [dbTableChart, setTableChart] = useState({
    label: [] as string[],
    recordCounts: [] as number[],
    columnCounts: [] as number[],
  });

  /** State for store the table records label and its values */
  const [drillDownTableChart, setDrillDownTableChart] = useState(dbTableDrillDownDetails);

  // state for donut chart
  const [drillDownColumnData, setDrillDownColumnData] = useState<any>(columnDetails);
  /** State for handle database tabel and Graph visibility */
  const [databaseTablesView, setDatabseTablesView] = useState(variables.dataProfilingTableText);

  /** This function for split the object property name as an signle array
   * And It ingores, If there any object or array contains
   */
  const getLabel = (requestLabelData: any) => {
    const data: any = requestLabelData.slice(0, 1).map((data: any) => {
      const label: string[] = [];
      for (const property in data) {
        if (typeof data[property] !== "object") {
          label.push(property);
        }
      }
      return label;
    });
    return data;
  };

  /** Here we're manipulating the table count and names in the in memory */
  useEffect(() => {
      const data = getLabel(dbProfiledData);
      setTableLabels(data[0]);
  }, []);

  /** Here we're manipulating the record count and names in the in memory */
  useEffect(() => {
    const label: string[] = [];
    const recordCount: number[] = [];
    const coulmCount: number[] = [];
    dbProfiledData.map((data: any) => {
      label.push(data.TableName);
      recordCount.push(data.RecordCount);
      coulmCount.push(data.ColumnCount);
    });
    setTableChart({ label: label, recordCounts: recordCount, columnCounts: coulmCount });
  }, []);

  /** This function for handle the table click event on the chart */
  const drillDownTableHandler = (sourceIndex: number) => {
      // Resetting the state on Every table drill down click
      setDrillDownTableChart(dbTableDrillDownDetails);
      const drillIndex = sourceIndex;
      dbProfiledData.map((el: any, index: number) => {
        if (index === drillIndex) {
          const data: string[] = getLabel(el.ColumnInfo);
          setDrillDownTableChart({
            tableName: el.TableName,
            countOfRecords: parseInt(el.RecordCount),
            values: el,
            label: data[0],
          });
          setIsDrilldown(false);
        }
      });
  };

  //implement the doughnut chart function
  const drillDownColumnHandler = (row: any) => {
    const nullPercent = (
      ((drillDownTableChart.countOfRecords - row.NullValues) /
        drillDownTableChart.countOfRecords) *
      100
    ).toFixed(2);
    const uniquePercent = (
      ((drillDownTableChart.countOfRecords - row.UniqueCount) /
        drillDownTableChart.countOfRecords) *
      100
    ).toFixed(2);
    setDrillDownColumnData({
      nullValues: nullPercent,
      uniqueValues: uniquePercent,
      topFiveValues: row.TopFiveFreq,
      bottomFiveValues: row.BotFiveFreq,
    });
  };

  return (
    <section className="dataProfilingResultSection">
      {isDrilldown ? (
        <div className="boxShadow">
            <div className="dataProfilingResults">
              <Grid container justifyContent="space-between">
                  <Grid container item md={4} className="DPDatabseNameAndCount">
                    <Grid item xs={7}>
                      <Typography>{intl.formatMessage({ id: "TableName" })} <br /> <span>{ProfileDBTitle}</span></Typography>
                    </Grid>
                    <Grid item xs={5} className="DPDatabseCount">
                      <Typography>{intl.formatMessage({ id: "TableCount" })} <br /> <span>{dbProfiledData.length}</span></Typography>
                    </Grid>
                  </Grid>

                  <Grid container item md={3} className={`DPDatabaseList ${databaseTablesView === variables.dataProfilingTableText ? "tableView" : "graphView"}`}>
                    <Grid container item xs={6} component="button" className="DPDatabaseTable" onClick={() => setDatabseTablesView("table")}>
                      <Grid item>
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.63867 6.11108H14.3609V10.3888H7.63867V6.11108Z" fill="#6946C6" fillOpacity="0.7"/>
                          <path d="M15.583 6.11108H21.9996V10.3888H15.583V6.11108Z" fill="#6946C6" fillOpacity="0.7"/>
                          <path d="M7.63867 17.1111H14.3609V21.9999H7.63867V17.1111Z" fill="#6946C6" fillOpacity="0.7"/>
                          <path d="M0 6.11108H6.41656V10.3888H0V6.11108Z" fill="#6946C6" fillOpacity="0.7"/>
                          <path d="M7.63867 11.6111H14.3609V15.8888H7.63867V11.6111Z" fill="#6946C6" fillOpacity="0.7"/>
                          <path d="M21.9998 4.88883V3.05546C21.9983 1.36861 20.6312 0.00151061 18.9444 0H3.05546C1.36861 0.00151061 0.00151061 1.36861 0 3.05546V4.88883H21.9998Z" fill="#6946C6" fillOpacity="0.7"/>
                          <path d="M6.41656 17.1111H0V18.9445C0.00151061 20.6313 1.36861 21.9984 3.05546 21.9999H6.41656V17.1111Z" fill="#6946C6" fillOpacity="0.7"/>
                          <path d="M0 11.6111H6.41656V15.8888H0V11.6111Z" fill="#6946C6" fillOpacity="0.7"/>
                          <path d="M15.583 17.1111V21.9999H18.9441C20.631 21.9984 21.9981 20.6313 21.9996 18.9445V17.1111H15.583Z" fill="#6946C6" fillOpacity="0.7"/>
                          <path d="M15.583 11.6111H21.9996V15.8888H15.583V11.6111Z" fill="#6946C6" fillOpacity="0.7"/>
                        </svg>
                      </Grid>
                      <Grid item>
                        <Typography>{intl.formatMessage({ id: "Table" })}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={6} component="button" className="DPDatabaseGraph" onClick={() => setDatabseTablesView("graph")}>
                      <Grid item>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.10156 14.6875C4.10156 14.3638 3.83936 14.1016 3.51562 14.1016H0.585938C0.262207 14.1016 0 14.3638 0 14.6875V19.4141C0 19.7378 0.262207 20 0.585938 20H3.51562C3.83936 20 4.10156 19.7378 4.10156 19.4141V14.6875Z" fill="black" fillOpacity="0.7"/>
                          <path d="M8.82812 10.5859H5.89844C5.57471 10.5859 5.3125 10.8481 5.3125 11.1719V19.4141C5.3125 19.7378 5.57471 20 5.89844 20H8.82812C9.15186 20 9.41406 19.7378 9.41406 19.4141V11.1719C9.41406 10.8481 9.15186 10.5859 8.82812 10.5859Z" fill="black" fillOpacity="0.7"/>
                          <path d="M14.1016 9.41406H11.1719C10.8481 9.41406 10.5859 9.67627 10.5859 10V19.4141C10.5859 19.7378 10.8481 20 11.1719 20H14.1016C14.4253 20 14.6875 19.7378 14.6875 19.4141V10C14.6875 9.67627 14.4253 9.41406 14.1016 9.41406Z" fill="black" fillOpacity="0.7"/>
                          <path d="M19.4141 4.72656H16.4844C16.1606 4.72656 15.8984 4.98877 15.8984 5.3125V19.4141C15.8984 19.7378 16.1606 20 16.4844 20H19.4141C19.7378 20 20 19.7378 20 19.4141V5.3125C20 4.98877 19.7378 4.72656 19.4141 4.72656Z" fill="black" fillOpacity="0.7"/>
                          <path d="M2.08984 12.8906C3.05908 12.8906 3.84766 12.1021 3.84766 11.1328C3.84766 10.9681 3.81763 10.812 3.77515 10.6609L6.05811 9.04932C6.34473 9.25281 6.69287 9.375 7.07031 9.375C7.95959 9.375 8.68884 8.70898 8.80444 7.85083L11.1421 7.35864C11.4515 7.86304 12.0031 8.20312 12.6367 8.20312C13.606 8.20312 14.3945 7.41455 14.3945 6.44531C14.3945 6.21094 14.3464 5.98804 14.2629 5.78345L17.0673 3.29077C17.3187 3.42957 17.6031 3.51562 17.9102 3.51562C18.8794 3.51562 19.668 2.72705 19.668 1.75781C19.668 0.788574 18.8794 0 17.9102 0C16.9409 0 16.1523 0.788574 16.1523 1.75781C16.1523 1.99219 16.2004 2.21509 16.2839 2.41968L13.4796 4.91235C13.2281 4.77356 12.9437 4.6875 12.6367 4.6875C11.7474 4.6875 11.0182 5.35352 10.9026 6.21167L8.56494 6.70386C8.25549 6.19946 7.70398 5.85938 7.07031 5.85938C6.10107 5.85938 5.3125 6.64795 5.3125 7.61719C5.3125 7.78186 5.34253 7.93799 5.38501 8.08911L3.10205 9.70068C2.81543 9.49719 2.46729 9.375 2.08984 9.375C1.12061 9.375 0.332031 10.1636 0.332031 11.1328C0.332031 12.1021 1.12061 12.8906 2.08984 12.8906Z" fill="black" fillOpacity="0.7"/>
                        </svg>
                      </Grid>
                      <Grid item>
                        <Typography>{intl.formatMessage({ id: "Graph" })}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
              </Grid>
            </div>
          <Grid className="syntheticTablecontainer">
            {databaseTablesView === variables.dataProfilingTableText &&
              <TableContainer className="searchtable">
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  //data-testid="syntheticTable"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                      {intl.formatMessage({ id: "SINo" })}
                      </TableCell>
                      {tableLabels.map((labelName: string, index: number) => (
                        <TableCell
                        
                          key={index}
                        >
                          {labelName}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dbProfiledData.map((row: any, index: number) => (
                      <TableRow 
                        key={index}
                        style={
                          index % 2
                            ? { background: "rgba(0, 0, 0, 0.02)" }
                            : { background: "rgba(0, 0, 0, 0.08)" }
                        }
                        onClick={() => drillDownTableHandler(index)}
                        className="cursor-pointer"
                      >
                        <TableCell>
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          {row.TableName}
                        </TableCell>
                        <TableCell>
                          {row.RecordCount}
                        </TableCell>
                        <TableCell>
                          {row.ColumnCount}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </Grid>
          
          {databaseTablesView === variables.dataProfilingGraphText &&
          <div>
            <TableBarChart drillDownTableHandler={drillDownTableHandler} dbTableChart={dbTableChart} />
          </div>
          }
        </div>
      ) : (
        <div>
          {drillDownTableChart.values.ColumnInfo && (
            <div>
              <div className="boxShadow">
                <Grid container component="button" onClick={()=> {setIsDrilldown(true); setDrillDownColumnData(columnDetails)}} className="DPBackButton" xs={4} sm={2} md={1}>
                  <Grid item xs={3}>
                    <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M-5.45384e-07 11C-5.45384e-07 11.3943 0.144056 11.7886 0.431564 12.0892L9.48337 21.5487C10.0592 22.1504 10.9927 22.1504 11.5683 21.5487C12.1439 20.9472 12.1439 19.9717 11.5683 19.3699L3.55878 11L11.568 2.63008C12.1436 2.02833 12.1436 1.05299 11.568 0.451532C10.9925 -0.150509 10.0589 -0.150509 9.48309 0.451532L0.431283 9.91091C0.143729 10.2117 -5.45384e-07 10.6059 -5.45384e-07 11Z" fill="#6946C6"/>
                    </svg>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography>{intl.formatMessage({ id: "Back" })}</Typography>
                  </Grid>
                </Grid>
                {/* <div>{drillDownTableChart.values.TableName}</div> */}
                <Grid className="syntheticTablecontainer">
                <TableContainer className="searchtable">
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    //className="joblist-table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>{intl.formatMessage({ id: "Table" })}</TableCell>
                        {drillDownTableChart.label.map(
                          (tableLabel: string, index: number) => {
                            return tableLabel === variables.dataProfilingNullValueText ? (
                              <>
                                <TableCell key={index}>{tableLabel}</TableCell>
                                <TableCell key={index}>{intl.formatMessage({ id: "NullValues" })}</TableCell>
                              </>
                            ) : tableLabel === variables.dataProfilingUniqueCountText ? (
                              <>
                                <TableCell key={index}>{tableLabel}</TableCell>
                                <TableCell key={index}>{intl.formatMessage({ id: "UniqueCount" })}</TableCell>
                              </>
                            ) : (
                              <TableCell key={index}>{tableLabel}</TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {drillDownTableChart.values.ColumnInfo.map(
                        (row: any, index: number) => (
                          <TableRow
                            data-testid="jobsrow"
                            key={index}
                            style={
                              index % 2
                                ? { background: "rgba(0, 0, 0, 0.02)" }
                                : { background: "rgba(0, 0, 0, 0.08)" }
                            }
                            onClick={() => drillDownColumnHandler(row)}
                            className="cursor-pointer"
                          >
                            <TableCell>{drillDownTableChart.tableName}</TableCell>
                            {drillDownTableChart.label.map((tableLabel: string) => {
                              return tableLabel === variables.dataProfilingNullValueText ? (
                                <>
                                  <TableCell>
                                    {row[tableLabel]}
                                  </TableCell>
                                  <TableCell>
                                      {(
                                        ((drillDownTableChart.countOfRecords -
                                          row[tableLabel]) /
                                          drillDownTableChart.countOfRecords) *
                                        100
                                      ).toFixed(2)}
                                      %
                                  </TableCell>
                                </>
                              ) : tableLabel === variables.dataProfilingUniqueCountText ? (
                                <>
                                  <TableCell>
                                    {row[tableLabel]}
                                  </TableCell>
                                  <TableCell>
                                      {(
                                        ((drillDownTableChart.countOfRecords -
                                          row[tableLabel]) /
                                          drillDownTableChart.countOfRecords) *
                                        100
                                      ).toFixed(2)}
                                      %
                                  </TableCell>
                                </>
                              ) : (
                                <TableCell>
                                  {row[tableLabel]}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                </Grid>
              </div>
              <Grid container spacing={2}>
                {drillDownColumnData.nullValues !== "" && 
                  <Grid item xs={12} md={6}>
                    <Grid container className="DPDatabaseTopAndBottomTable"> 
                      <Grid item xs={12} md={6} className="DPDatabaseDoughnutChart">
                        <TableDoughnutChart doughnutValues={drillDownColumnData.nullValues} doughnutCategory={variables.dataProfilingNullChartText} />
                      </Grid>
                      <Grid item xs={12} md={6} className="DPDatabaseDoughnutChart">
                        <TableDoughnutChart doughnutValues={drillDownColumnData.uniqueValues} doughnutCategory={variables.dataProfilingUniqueChartText}/>
                      </Grid>
                    </Grid>
                  </Grid>
                }
                {
                  drillDownColumnData.topFiveValues.length > 0 && 
                  <Grid item xs={12} md={6}>
                    <Grid container className="DPDatabaseTopAndBottomTable">
                      <Grid item xs={12} md={6} >
                        <Grid className="syntheticTablecontainer">
                          <Typography>{intl.formatMessage({ id: "Top" })} <strong>{intl.formatMessage({ id: "Five" })}</strong></Typography>
                          <TableContainer className="searchtable">
                            <Table
                              stickyHeader
                              aria-label="sticky table"
                              size="small"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>{intl.formatMessage({ id: "Values" })}</TableCell>
                                  <TableCell>{intl.formatMessage({ id: "ValuesCount" })}</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {drillDownColumnData.topFiveValues.map(
                                  (row: any, index: number) => (
                                    <TableRow data-testid="jobsrow" key={index} style={
                                      index % 2
                                        ? { background: "rgba(0, 0, 0, 0.02)" }
                                        : { background: "rgba(0, 0, 0, 0.08)" }
                                    }>
                                      <TableCell>
                                        {row.ColValue}
                                      </TableCell>
                                      <TableCell>
                                        {row.ColCount}
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid className="syntheticTablecontainer">
                          <Typography>{intl.formatMessage({ id: "Bottom" })} <strong>{intl.formatMessage({ id: "Five" })}</strong></Typography>
                          <TableContainer className="searchtable">
                            <Table
                              stickyHeader
                              aria-label="sticky table"
                              size="small"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>{intl.formatMessage({ id: "Values" })}</TableCell>
                                  <TableCell>{intl.formatMessage({ id: "ValuesCount" })}</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {drillDownColumnData.bottomFiveValues.map(
                                  (row: any, index: number) => (
                                    <TableRow data-testid="jobsrow" key={index} style={
                                      index % 2
                                        ? { background: "rgba(0, 0, 0, 0.02)" }
                                        : { background: "rgba(0, 0, 0, 0.08)" }
                                    }>
                                      <TableCell>
                                        {row.ColValue}
                                      </TableCell>
                                      <TableCell>
                                        {row.ColCount}
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                }
              </Grid>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default ProfilingResult;
