/**Imports of React libraries */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
/**Imports of Reusable Components */
import { RootState } from "../../store/reducers/rootReducer";
/**Imports of Material UI Elements */
import {
  Grid,
  Typography,
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Snackbar,
} from "@material-ui/core";
import { Autocomplete, Alert, Pagination } from "@material-ui/lab";
/**Imports of API Actions */
import {
  getTestdbTableDetails,
  connectTestDB,
  disConnectTestDB,
  dbConnectionList,
  clearConnectionDetails,
  setConnectionDetails,
  // deleteTestData,
  // deleteAllTestData,
} from "../../../src/store/actions/testDBOperation/testDBAction";
/**Imports of SVG Icons */
import { ReactComponent as InActiveDeleteIconSvg } from "../../assets/images/avatars/delete-disable.svg";
import { ReactComponent as WhiteDeleteIconSvg } from "../../assets/images/avatars/delete-white.svg";
// import { ReactComponent as PopupDeleteIconSvg } from "../../assets/images/avatars/deletepopup.svg";
// import { ReactComponent as WarningIconSvg } from "../../assets/images/avatars/warning.svg";
import { ReactComponent as NoDataSvg } from "../../assets/images/avatars/nodata.svg";
/**Import of Config files */
import { testDBLog, logLevel } from "../../config/logConfig";
import { config } from "../../config/clientConfig";
/**Import of Logger files */
import { logFunctionStart, logFunctionEnd, writeLog } from "../../logManager";


/**Global Variables Declaration*/
let selectedRows = [] as Array<number>;
let newSelected = [] as Array<number>;
let Selected = [] as Array<number>;
let deleterowIDs = [] as Array<number>;
let dbConnectionDetails = {};

function TestDB() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [selectedtable, setselectedtable] = React.useState("" as string);
  const [connection, setconnection] = React.useState({} as any);
  const [page, setpage] = React.useState(0);
  const [rowsPerPage] = React.useState(25);
  const [connectbutton, setconnectbutton] = React.useState(true);
  const [dbConnectionName, setDBConnectionName] = React.useState("");
  // const [open, setopen] = React.useState(false);
  // const [openwarning, setopenwarning] = React.useState(false);
  // const [success, setsuccess] = React.useState(false);
  const [selected, setselected] = React.useState(false);
  const [selectedrows, setselectedrows] = React.useState([] as Array<number>);
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [isSelectAll, setisSelectAll] = React.useState(false);
  const [isSelectAllItems, setisSelectAllItems] = React.useState(false);
  const [dbDetails, setDbDetails] = React.useState([] as Array<string>);

  /** state for handle Success message */
  const [successMessage, setSuccessMessage] = React.useState("");
  const testdata = useSelector((state: RootState) => state.testDBdata.testdata);

  const columnArr = useSelector((state: RootState) => {
    // To Remove the table when the page is changed.
    if (Array.isArray(connection)) state.testDBdata.columnArr = [];

    return state.testDBdata.columnArr;
  });

  type Order = "asc" | "desc";
  const currentLogLevel = useSelector(
    (state: RootState) => state.landingPage.currentLogLevel
  );
  const connectionList = useSelector(
    (state: RootState) => state.testDBdata.connectionList
  );
  const dbConnection = useSelector(
    (state: RootState) => state.testDBdata.dbConnection
  );

  // Fetching Connection Details
  useEffect(() => {
    dispatch(dbConnectionList());
    return () => {
      Object.keys(dbConnectionDetails).length && dispatch(disConnectTestDB(dbConnectionDetails));
    }
  }, []);

  /**Function for API Call of Selected Table Details*/
  const handleTestDBDataTable = async (event: string) => {
    const startTime = logFunctionStart(
      currentLogLevel,
      "handleTestDBDataTable"
    );
    try {
      setselectedtable(event);
      selectedRows = [];
      setselectedrows([]);
      setisSelectAll(false);
      setselected(false);
      const postDataDetails = {
        dbconnection: {
          connectionName: connection.connectionname,
          dbType: connection.dbtype,
          dbHost: connection.hostname,
          dbPort: connection.port,
          dbUser: connection.username,
          password: connection.password,
          dbName: connection.database,
          tableName: event
        },
      }
      const testDB: any = await dispatch(
        getTestdbTableDetails(postDataDetails)
      );
      //Alert the user when the records are empty
      (testDB.data.length <= 0) && alert("No records in the table");
      if (testDB !== undefined) {
        writeLog(
          logLevel.Information,
          currentLogLevel,
          testDB.userMessage,
          testDBLog.testDBData
        );
      } else {
        writeLog(
          logLevel.Error,
          currentLogLevel,
          testDB.userMessage,
          testDBLog.testDBData
        );
      }
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleTestDBDataTable");
    }
  };

  /**Function to Clear all selected test DB records and refresh the test DB table*/
  const handleClearTestDBSelectedbutton = () => {
    const startTime = logFunctionStart(
      currentLogLevel,
      "handleClearTestDBSelectedbutton"
    );
    try {
      selectedRows = [];
      setselectedrows([]);
      setisSelectAll(false);
      setselected(false);
      setisSelectAllItems(false);
      writeLog(
        logLevel.Information,
        currentLogLevel,
        "Clear All Selected Records",
        testDBLog.clearAll
      );
    } finally {
      logFunctionEnd(
        currentLogLevel,
        startTime,
        "handleClearTestDBSelectedbutton"
      );
    }
  };

  // /**Function to Open the Delete Confirmation popup*/
  // const handleTestDbDeletePopupOpenbutton = () => {
  //   const startTime = logFunctionStart(
  //     currentLogLevel,
  //     "handleTestDbDeletePopupOpenbutton"
  //   );
  //   try {
  //     setopen(true);
  //     writeLog(
  //       logLevel.Information,
  //       currentLogLevel,
  //       "TestDB Delete Confirmation popup",
  //       testDBLog.deleteConfirmation
  //     );
  //   } finally {
  //     logFunctionEnd(
  //       currentLogLevel,
  //       startTime,
  //       "handleTestDbDeletePopupOpenbutton"
  //     );
  //   }
  // };

  /**Function for the event of Select All Items Checkbox*/
  const handleSelectAllTestDBRecords = () => {
    const startTime = logFunctionStart(
      currentLogLevel,
      "handleSelectAllTestDBRecords"
    );
    try {
      selectedRows = [];
      setselectedrows([]);
      setisSelectAllItems(!isSelectAllItems);
      setisSelectAll(false);
      writeLog(
        logLevel.Information,
        currentLogLevel,
        "Select All Items Checkbox",
        testDBLog.selectAllItems
      );
    } finally {
      logFunctionEnd(
        currentLogLevel,
        startTime,
        "handleSelectAllTestDBRecords"
      );
    }
  };

  // /**Function to close confirmation popup message*/
  // const handleClosePopupButton = () => {
  //   const startTime = logFunctionStart(
  //     currentLogLevel,
  //     "handleClosePopupButton"
  //   );
  //   try {
  //     setopen(false);
  //     writeLog(
  //       logLevel.Information,
  //       currentLogLevel,
  //       "Close confirmation popup",
  //       testDBLog.closeConfirmation
  //     );
  //   } finally {
  //     logFunctionEnd(currentLogLevel, startTime, "handleClosePopupButton");
  //   }
  // };

  // /**Function to Call Delete API calls for table records*/
  // const handleDeleteTestRecords = async () => {
  //   let deletedstatus: any;
  //   const startTime = logFunctionStart(
  //     currentLogLevel,
  //     "handleDeleteTestRecords"
  //   );
  //   try {
  //     if (isSelectAllItems && selectedRows.length === 0) {
  //       deletedstatus = await dispatch(deleteAllTestData(selectedtable));
  //     } else {
  //       deletedstatus = await dispatch(
  //         deleteTestData(selectedtable, selectedRows)
  //       );
  //     }
  //     if (deletedstatus !== undefined && deletedstatus !== null) {
  //       await dispatch(getTestdbTableDetails(selectedtable));
  //       setsuccess(true);
  //       setopen(false);
  //       writeLog(
  //         logLevel.Information,
  //         currentLogLevel,
  //         deletedstatus.userMessage,
  //         testDBLog.deleteRecords
  //       );
  //     } else {
  //       setopenwarning(true);
  //       setopen(false);
  //       writeLog(
  //         logLevel.Error,
  //         currentLogLevel,
  //         deletedstatus.userMessage,
  //         testDBLog.deleteRecords
  //       );
  //     }
  //     selectedRows = [];
  //     setselectedrows([]);
  //     setisSelectAll(false);
  //     setselected(false);
  //     setisSelectAllItems(false);
  //     setopen(false);
  //   } finally {
  //     logFunctionEnd(currentLogLevel, startTime, "handleDeleteTestRecords");
  //   }
  // };

  // /**Function to close waring popup message*/
  // const handleDeleteConfirmationClosePopupButton = () => {
  //   const startTime = logFunctionStart(
  //     currentLogLevel,
  //     "handleDeleteConfirmationClosePopupButton"
  //   );
  //   try {
  //     selectedRows = [];
  //     setselectedrows([]);
  //     setisSelectAll(false);
  //     setselected(false);
  //     setopenwarning(false);
  //     writeLog(
  //       logLevel.Information,
  //       currentLogLevel,
  //       "Close Warning popup",
  //       testDBLog.closeWarning
  //     );
  //   } finally {
  //     logFunctionEnd(
  //       currentLogLevel,
  //       startTime,
  //       "handleDeleteConfirmationClosePopupButton"
  //     );
  //   }
  // };

  // /**Function to close Success Pop up Message Close*/
  // const handleSuccessPopupClose = () => {
  //   const startTime = logFunctionStart(
  //     currentLogLevel,
  //     "handleSuccessPopupClose"
  //   );
  //   try {
  //     setsuccess(false);
  //     writeLog(
  //       logLevel.Information,
  //       currentLogLevel,
  //       "Close Success popup",
  //       testDBLog.closeSuccess
  //     );
  //   } finally {
  //     logFunctionEnd(currentLogLevel, startTime, "handleSuccessPopupClose");
  //   }
  // };

  /**Function to Select 25 records in table**/
  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const startTime = logFunctionStart(currentLogLevel, "onSelectAllClick");
    try {
      deleterowIDs = [];
      selectedRows = [];
      const startValue = page * rowsPerPage;
      const lastValue = startValue + rowsPerPage;
      if (isSelectAll === false) {
        const deleteRows = testdata.slice(startValue, lastValue);
        deleteRows.forEach(function (obj: any) {
          deleterowIDs.push(obj.ID);
        });
        selectedRows = selectedRows.concat(deleterowIDs);
        setselectedrows(selectedRows);
        setisSelectAll(!isSelectAll);
        setselected(true);
        writeLog(
          logLevel.Information,
          currentLogLevel,
          "Select 25 Records",
          testDBLog.selectPerPageRecords
        );
      } else {
        setisSelectAll(!isSelectAll);
        setselected(false);
        setselectedrows(selectedRows);
        writeLog(
          logLevel.Information,
          currentLogLevel,
          "UnSelect 25 Records",
          testDBLog.selectPerPageRecords
        );
      }
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "onSelectAllClick");
    }
  };

  /**Function for test db sorting by asc/dsc*/
  const createSortHandler = (event: string) => () => {
    const isAsc = orderBy === event && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(event);
  };

  /**Default Material UI Function used for test db sorting by asc/dsc*/
  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  /**Default Material UI Function used for test db sorting by asc/dsc*/
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  /**Default Material UI Function used for test db sorting by asc/dsc*/
  function getComparator<Key extends string | number | symbol>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  /**Function to select Checkbox for Delete Records*/
  const onSelectTestDBRecord = async (row: any) => {
    const startTime = logFunctionStart(currentLogLevel, "onSelectTestDBRecord");
    try {
      newSelected = [];
      Selected = [];
      setisSelectAll(false);
      const selectedIndex = selectedRows.indexOf(row.ID);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(Selected, row.ID);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRows.slice(1));
        selectedRows = [];
      } else if (selectedIndex === selectedRows.length - 1) {
        newSelected = newSelected.concat(selectedRows.slice(0, -1));
        selectedRows = [];
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedRows.slice(0, selectedIndex),
          selectedRows.slice(selectedIndex + 1)
        );
        selectedRows = [];
      }
      selectedRows = selectedRows.concat(newSelected);
      setselected(true);
      setselectedrows(selectedRows);
      writeLog(
        logLevel.Information,
        currentLogLevel,
        "Checkbox Selected for Delete Records",
        testDBLog.selectRecords
      );
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "onSelectTestDBRecord");
    }
  };

  /**Function to Delete Single Record from Table*/
  /**Commenting Delete Functionality and Changing InActiveDeleteIcon for soft launch */
  // const handleDBOperationSingleRecordDelete = async (row: any, index: any) => {
  //   selectedRows = [];
  //   selectedRows.push(row.ID);
  //   setopen(true);
  // };

  /**Function to Change the page in Pagination*/
  const handleTestDBPageChange = (event: any, newPage: number) => {
    selectedRows = [];
    setpage(newPage - 1);
    setselectedrows([]);
    setisSelectAll(false);
    setselected(false);
    setisSelectAllItems(false);
  };

  /**Function to control checkbox(Table Body) enable/disable functionality*/
  const isSelected = (ID: number) => {
    if (selectedRows !== undefined) {
      if (selectedRows.length === 0) {
        return false;
      } else {
        if (selectedRows.includes(ID)) {
          return selected;
        }
      }
    }
  };

  /**Function for choose connection list*/
  const handleConnectionList = async (event: {database: string}) => {
    setDBConnectionName(event.database);
    setconnection(event);
    setconnectbutton(false);
    dispatch(clearConnectionDetails());
  };

  /**Function to Connect with Database*/
  const handleConnectDB = async () => {
    const startTime = logFunctionStart(currentLogLevel, "handleConnectDB");
    const postDataDetails = {
      dbconnection: {
        connectionName: connection.connectionname,
        dbType: connection.dbtype,
        dbHost: connection.hostname,
        dbPort: connection.port,
        dbUser: connection.username,
        password: connection.password,
        dbName: connection.database,
      },
    };
    try {
      const connectDB: any = await dispatch(connectTestDB(postDataDetails));
      if(connectDB.data.length <= 0){
        alert(connection.username + " user have no permission to access tables");
        setDbDetails([]);
      }else{
        setDbDetails(connectDB.data);
      }
      setSuccessMessage(connectDB.messageCode);
      if (connectDB.messageCode === config.successCode) {
        setconnectbutton(false);
        dispatch(setConnectionDetails(connectbutton));
        dbConnectionDetails = {
          dbconnection: {
            connectionName: connection.connectionname,
            dbType: connection.dbtype,
            dbHost: connection.hostname,
            dbPort: connection.port,
            dbUser: connection.username,
            password: connection.password,
            dbName: connection.database,
            dbValue: config.disconnectDBText
          }
        };
        writeLog(
          logLevel.Information,
          currentLogLevel,
          connectDB.userMessage,
          testDBLog.connectDB
        );
      } else {
        writeLog(
          logLevel.Error,
          currentLogLevel,
          connectDB.userMessage,
          testDBLog.connectDB
        );
      }
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleConnectDB");
    }
  };

  /**Function to DisConnect with Database*/
  const handleDisConnectDB = async () => {
    const startTime = logFunctionStart(currentLogLevel, "handleDisConnectDB");
    try {
      
      const postDataDetails = {
        dbconnection: {
          connectionName: connection.connectionname,
          dbType: connection.dbtype,
          dbHost: connection.hostname,
          dbPort: connection.port,
          dbUser: connection.username,
          password: connection.password,
          dbName: connection.database,
          dbValue: config.disconnectDBText
        },
        
      };
      const disconnectDB: any = await dispatch(disConnectTestDB(postDataDetails));
      if (disconnectDB.messageCode === config.successCode) {
        setSuccessMessage("");
        setconnection({});
        dbConnectionDetails = {};
        setselectedtable("");
        setconnectbutton(true);
        writeLog(
          logLevel.Information,
          currentLogLevel,
          disconnectDB.userMessage,
          testDBLog.disconnectDB
        );
      } else {
        writeLog(
          logLevel.Error,
          currentLogLevel,
          disconnectDB.userMessage,
          testDBLog.disconnectDB
        );
      }
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleDisConnectDB");
    }
  };

  return (
    <div className="testDBHead">
      <Grid container className="testDBHeadDBSelection">
        <Grid item xs={4} md={3} className="tablelist">
          <FormControl>
            <Autocomplete
              disabled={successMessage === config.successCode && connectbutton === false ? true : false}
              id="combo-box-demo"
              options={connectionList}
              getOptionLabel={(connectionList) => connectionList.connectionname}
              value={connection}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={intl.formatMessage({ id: "SelectConnection" })}
                  inputProps={{
                    ...params.inputProps,
                    "data-testid": "object",
                  }}
                />
              )}
              onChange={(event: any, newValue: any) => {
                handleConnectionList(newValue);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} md={3} className="newDBConnectionBtns">
          {/* Button red class = failedBtn */}
          {/* Button green class = successBtn */}
          {/* Button disabled class = disabledBtn and need to add disabled attribute */}
          {successMessage === config.successCode && connectbutton === false ? (
            <button className="failedBtn" onClick={handleDisConnectDB}>
              <div>
                <svg
                  width="26"
                  height="29"
                  viewBox="0 0 26 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.7876 13.3037L6.05394 14.3883L6.86737 14.9385L4.59321 18.3005L7.20002 20.0638L4.36309 24.2577L6.68689 25.8296L9.52382 21.6358L12.1306 23.3991L14.4048 20.0372L15.2182 20.5875L15.9519 19.5029L6.7876 13.3037Z"
                    fill="white"
                  />
                  <path
                    d="M19.0845 13.2047L21.3587 9.84277L18.7518 8.0794L21.5888 3.88555L19.265 2.31362L16.428 6.50747L13.8212 4.7441L11.5471 8.10602L10.7336 7.55578L9.99998 8.64036L19.1643 14.8395L19.8979 13.7549L19.0845 13.2047Z"
                    fill="white"
                  />
                </svg>
                <Typography>
                  {intl.formatMessage({ id: "Disconnect" })}
                </Typography>
              </div>
            </button>
          ) : (
            <button
              onClick={handleConnectDB}
              type="button"
              className={connectbutton ? "disabledBtn" : ""}
              disabled={connectbutton ? true : false}
            >
              <div>
                <svg
                  width="26"
                  height="29"
                  viewBox="0 0 26 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.7876 13.3037L6.05394 14.3883L6.86737 14.9385L4.59321 18.3005L7.20002 20.0638L4.36309 24.2577L6.68689 25.8296L9.52382 21.6358L12.1306 23.3991L14.4048 20.0372L15.2182 20.5875L15.9519 19.5029L6.7876 13.3037Z"
                    fill="white"
                  />
                  <path
                    d="M19.0845 13.2047L21.3587 9.84277L18.7518 8.0794L21.5888 3.88555L19.265 2.31362L16.428 6.50747L13.8212 4.7441L11.5471 8.10602L10.7336 7.55578L9.99998 8.64036L19.1643 14.8395L19.8979 13.7549L19.0845 13.2047Z"
                    fill="white"
                  />
                </svg>
                <Typography>{intl.formatMessage({ id: "Connect" })}</Typography>
              </div>
            </button>
          )}
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid xs={12} md={3} className="tablelist">
            <Autocomplete
              id="combo-box-demo"
              className={successMessage === config.successCode && connectbutton === false ? "" : "disabledBtn"}
              disabled={successMessage === config.successCode && connectbutton === false ? false : true}
              options={dbDetails}
              value={selectedtable}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={intl.formatMessage({ id: "SelectTable" })}
                  inputProps={{
                    ...params.inputProps,
                    "data-testid": "tabledropdown",
                  }}
                />
              )}
              onChange={(event: any, newValue: any) => {
                handleTestDBDataTable(newValue);
              }}
            />
        </Grid>
        <Grid xs={12} md={9} className="cont3-item">
          <Grid container alignItems="center" className="container3-right">
            {(selectedrows.length > 0 &&
              columnArr !== undefined &&
              columnArr.length > 0) ||
            (isSelectAllItems &&
              columnArr !== undefined &&
              columnArr.length > 0) ? (
              <>
                {
                  <Grid>
                    <Typography className="itemtext">
                      {isSelectAllItems ? (
                        <>
                          {" "}
                          <span>{testdata.length}</span>
                          {testdata.length > 1 ? (
                            <p>{intl.formatMessage({ id: "itemsSelected" })}</p>
                          ) : (
                            <p>{intl.formatMessage({ id: "itemSelected" })}</p>
                          )}
                        </>
                      ) : (
                        <>
                          {" "}
                          <span>{selectedrows.length}</span>
                          {selectedrows.length > 1 ? (
                            <p>
                              {intl.formatMessage({ id: "itemsSelected" })}{" "}
                            </p>
                          ) : (
                            <p>{intl.formatMessage({ id: "itemSelected" })} </p>
                          )}
                        </>
                      )}
                    </Typography>
                  </Grid>
                }
                <Grid>
                  <Button
                    className="clearbutton clearbutton-text"
                    variant="contained"
                    color="primary"
                    onClick={handleClearTestDBSelectedbutton}
                    disabled={
                      isSelectAllItems || selectedrows.length > 0 ? false : true
                    }
                  >
                    {" "}
                    {intl.formatMessage({ id: "ClearSelection" })}
                  </Button>
                </Grid>
                <Grid>
                  <Button
                    className="deletebutton deletebutton-text"
                    variant="contained"
                    color="primary"
                    //onClick={handleTestDbDeletePopupOpenbutton}
                    endIcon={<WhiteDeleteIconSvg />}
                    disabled={true}
                    // disabled={
                    //   isSelectAllItems || selectedrows.length > 0 ? false : true
                    // }
                  >
                    {" "}
                    {intl.formatMessage({ id: "Delete" })}
                  </Button>
                </Grid>
              </>
            ) : null}
            {columnArr !== undefined && columnArr.length > 0 ? (
              <Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSelectAllItems}
                      onChange={handleSelectAllTestDBRecords}
                      inputProps={{
                        "aria-label": "selectall-checkbox",
                      }}
                    />
                  }
                  label={intl.formatMessage({ id: "SelectAllitems" })}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      {/* <div>
        <Dialog
          open={open}
        onClose={handleClosePopupButton}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className="popup">
            <DialogContentText id="alert-dialog-description">
              <PopupDeleteIconSvg className="delete-disable" />
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              {intl.formatMessage({ id: "DeleteConfirmation" })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
           onClick={handleClosePopupButton}
             variant="contained">
              {intl.formatMessage({ id: "No" })}
            </Button>
            <Button
            onClick={handleDeleteTestRecords}
              color="primary"
              variant="contained"
              className="reindexpop"
            >
              {intl.formatMessage({ id: "Yes" })}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={openwarning}
        onClose={handleDeleteConfirmationClosePopupButton}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className="popup">
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <WarningIconSvg />
              </DialogContentText>
            </DialogContent>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {intl.formatMessage({ id: "DeleteWarning" })}
              </DialogContentText>
            </DialogContent>
          </DialogContent>
          <DialogActions>
            <Button
            onClick={handleDeleteConfirmationClosePopupButton}
              variant="contained"
            >
              {intl.formatMessage({ id: "MoreInfo" })}
            </Button>
            <Button
            onClick={handleDeleteConfirmationClosePopupButton}
              color="primary"
              variant="contained"
              className="reindexpop"
            >
              {intl.formatMessage({ id: "OK" })}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Snackbar
        open={success}
        autoHideDuration={6000}
      onClose={handleSuccessPopupClose}
      >
        <Alert onClose={handleSuccessPopupClose} severity="success">
          {intl.formatMessage({ id: "SuccessfullyDeleted" })}
        </Alert>
      </Snackbar>*/}
      <Grid>
        {testdata !== undefined &&
        testdata !== null &&
        columnArr !== undefined &&
        columnArr.length > 0 ? (
          <>
            {
              <TableContainer className="searchtable">
                <Table stickyHeader data-testid="testDBTable">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          className="checkbox-head"
                          checked={isSelectAll || isSelectAllItems}
                          disabled={isSelectAllItems}
                          onChange={onSelectAllClick}
                          inputProps={{
                            "aria-label": "header-checkbox",
                          }}
                        />
                      </TableCell>

                      {testdata !== undefined && testdata !== null ? (
                        <>
                          {columnArr.map((data: any) => (
                            <TableCell
                              align="right"
                              key={data}
                              sortDirection={false}
                            >
                              <TableSortLabel
                                active={orderBy === data}
                                direction={orderBy === data ? order : "asc"}
                                onClick={createSortHandler(data)}
                                data-testid="sorting"
                              ></TableSortLabel>
                              {data}
                            </TableCell>
                          ))}
                        </>
                      ) : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stableSort(testdata, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any, index: number) => (
                        <TableRow
                          key={index}
                          style={
                            index % 2
                              ? { background: "rgba(0, 0, 0, 0.02)" }
                              : { background: "rgba(0, 0, 0, 0.08)" }
                          }
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isSelected(row.ID) || isSelectAllItems}
                              disabled={isSelectAll || isSelectAllItems}
                              inputProps={{
                                "aria-label": "row-checkbox",
                              }}
                              onChange={() => onSelectTestDBRecord(row)}
                            />
                          </TableCell>
                          {columnArr.map((cell: string, index1: number) => (
                            <>
                              {selectedrows.length > 0 || isSelectAllItems ? (
                                <TableCell
                                  align="right"
                                  className="delete-disable text-disable"
                                >
                                  {row[cell]}
                                  {columnArr.length - 1 === index1 ? (
                                    <InActiveDeleteIconSvg />
                                  ) : null}
                                </TableCell>
                              ) : (
                                <TableCell align="right">
                                  {row[cell]}
                                  {columnArr.length - 1 === index1 ? ( // Commenting Delete Functionality and Changing InActiveDeleteIcon for soft launch
                                    // <ActiveDeleteIconSvg
                                    //   onClick={(event: any) =>
                                    //     handleDBOperationSingleRecordDelete(
                                    //       row,
                                    //       index
                                    //     )
                                    //   }
                                    // />
                                    <InActiveDeleteIconSvg />
                                  ) : null}
                                </TableCell>
                              )}
                            </>
                          ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
            <div className="pagination">
              <Typography className="paginationtext">
                <span>
                  {page * rowsPerPage + 1} {"-"}{" "}
                  {page * rowsPerPage + rowsPerPage < testdata.length ? (
                    <>{page * rowsPerPage + rowsPerPage}</>
                  ) : (
                    <>{testdata.length}</>
                  )}
                </span>
                <p>{intl.formatMessage({ id: "of" })}</p>
                <p>{testdata.length}</p>
              </Typography>
              <Pagination
                count={Math.ceil(testdata.length / rowsPerPage)}
                siblingCount={2}
                variant="outlined"
                shape="rounded"
                data-testid="pagination"
                onChange={handleTestDBPageChange}
              />
            </div>
          </>
        ) : (
          <div className="nodata-main">
            <div className="nodata">
              <NoDataSvg />
            </div>
            <span>{intl.formatMessage({ id: "NoSelectedConnectionMsg" })}</span>
          </div>
        )}
      </Grid>
    </div>
  );
}

export default TestDB;
