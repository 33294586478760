/**Imports of React libraries */
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { MsalProvider } from "@azure/msal-react";
/**Imports of Reusable Components */
import { RootState } from "../src/store/reducers/rootReducer";
import "./css/App.css";
import "./css/style.css";
// Imported SASS file
import "./sass/style.css";
/**Imports of Main pages */
import { MainAppbar } from "./layout";
import Layout from "./layout/layout";
import TonicAi from "./components/Administration/tonicAi";
import DBConnections from "./components/Administration/dbConnections";
import DataProfilingIndex from "./components/DBOperation/dataProfilingIndex";
import SearchIndex from "./components/DataOperation/searchIndex";
import Help from "./components/help";
import landingpage from "../src/components/landingpage/landingpage";
import ReleaseNotes from "../src/components/ReleaseNotes/releaseNotes";
import dbOperation from "../src/components/DBOperation/dbOperation";
import DataOperation from "../src/components/DataOperation/dataOperation";
import JailerLandingPage from "./components/Administration/jailerLandingPage";
import QueryManager from "./components/DBOperation/queryManager/queryManagerIndex";
import {
  validateUserByToken,
  setTokenExpiry,
} from "./store/actions/authentication/authenticationAction";
import AutoLogout from "../src/components/Popup/AutoLogout";
import TokenExpiryLogout from "../src/components/Popup/TokenExpiryLogout";
/**Import of Timer files */
import IdleTimer from "./IdleTimer";
/**Import of Logger files */
import TelemetryProvider from "./telemetry-provider";
import { getAppInsights } from "./TelemetryService";
import { authenticationLog, logLevel } from "./config/logConfig";
import { logFunctionStart, logFunctionEnd, writeLog } from "./logManager";
import { aiFunction } from "./logManager";
/**Import of Config files */
import { config } from "./config/clientConfig";
/**Import of Interfaces files */
import { IMsalInstance, ITokenObject } from "./interfaces";
import { dbConnectionList } from "./store/actions/testDBOperation/testDBAction";
import { getQueryLists } from "./store/actions/queryManager/queryManagerAction";
import DataComparisonIndex from "./components/DBOperation/dataComparison/dataComparisonIndex";

/**Global Variables Declaration*/
let appInsights: any;
let timer: any;

function App() {
  const dispatch = useDispatch();
  const [isTimeout, setIsTimeout] = useState(false);
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  const reactintlConfig = useSelector((state: RootState) => state.landingPage);
  //Redux State to check Loading of authlogin
  // const authLoading = useSelector((state: RootState) => state.landingPage);
  const currentLogLevel = useSelector(
    (state: RootState) => state.landingPage.currentLogLevel
  );

  // After user loggedin, if someone tries to refresh the page, set authloading to false, so that username won't get cleared
  // if (performance.navigation.type === 1) authLoading.isLoading = false;

  // Authentication Check
  // getTokenAndValidateUser(msalInstance, currentLogLevel);

  // Receive the tokenExpiry value for logged in user from inmemory to perform the Login Token Expiration Check
  // const tokenExpiry = useSelector(
  //   (state: RootState) => state.userAuthentication.tokenExpiry
  // );
  const connectionList = useSelector(
    (state: RootState) => state.testDBdata.connectionList
  );

  // Once we receive the token expiry value from store, set the tokenExpiry in localstore to fix page refresh issue
  // useEffect(() => {
  //   tokenExpiry ? localStorage.setItem("tokenexpiry", tokenExpiry) : 0;
  // }, [tokenExpiry]);

  // Token Expiry Check - Check if it is in memory or local storage
  // const tokenExpiryValue = tokenExpiry
  //   ? tokenExpiry
  //   : localStorage.getItem("tokenexpiry");
  // setInterval(() => {
  //   const isTokenExpiredStatus = validateTokenExpiry(tokenExpiryValue);
  //   isTokenExpiredStatus === true
  //     ? setIsTokenExpired(true)
  //     : setIsTokenExpired(false);
  // }, config.tokenExpiryCheck);

  // // Idle Timer Expiration Check
  // useEffect(() => {
  //   timer = new IdleTimer({
  //     // Expires after 5 sec
  //     timeout: config.timeout,
  //     onTimeout: () => setIsTimeout(true),
  //     onExpired: () => setIsTimeout(false),
  //   });
  //   return () => timer.cleanUp();
  // }, [timer]);

  // Fetching Connection and Query Details
  useEffect(() => {
    dispatch(dbConnectionList());
    dispatch(getQueryLists());
  }, []);

  return (
    <TelemetryProvider
      instrumentationKey={process.env.REACT_APP_INSTRUMENT_KEY}
      after={async () => {
        appInsights = getAppInsights();
        if (appInsights !== undefined && appInsights !== null) {
          aiFunction(appInsights);
        }
      }}
    >
      <main>
        <IntlProvider
          locale={reactintlConfig.language}
          messages={reactintlConfig.messages}
        >
          <MainAppbar />
          <Layout>
            <Switch>
              <Route path={["/", "/home"]} component={landingpage} exact />
              <Route path="/version" component={ReleaseNotes} />
              <Route path="/help" component={Help} />
              {/* Admin pages */}
              <Route path="/db-connections" component={DBConnections} />
              {/* DB operation pages */}
              <Route path="/data-profiling" component={DataProfilingIndex} />
              <Route path="/db" component={dbOperation} />
              <Route path="/query-manager" component={QueryManager} />
              <Route path="/data-comparison" component={DataComparisonIndex} />
              {/* Data operation pages */}
              <Route path="/search" component={SearchIndex} />
              <Route path="/data" component={DataOperation} />
              {/** External Tools  */}
              <Route path="/jailer" component={JailerLandingPage} />
              <Route path="/tools-integration" component={TonicAi} />
            </Switch>
          </Layout>
        </IntlProvider>
      </main>
    </TelemetryProvider>
  );
}

// Capture token from msalinstance from the browser and validate user by passing token to external AuthApp
// const getTokenAndValidateUser = (
//   msalInstance: IMsalInstance,
//   currentLogLevel: number
// ) => {
//   const dispatch = useDispatch();
//   const startTime = logFunctionStart(
//     currentLogLevel,
//     "getTokenAndValidateUser"
//   );
//   try {
//     const tokenResponse = [...msalInstance["redirectResponse"].values()];
//     if (tokenResponse.length > 0) {
//       tokenResponse[0].then((val: []) => {
//         const jsonStr: string = JSON.stringify(val);
//         const jsObj: ITokenObject = JSON.parse(jsonStr);
//         const getTokenExpiry: number = jsObj["idTokenClaims"].exp;
//         jsObj ? dispatch(validateUserByToken(jsObj["idToken"])) : null;
//         jsObj ? dispatch(setTokenExpiry(getTokenExpiry)) : null;
//       });
//       writeLog(
//         logLevel.Information,
//         currentLogLevel,
//         "Redirecting to Azure Auth Page for user authentication",
//         authenticationLog.getTokenAndValidateUser
//       );
//     }
//   } finally {
//     logFunctionEnd(currentLogLevel, startTime, "getTokenAndValidateUser");
//   }
// };

// Validate user token expiry value from msalinstance
// const validateTokenExpiry = (tokenExpiry: number) => {
//   // The conversion to * 1000 is required as token expiry value is default to 10 digits
//   // Inorder to compare with current date time, we need 13 digits
//   const userTokenExpiryTime = tokenExpiry * 1000;
//   const currentLocalTime = new Date().getTime();
//   return currentLocalTime > userTokenExpiryTime ? true : false;
// };

export default App;
