/**Imports of React libraries */
import React from "react";
/**Imports of Reusable Components */
import { useSelector } from "react-redux";
import { RootState } from "../../../store/reducers/rootReducer";
import { useIntl } from "react-intl";
/**Imports of Material UI Elements */
import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
/**Imports of Suorce and Target forms type */
import { InputsType } from "./dataComparisonForm";

/*
 * i) ConnectionType for showing input fields as per the type
 * ii) formInputs and setFormInputs for populating the user enterd data into our object state
 */
interface Props {
  connectionType: string;
  formInputs: InputsType;
  setFormInputs: React.Dispatch<React.SetStateAction<InputsType>>;
}
function ComparisonForm({ connectionType, formInputs, setFormInputs }: Props) {
  const intl = useIntl();
  /** Get connection lists from the store */
  const connectionList = useSelector(
    (state: RootState) => state.testDBdata.connectionList
  );

  /** Get query lists from the store */
  const queryList = useSelector(
    (state: RootState) => state.queryManagerData.queryList
  );

  /**Function for choose connection list*/
  const handleConnectionList = async (event: {
    dbtype: string;
    connectionname: string;
  }) => {
    setFormInputs({
      ...formInputs,
      connection: event.connectionname,
      dbType: event.dbtype,
    });
  };

  /**Function for get key input value */
  const handleKeyInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormInputs({ ...formInputs, key: e.target.value });
  };

  /**Function for get table name input value */
  const handleTableNameInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormInputs({ ...formInputs, tableName: e.target.value });
  };

  /**Function for get file path input value */
  const handleFilePathInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormInputs({ ...formInputs, filePath: e.target.value });
  };

  /**Function for choose query list*/
  const handleQueryList = async (event: any) => {
    const queryList = [] as string[];
    event.map((el: { Query: string }) => {
      queryList.push(el.Query);
    });
    setFormInputs({ ...formInputs, query: queryList });
  };

  return (
    <div className="dataCompasisonFormInputs">
      {connectionType === "Database" && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <label className="label1">
              {intl.formatMessage({ id: "Connection" })}
            </label>
            <Autocomplete
              className="dcSelect"
              options={connectionList ? connectionList : []}
              disableClearable
              getOptionLabel={(connectionList: {
                connectionname: string;
                dbtype: string;
              }) => connectionList.connectionname}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={intl.formatMessage({ id: "SelectType" })}
                  inputProps={{
                    ...params.inputProps,
                    "data-testid": "object",
                  }}
                />
              )}
              onChange={(
                event: any,
                newValue: { dbtype: string; connectionname: string }
              ) => {
                handleConnectionList(newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label className="label1">
              {intl.formatMessage({ id: "DBType" })}
            </label>
            <TextField
              value={formInputs?.dbType}
              disabled
              className="dcInput disabled"
              placeholder={intl.formatMessage({ id: "None" })}
              variant="outlined"
            />
          </Grid>
        </Grid>
      )}
      {connectionType !== "" && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <label className="label1">
              {intl.formatMessage({ id: "Key" })}
            </label>
            <TextField
              value={formInputs?.key}
              className="dcInput"
              placeholder={intl.formatMessage({ id: "PlaceHolderFieldName" })}
              variant="outlined"
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                handleKeyInput(e)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label className="label1">
              {intl.formatMessage({ id: "TableName" })}
            </label>
            <TextField
              value={formInputs?.tableName}
              className="dcInput"
              placeholder={intl.formatMessage({ id: "PlaceHolderFieldName" })}
              variant="outlined"
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                handleTableNameInput(e)
              }
            />
          </Grid>
        </Grid>
      )}
      {connectionType === "Database" ? (
        <div>
          <label className="label1">
            {intl.formatMessage({ id: "Query" })}
          </label>
          <Autocomplete
            multiple
            className="dcSelect"
            options={queryList && queryList}
            disableClearable
            getOptionLabel={(queryList: { QueryName: string; Query: string }) =>
              queryList.QueryName
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={intl.formatMessage({ id: "SelectType" })}
                inputProps={{
                  ...params.inputProps,
                  "data-testid": "object",
                }}
              />
            )}
            onChange={(event: any, newValue: any) => {
              handleQueryList(newValue);
            }}
          />
        </div>
      ) : (
        connectionType === "File" && (
          <div>
            <label className="label1">
              {intl.formatMessage({ id: "FilePath" })}
            </label>
            <TextField
              value={formInputs?.filePath}
              fullWidth
              className="dcInput"
              placeholder={intl.formatMessage({ id: "TypeFilePath" })}
              variant="outlined"
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                handleFilePathInput(e)
              }
            />
          </div>
        )
      )}
    </div>
  );
}

export default ComparisonForm;
