import {
  SET_LANGUAGE,
  SET_LOG,
  SET_LOADING,
  SET_CURRENT_LOG_LEVEL,
} from "../../actions/actiontypes";
import messages_en from "../../../lang/languages/en.json";
import messages_de from "../../../lang/languages/de.json";
import messages_fr from "../../../lang/languages/fr.json";
import { logType, logLevel } from "../../../config/logConfig";

const initialState = {
  language: "en",
  messages: messages_en,
  log: logType[0],
  currentLogLevel: logLevel.Error,
  isLoading: true,
};

export default function landingPageReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_LANGUAGE:
      if (action.payload === "en") {
        return {
          ...state,
          language: action.payload,
          messages: messages_en,
        };
      } else if (action.payload === "fr") {
        return {
          ...state,
          language: action.payload,
          messages: messages_fr,
        };
      } else if (action.payload === "de") {
        return {
          ...state,
          language: action.payload,
          messages: messages_de,
        };
      } else {
        return {
          ...state,
          language: action.payload,
          messages: messages_en,
        };
      }
    case SET_LOG:
      return {
        ...state,
        log: action.payload,
      };
    case SET_CURRENT_LOG_LEVEL:
      return {
        ...state,
        currentLogLevel: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
}
