import { MENU_OPEN, MENU_CLOSE } from "../actiontypes";

/** Sidebar Action for open */
export const sidebarMenuOpenAction = (value: boolean) => {
  return {
    payload: value,
    type: MENU_OPEN,
  };
};

/** Sidebar Action for close */
export const sidebarMenuCloseAction = (value: boolean) => {
  return {
    payload: value,
    type: MENU_CLOSE,
  };
};
