/**Imports of React libraries */
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
/**Imports of Reusable Components */
import { RootState } from "../../store/reducers/rootReducer";
import SyntheticData from "../DataOperation/SyntheticOperation";
/**Imports of Material UI Elements */
import { Box, Tab, Tabs } from "@material-ui/core";
/**Imports of Config files */
import { dataOperationLog, logLevel } from "../../config/logConfig";
/**Import of Logger files */
import { logFunctionStart, logFunctionEnd, writeLog } from "../../logManager";
import UnReserveData from "./UnReserveData";
import SchemaCreation from "./syntheticDataSchema/schemaCreation";

/**Interface of Material UI tab component props */
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

/**Function to manage Tab Changes*/
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function DataOperation() {
  const history = useHistory();
  const intl = useIntl();
  const [tab, settab] = React.useState(0);
  const currentLogLevel = useSelector(
    (state: RootState) => state.landingPage.currentLogLevel
  );

  /**Function to Change Tabs in Data Operation */
  const handleTabChange = (event: any, newValue: any) => {
    const startTime = logFunctionStart(currentLogLevel, "handleTabChange");
    try {
      // This condition for Alert schema creation data clear info to the user
      if (newValue == 0 && tab !== 0) {
        const alertText = "Please save your schema else all your changes will be lost!";
        if (confirm(alertText) == true) {
          settab(newValue);
        }
      } else {
        settab(newValue);
      }
      writeLog(
        logLevel.Information,
        currentLogLevel,
        "Changing Data operation Tabs",
        dataOperationLog.tabChange
      );
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleTabChange");
    }
  };

  return (
    <div>
      <div className="navPageTabs">
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="test data operations"
        >
          <Tab
            label={intl.formatMessage({ id: "PreDefinedSchema" })}
            className="tabtext"
          />
          <Tab
            label={intl.formatMessage({ id: "SchemaCreation" })}
            className="tabtext"
          />
        </Tabs>
      </div>
      <div className="midContentShadow">
        <TabPanel value={tab} index={0}>
          <SyntheticData />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <SchemaCreation />
        </TabPanel>
      </div>
    </div>
  );
}

export default DataOperation;
