/**Imports of React libraries */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
/**Imports of Reusable Components */
import { RootState } from "../../store/reducers/rootReducer";
/**Imports of Material UI Elements */
import { Autocomplete } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import {
  Grid,
  TextField,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
/**Imports of API Actions */
import {
  inmemoryLogType,
  inmemoryCurrentLogLevel,
} from "../../store/actions/landingPage/landingPage";
/**Imports of SVG Icons */
import { ReactComponent as LoggerSvg } from "../../assets/images/avatars/log-icon.svg";
import { ReactComponent as LogTitleSvg } from "../../assets/images/avatars/log-title.svg";
/**Import of Config files */
import { logType, logLevel } from "../../config/logConfig";

function Logger() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isLog, setisLog] = React.useState(false);
  const log = useSelector((state: RootState) => state.landingPage.log);

  /**Function to open log popup*/
  const handleLogSelection = () => {
    setisLog(true);
  };

  /**Function to Close Confirmation log popup*/
  const handleLogClose = () => {
    setisLog(false);
  };

  /**Function to Select Log level*/
  const handleSetLogLevel = (newValue: any) => {
    // Log: OFF - This will cover error & critical
    let currentLogLevel: number = logLevel.Error;
    dispatch(inmemoryLogType(newValue));
    // Log: Level 1
    if (logType[1] === newValue) {
      currentLogLevel = logLevel.Warning;
    }
    // Log: Level 2
    else if (logType[2] === newValue) {
      currentLogLevel = logLevel.Information;
    }
    // Log: All
    else if (logType[3] === newValue) {
      currentLogLevel = logLevel.Debug;
    }
    dispatch(inmemoryCurrentLogLevel(currentLogLevel));
  };

  return (
    <>
      <Grid className="log-icon">
        <div
          className="loggingSectionIcon"
          id="loggingIcon"
          data-testid="logger"
          onClick={() => handleLogSelection()}
        >
          <LoggerSvg />
        </div>
      </Grid>
      {isLog && (
        <div className="log-position">
          <DialogTitle className="log-title">
            <Grid container alignItems="center" justifyContent="space-between">
              <div>
                <Grid container spacing={2} alignItems="center">
                  <Grid>
                    <LogTitleSvg></LogTitleSvg>
                  </Grid>
                  <Grid>
                    <span>{intl.formatMessage({ id: "ApplicationLogs" })}</span>
                  </Grid>
                </Grid>
              </div>
              <div className="d-flex cursor-pointer">
                <CloseIcon data-testid="loggerclose" onClick={handleLogClose} />
              </div>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {intl.formatMessage({ id: "LogContent" })}
            </DialogContentText>
            <Autocomplete
              id="combo-box-demo"
              options={logType}
              defaultValue={log}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    "data-testid": "my-logger",
                  }}
                />
              )}
              onChange={(event: any, newValue: any) => {
                handleSetLogLevel(newValue);
              }}
            />
          </DialogContent>
        </div>
      )}
    </>
  );
}

export default Logger;
