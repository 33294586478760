export const variables = {
    dataProfilingTableText: "table",
    dataProfilingGraphText: "graph",
    dataProfilingNullChartText: "nullChart",
    dataProfilingUniqueChartText: "uniqueChart",
    dataProfilingNullValueText: "NullValues",
    dataProfilingUniqueCountText: "UniqueCount",
    reindexDBConfig: {
        database:"autodb",
        hostname: "dataguruxplatform.mariadb.database.azure.com",
        port: "3306",
        username: "datagurutest@dataguruxplatform",
        password: "Expleotest@2022"
    }
}