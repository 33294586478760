import React, { useRef, useEffect, useState } from 'react';
import type { ChartData, ChartArea, ChartOptions, ActiveElement } from 'chart.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartDataLabels,
  Tooltip,
  Legend
);

function brandingGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
  const gradient = ctx.createLinearGradient(0, 0, 600, 0);
  gradient.addColorStop(0, "#7859C9");
  gradient.addColorStop(0.5, "#7857CD");
  gradient.addColorStop(1, "#492A9B");
  return gradient;
}

function pinkGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
  const gradient = ctx.createLinearGradient(0, 0, 600, 0);
  gradient.addColorStop(0, "#E389DB");
  gradient.addColorStop(0.5, "#EB80BD");
  gradient.addColorStop(1, "#F4779C");
  return gradient;
}

interface Props {
  drillDownTableHandler: (index: number) => void
  dbTableChart: {
    label: string[]
    recordCounts: number[]
    columnCounts: number[]
  }
}

const TableBarChart = ({drillDownTableHandler, dbTableChart}: Props) => {
  const chartRef = useRef<ChartJS<"bar", number[], string>>(null);
  const [chartData, setChartData] = useState<ChartData<'bar'>>({
    datasets: [],
  });

  /** Bar chart option and data props */
  const options: ChartOptions<'bar'> = {
    indexAxis: "y",
    responsive: true,
    onClick: (_evt, element) => element[0] && drillDownTableHandler(element[0].index),
    onHover: (_evt, element, chartThe) => element[0] ? 
    chartThe.canvas.style.cursor = "pointer" : chartThe.canvas.style.cursor = "default",
    scales: {
      x: {
        grace: "1%",
        ticks: {
          color: "#808080",
          font: {
            family: "'Montserrat', sans-serif",
            weight: "500"
          }
        },
        grid: {
          display: false,
        }
      },
      y: {
        ticks: {
          color: "#000000",
          font: {
            family: "'Montserrat', sans-serif",
            weight: "600"
          }
        },
        grid: {
          display: false
        }
      },
    },
    plugins: {
      datalabels: {
        formatter: (value: number) => {
          return value;
        },
        anchor: 'end',
        align: "right"
      },
      legend: {
        labels: {
          color: "#000000",
          usePointStyle: true,
          pointStyle: "rectRounded",
          font: {
            family: "'Montserrat', sans-serif",
            weight: "600"
          }
        }
      }
    }
  };

  /** Bar chart data props */
  const data: ChartData<'bar'> = {
    labels: dbTableChart.label,
    datasets: [
      {
        label: "Records Count",
        data: dbTableChart.recordCounts,
        borderRadius: 50,
        datalabels: {
          color: "#6946C6",
          font: {
            family: "'Montserrat', sans-serif",
            weight: "bold"
          },
        },
      },
      {
        label: "Columns Count",
        data: dbTableChart.columnCounts,
        borderRadius: 50,
        datalabels: {
          color: "#FC6F8B",
          font: {
            family: "'Montserrat', sans-serif",
            weight: "bold"
          },
        },
      },
    ],
  };

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }
    const chartData = {
      ...data,
      datasets: data.datasets.map((dataset, index) => {
        if(index === 0){
          return {
            ...dataset,
            backgroundColor: brandingGradient(chart.ctx, chart.chartArea),
          }
        }else{
          return {
            ...dataset,
            backgroundColor: pinkGradient(chart.ctx, chart.chartArea),
          }
        }
      }),
    };

    setChartData(chartData);
  }, []);

  return <Bar ref={chartRef} data={chartData} options={options} />;
}

export default TableBarChart;