import {
  GET_TESTDB_DATA,
  DELETE_TESTDB_ITEM,
  GENERATE_DATA,
  VIEW_JOB_LIST,
  INDEX_DATA_RESULT,
  TABLE_COLUMN_LIST,
  CONNECT_TESTDB,
  CONNECTED_TESTDB,
  TEST_DBCONNECTION,
  CONNECTION_LIST,
  CONNECTION_INSERT,
  CONNECTEDDB_BUTTON_STATUS,
} from "../actiontypes";
import fetch from "../../../utils/leoFetch";
import { config } from "../../../config/clientConfig";
import axios, { AxiosError } from "axios";
import { Dispatch } from "redux";
/** Import of Utils file import */
import { parseArrayOfNestedObject } from "../../../utils/parseObjects";



/** Get Table Records based on connected database */
export const getTestdbTableDetails =
  (connection: any) => (dispatch: any) => {
    let columnArr: any[];
    return new Promise(function (resolve, reject) {
      fetch(config.apiRootPath + config.getTableURL, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(connection),
      })
        .then((res) => res.json())
        .then((res) => {
          let convertedData;
          if (
            res.data !== undefined &&
            res.data[0] !== undefined &&
            res.data[0] !== null
          ) {
            //Function for converting nested Array objects into a flat object
            convertedData = parseArrayOfNestedObject(res.data)
            columnArr = Object.keys(convertedData[0]);
            columnArr.push("Action");
          }
          dispatch({
            payload: convertedData,
            type: GET_TESTDB_DATA,
          });
          dispatch({
            payload: columnArr,
            type: TABLE_COLUMN_LIST,
          });
          return resolve(res);
        });
    });
  };

/** Delete selected Record */

export const deleteTestData =
  (selectedtable: any, record: any) => (dispatch: any) => {
    return new Promise(function (resolve, reject) {
      fetch(config.apiRootPath + config.testdbURL + selectedtable, {
        method: "DELETE",
        body: JSON.stringify(record),
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          dispatch({
            payload: res.data,
            type: DELETE_TESTDB_ITEM,
          });
          return resolve(res.data);
        });
    });
  };

/** generate Data */

export const generateData =
  (apikey: any, workspaceId: any) => (dispatch: any) => {
    return new Promise(function (resolve, reject) {
      fetch(
        config.apiRootPath +
          config.startGenerateData +
          "?workspaceId=" +
          workspaceId,
        {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: apikey,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          dispatch({
            payload: res,
            type: GENERATE_DATA,
          });
          return resolve(res);
        });
    });
  };

/** get view Job List Details */

export const viewJobListDetails =
  (apikey: any, workspaceId: any) => (dispatch: any) => {
    return new Promise(function (resolve, reject) {
      fetch(
        config.apiRootPath + config.viewJobs + "?workspaceId=" + workspaceId,
        {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: apikey,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          dispatch({
            payload: res.data,
            type: VIEW_JOB_LIST,
          });
          return resolve(res);
        });
    });
  };

/** Inmemory for table columns */

export const inmemoryTableColums = (records: any) => (dispatch: any) => {
  dispatch({
    payload: records,
    type: TABLE_COLUMN_LIST,
  });
};

/** Start Index Test Data */

export const StartIndexData = () => (dispatch: any) => {
  return new Promise(function (resolve, reject) {
    fetch(config.apiRootPath + config.solrIndexurl, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        //  'Authorization':apikey
      },
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          payload: res,
          type: INDEX_DATA_RESULT,
        });
        return resolve(res);
      });
  });
};

/** Delete All records from Selected Table */

export const deleteAllTestData = (selectedtable: any) => (dispatch: any) => {
  return new Promise(function (resolve, reject) {
    fetch(config.apiRootPath + config.deleteAlltestdbURL + selectedtable, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          payload: res.data,
          type: DELETE_TESTDB_ITEM,
        });
        return resolve(res.data);
      });
  });
};

/** Get All DBConnection Details */

export const dbConnectionList = () => (dispatch: any) => {
  return new Promise(function (resolve, reject) {
    fetch(config.apiRootPath + config.getUserDBListurl, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          payload: res.data,
          type: CONNECTION_LIST,
        });
        return resolve(res);
      });
  });
};

/** Save Database Connection Details */
export const saveDBConnection = (connection: any) => (dispatch: any) => {
  return new Promise(function (resolve, reject) {
    fetch(config.apiRootPath + config.userDBListurl, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(connection),
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          payload: res.data,
          type: CONNECTION_INSERT,
        });
        return resolve(res);
      });
  });
};

/** Update Database Connection Details */
export const updateDBConnection = (connection: any) => (dispatch: any) => {
  return new Promise(function (resolve, reject) {
    fetch(config.apiRootPath + config.updateDBListurl, {
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(connection),
    })
      .then((res) => res.json())
      .then((res) => {
        return resolve(res);
      });
  });
};

/** Delete Database Connection Details */
export const deleteDBConnection =
  (selectedConnectionname: any) => (dispatch: any) => {
    return new Promise(function (resolve, reject) {
      fetch(
        config.apiRootPath +
          config.deleteDBListurl +
          "/" +
          selectedConnectionname,
        {
          method: "DELETE",
        }
      )
        .then((res) => res.json())
        .then((res) => {
          return resolve(res.data);
        });
    });
  };

/** Connection with TestDb */

export const connectTestDB = (payload: any) => async (dispatch: Dispatch) => {
  const response = await axios({
    method: "POST",
    url: config.apiRootPath + config.getTableURL,
    data: payload,
  })
    .then((res) => res.data)
    .catch((err: AxiosError) => err.response?.data);
  return response;
};


/** DisConnection with TestDb */

export const disConnectTestDB = (connection: any) => (dispatch: any) => {
  return new Promise(function (resolve, reject) {
    fetch(config.apiRootPath + config.getTableURL, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(connection),
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          payload: res,
          type: CONNECT_TESTDB,
        });
        dispatch({
          payload: connection,
          type: CONNECTED_TESTDB,
        });
        dispatch({
          payload: [],
          type: GET_TESTDB_DATA,
        });
        dispatch({
          payload: [],
          type: TABLE_COLUMN_LIST,
        });
        return resolve(res);
      });
  });
};

/** Set the ConnectionButtonStatus  */

export const setConnectionDetails =
  (connectionButtonStatus: boolean) => (dispatch: any) => {
    dispatch({
      payload: true,
      type: CONNECTEDDB_BUTTON_STATUS,
    });
  };

/** DisConnection with TestDb */

export const clearConnectionDetails = () => (dispatch: any) => {
  dispatch({
    payload: {},
    type: CONNECT_TESTDB,
  });
  dispatch({
    payload: {},
    type: CONNECTED_TESTDB,
  });
  dispatch({
    payload: [],
    type: GET_TESTDB_DATA,
  });
  dispatch({
    payload: [],
    type: TABLE_COLUMN_LIST,
  });
};

/** Connection with Test DB Connection */
export const testDBConnection = (dbConfig: any) => (dispatch: any) => {
  return new Promise((resolve, reject) => {
    fetch(config.apiRootPath + config.testDBConnectionURL, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dbConfig),
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          payload: res,
          type: TEST_DBCONNECTION,
        });
        return resolve(res);
      })
      .catch((error) => {
        return reject(error);
      });
  });
};
