import { DB_PROFILE } from "../../actions/actiontypes";

const initialState = {
  dbProfileResult: [],
};

export default function dbOperationReducer(state = initialState, action: any) {
  switch (action.type) {
    case DB_PROFILE:
      return {
        ...state,
        dbProfileResult: action.payload,
      };
    default:
      return state;
  }
}
