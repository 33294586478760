// import Icons from material UI
import { ReactComponent as AdministrationIcon } from "../assets/images/avatars/sidebar-administration-icon.svg";
import { ReactComponent as DBOperationsIcon } from "../assets/images/avatars/sidebar-db-operations-icon.svg";
import { ReactComponent as DataOperationsIcon } from "../assets/images/avatars/sidebar-data-operations-icon.svg";
import { ReactComponent as ExternalToolsIcon } from "../assets/images/avatars/External-tools-icon.svg";

/*
 Here is the array, which we've implemented in the sidebar
 This array contains all the permission, sidebar path, title, Icons and its submenus
 * Title -- It's the sidebar name -- Required
 * path -- It's the URL. When user click the title that time it's directing to this path only.
 * Icon -- Pro sidebar had capabality to add icon for each title and its submenus, So we can add whatever icon given by UI designer
 * Permissions --  This is main part of the sidebar. We need to give permission for which user should see menus in the sidebar.
   If object don't have permission it will consider of all the user have the app permission
 * children --  It's the submenus. we can add multiple children under the menus. its automatically rendering in the sidebar.
*/

const appMenuItems = [
  {
    title: "Administration",
    Icon: AdministrationIcon,
    children: [
      {
        title: "DB Connections",
        path: "/db-connections",
      },
    ],
  },
  {
    title: "Database Operations",
    Icon: DBOperationsIcon,
    children: [
      {
        title: "Data Profiling",
        path: "/data-profiling",
      },
      {
        title: "View DB",
        path: "/db",
      },
      {
        title: "Query Manager",
        path: "/query-manager",
      },
      {
        title: "Data Comparison",
        path: "/data-comparison",
      }
    ]
  },
  {
    title: "Data Operations",
    Icon: DataOperationsIcon,
    children: [
      {
        title: "Search",
        path: "/search",
      },
      {
        title: "Synthetic Data",
        path: "/data",
      },
    ],
  },
  {
    title: "External Tools",
    Icon: ExternalToolsIcon,
    children: [
      {
        title: "Jailer",
        path: "/jailer",
      },
      {
        title: "Tools Integration",
        path: "/tools-integration",
      },
    ],
  },
];

export default appMenuItems;
