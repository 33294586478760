/** 
 * Use Case: slicing the object
 * obj parameter - which object want to slice
 * objLength - Count of object length for slice
 */
export const objSlice = (obj: any, objLength: number) => {
  const filteredKeys = Object.keys(obj).slice(0, objLength);
  const newObj = {} as any;
  filteredKeys.forEach((key: string) => newObj[key as keyof any] = obj[key]);
  return newObj;
}

/** Use case: convert nested object into a flat object.
 * 1. Import this function name
 * 2. The first parameter is, what object that you want to convert
 * *  Second one is, Empty object. The return data will be updated to the empty object.
 * 3. Third Parameter is used for the declaration of nested object property name. It's not required to pass as a param.
*/
export const parseNestedObject = (targetObj: any, returnObj: any, propertyNameKey?: string) => {
  for (const key in targetObj) {
      if (Object.prototype.hasOwnProperty.call(targetObj, key)) {
          let newKey;
          propertyNameKey === undefined ? newKey = key : newKey = propertyNameKey + '.' + key;
          const value = targetObj[key];
          typeof value === "object" ? parseNestedObject(value, returnObj, newKey) : returnObj[newKey] = value;
      }
  }
}


/** We need to use this function for converting nested Array objects into a flat object.
 * 1. Import this function
 * 2. PASS the Array of object that you have as a parameter
 * 3. This function will return the new flat object data as a array format
 * 4. Ex: [{job: {company: "", role: "", address: {street: "", pincode: ""}}}]
 */
export const parseArrayOfNestedObject = (data: any) => {
  const convertedData = [] as Array<any>;
  data.map((item: any) => {
      const returnObj: any = {};
      parseNestedObject(item, returnObj);
      return convertedData.push(returnObj);
  })
  return convertedData;
}