export const config = {
  companyName: "Expleo",
  AppVersion: "1.0 Beta",
  apiRootPath: process.env.REACT_APP_SERVER_ROOT_PATH,
  authAppPath: process.env.REACT_APP_AUTH_APP_PATH,
  authAppValidate: process.env.REACT_APP_AUTH_APP_VALIDATE,
  authRefreshTokenPath: process.env.REACT_APP_AUTH_APP_REFRESH_TOKEN_PATH,
  userDBListurl: "/dbconnection/createuserdbconnection",
  getUserDBListurl: "/dbconnection/getconnectionlist",
  updateDBListurl: "/dbconnection/edituserdbconnection",
  deleteDBListurl: "/dbconnection/deleteuserdbconnection",
  userDBPowerBIurl: "https://app.powerbi.com/reportEmbed?reportId=b8278c42-4190-412e-8058-fe85bad9b3ef&autoAuth=true&ctid=3b0e7247-e0d5-44bf-8ed1-d01b18d16ca2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtZXVyb3BlLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9",
  tonicaiApiRootPath: "https://app.tonic.ai",
  getworkspaceURL: "/getworkspace",
  getdatasourceURL: "/datasource",
  connectDBURL: "/connect",
  disconnectDBURL: "/disconnect",
  testDBConnectionURL: "/dbconnection/testuserdbconnection",
  setTestDbURL: "/settestdb",
  getTableURL:"/getTableRecords",
  testdbURL: "/testdb/",
  deleteAlltestdbURL: "/deleteAll/",
  startGenerateData: "/GenerateData/start",
  viewJobs: "/GenerateData",
  solrIndexurl: "/index",
  solrsearchurl: "/search",
  mockarooURL: "/synthetic",
  successCode: "MSG200",
  failureCode: "MSG401",
  serverErrorCode: "MSG500",
  clientFailureCode: "MSG400",
  timeout: 1800,
  autologoutCheck: 30000,
  tokenAutoLogoutCheck: 30000,
  tokenExpiryCheck: 1800000,
  downloadSchema: "/downloadSchemaData",
  saveNewSchema: "/saveSchema",
  generateDataProfile: "/dataProfile",
  getSchemaNames: "/getSchemaNames",
  saveQuery: "/saveQuery",
  getQuery: "/getQueries",
  editQuery: "/editQuery",
  deleteQuery: "/deleteQuery",
  disconnectDBText: "disconnectDB"
};