import {
  USER_NAME,
  GET_TOKEN_EXPIRY,
  REFRESH_TOKEN,
  CONNECT_AUTHAPP,
} from "../../actions/actiontypes";

const initialState = {
  username: "",
  isLoggedIn: false,
  tokenExpiry: 0,
  newAccessToken: "",
};

// Get the username from action file and store in root state
export default function authenticationReducer(
  state = initialState,
  action: any
) {
  switch (action.type) {
    case CONNECT_AUTHAPP:
      return {
        ...state,
        authapp: action.payload,
      };
    case USER_NAME:
      return {
        ...state,
        username: action.payload,
        isLoggedIn: true,
      };
    case GET_TOKEN_EXPIRY:
      return {
        ...state,
        tokenExpiry: action.payload,
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        newAccessToken: action.payload,
      };
    default:
      return state;
  }
}
