/**Imports of React libraries */
import { useIntl } from "react-intl";
/**Imports of Material UI Elements */
import { Typography } from "@material-ui/core";
import DataComparisonForm from "./dataComparisonForm";

function DataComparisonIndex() {
  const intl = useIntl();
  return (
    <div>
      <div className="TestDBTitle">
        <Typography className="tabtextTestDB">
          {intl.formatMessage({ id: "DataComparison" })}
        </Typography>
      </div>
      <div className="midContentShadowTestDB">
        <DataComparisonForm />
      </div>
    </div>
  );
}

export default DataComparisonIndex;
