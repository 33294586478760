/**Imports of React libraries */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
/**Import of Root State for Localization */
import { RootState } from "../store/reducers/rootReducer";
/**Imports of Material UI Elements */
import { AppBar, Toolbar, Select, MenuItem } from "@material-ui/core";
import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
/**Imports of API Actions */
import { inmemoryhandleLanguageChange } from "../store/actions/landingPage/landingPage";
import {
  sidebarMenuOpenAction,
  sidebarMenuCloseAction,
} from "../store/actions/sidebarMenu/sidebarMenuAction";
/**Imports of Material UI Icons */
import ListItemIcon from "@material-ui/core/ListItemIcon";
/**Imports of SVG Icons */
import { ReactComponent as ENSvg } from "../../src/assets/images/avatars/united-states.svg";
import { ReactComponent as DESvg } from "../../src/assets/images/avatars/germany.svg";
import { ReactComponent as FRSvg } from "../../src/assets/images/avatars/france.svg";
import { ReactComponent as UserSvg } from "../../src/assets/images/avatars/user.svg";
import { ReactComponent as DataGuruLogo } from "../assets/images/dataguru_logo.svg";
import { ReactComponent as LogoutSvg } from "../../src/assets/images/avatars/Logout.svg";
import { ReactComponent as AccessRequiredImg } from "../../src/assets/images/avatars/access-required-popup-img.svg";
import { ReactComponent as AccountRestrictedImg } from "../../src/assets/images/avatars/account-restricted-img.svg";
import { ReactComponent as LogoutImg } from "../../src/assets/images/avatars/logout-img.svg";
import { ReactComponent as IdleTimeExpired } from "../../src/assets/images/avatars/idle-time-expired-img.svg";

/**Import of Config files */
import { MainAppbarLog, logLevel } from "../config/logConfig";
/**Import of Logger files */
import { logFunctionStart, logFunctionEnd, writeLog } from "../logManager";
import { Link } from "react-router-dom";

function MainAppbar() {
  const { instance } = useMsal();
  const dispatch = useDispatch();

  // Sidebar Menu state
  const [isSidebarOpen, setIsSidebaropen] = React.useState(true);
  // Auto time out count state
  const [progress, setProgress] = React.useState(30);
  // Popup handler state
  const [open, setopen] = React.useState(false);
  // Logout popup handler state
  const [isLogoutPopOpen, setIsLogoutPopOpen] = React.useState(false);

  const language = useSelector(
    (state: RootState) => state.landingPage.language
  );
  const currentLogLevel = useSelector(
    (state: RootState) => state.landingPage.currentLogLevel
  );

  // Receive the username from store and display in this template
  // const username = useSelector(
  //   (state: RootState) => state.userAuthentication.username
  // );

  // Once we receive the username from store, update the username in localstore to fix page refresh issue
  // useEffect(() => {
  //   if (username) {
  //     localStorage.setItem("username", username.name || []);
  //   }
  // }, [username]);

  /**Method for Change event of Language dropdown */
  const handleChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const startTime = logFunctionStart(currentLogLevel, "handleChange");
    try {
      dispatch(inmemoryhandleLanguageChange(e.target.value));
      writeLog(
        logLevel.Information,
        currentLogLevel,
        "Language Changed",
        MainAppbarLog.handleChange
      );
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleChange");
    }
  };

  /**Method to display alert while clicking Logout button */
  const handleLogout = () => {
    const startTime = logFunctionStart(currentLogLevel, "handleLogout");
    try {
      instance.logoutRedirect({ postLogoutRedirectUri: "/" });
      writeLog(
        logLevel.Information,
        currentLogLevel,
        "Login/Logout functionality will be activated on integration with AD",
        MainAppbarLog.handleLogout
      );
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleLogout");
    }
  };

  const handleLogoutPopup = () => {
    setIsLogoutPopOpen(!isLogoutPopOpen);
  };
  const handleClose = () => {
    setopen(!open);
  };

  // Auto logout timer
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress === 0
          ? // As per my knowladge, We have to write logout function in here
            30
          : prevProgress - 1
      );
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  // tiggering sidebar from navbar for the mobile screen
  const sidebarMenuHandler = () => {
    setIsSidebaropen(!isSidebarOpen);

    // dispatching the action based on the "isSidebarOpen" state
    isSidebarOpen
      ? dispatch(sidebarMenuOpenAction(isSidebarOpen))
      : dispatch(sidebarMenuCloseAction(isSidebarOpen));
  };

  return (
    <>
      <div className="topNavExtraSpace"></div>
      <AppBar position="fixed" className="header-appbar">
        <Toolbar className="header-toolbar">
          <div className="headerToolbarMenu">
            <button
              className="headerToolbarMenuBtn"
              onClick={sidebarMenuHandler}
            >
              <svg
                width="26"
                height="20"
                viewBox="0 0 26 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.58056 3.42345H24.4198C25.2913 3.42345 26 2.71476 26 1.84326C26 0.971764 25.2913 0.262695 24.4198 0.262695H1.58056C0.709069 0.262695 0 0.971764 0 1.84326C0 2.71476 0.709069 3.42345 1.58056 3.42345V3.42345Z"
                  fill="white"
                />
                <path
                  d="M24.4198 8.41895H1.58056C0.709069 8.41895 0 9.12801 0 9.99951C0 10.871 0.709069 11.5797 1.58056 11.5797H24.4198C25.2913 11.5797 26 10.871 26 9.99951C26 9.12801 25.2913 8.41895 24.4198 8.41895V8.41895Z"
                  fill="white"
                />
                <path
                  d="M24.4198 16.5762H1.58056C0.709069 16.5762 0 17.2856 0 18.1571C0 19.0286 0.709069 19.7373 1.58056 19.7373H24.4198C25.2913 19.7373 26 19.0286 26 18.1571C26 17.2856 25.2913 16.5762 24.4198 16.5762Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
          <div className="header-toolbar-logo">
            <Link to="/">
              <DataGuruLogo data-testid="DataGuruLogo" />
            </Link>
          </div>
          <div className="rightToolbar">
            <Select
              className="languageselection"
              defaultValue={language}
              onChange={handleChange}
              inputProps={{
                "data-testid": "lang",
              }}
            >
              <MenuItem value="en" className="menu-selection">
                <ENSvg title="EN" />
              </MenuItem>
              <MenuItem value="de">
                <DESvg title="DE" />
              </MenuItem>
              <MenuItem value="fr">
                <FRSvg title="FR" />
              </MenuItem>
            </Select>
          </div>
          <ListItemIcon>
            <UserSvg className="header-user-svg" />
            <Grid className="header-userDetails">
              <Typography className="header-username"></Typography>
            </Grid>
          </ListItemIcon>
          <AuthenticatedTemplate>
            <ListItemIcon
              data-testid="logout"
              onClick={handleLogoutPopup}
              className="cursor-pointer"
            >
              <LogoutSvg />
            </ListItemIcon>
          </AuthenticatedTemplate>
        </Toolbar>
      </AppBar>

      {/* Access required popup */}
      {/* <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="accessRequiredPopup"
        >
        <DialogContent>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className="accessRequiredPopupImg">
                <AccessRequiredImg />
             </div>
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <DialogTitle className="success-title">
                Access Required
              </DialogTitle>
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              User is not authorized to access X Platform, 
              You can request access through <a href="https://expleo-apps.easyvista.com/index.php?timestamp=1634622040896&name=com.expleo.60c8b19e70b3c" target="_blank" rel="noreferrer" >EasyVista</a>
            </DialogContentText>
          </DialogContent>
        </DialogContent>
        <DialogActions className="ssoPopupsButton">
          <button onClick={handleClose}>
            Logout (<span>10s</span>)
          </button>
          <a href="https://expleo-apps.easyvista.com/index.php?timestamp=1634622040896&name=com.expleo.60c8b19e70b3c" target="_blank" rel="noreferrer">
            <button className="reindexpop">
              Request Access
            </button>
          </a>
        </DialogActions>
      </Dialog> */}

      {/* Your account has restricted access */}
      {/* <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="accessRequiredPopup"
        >
        <DialogContent>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className="accessRequiredPopupImg">
                <AccountRestrictedImg />
             </div>
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <DialogTitle className="success-title">
                Your account has restricted access
              </DialogTitle>
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              User is not authorized to access X Platform, 
              You can request access through <a href="https://expleo-apps.easyvista.com/index.php?timestamp=1634622040896&name=com.expleo.60c8b19e70b3c" target="_blank" rel="noreferrer" >EasyVista</a>
            </DialogContentText>
          </DialogContent>
        </DialogContent>
        <DialogActions className="ssoPopupsButton">
          <button onClick={handleClose}>
            Logout (10s)
          </button>
          <a href="https://expleo-apps.easyvista.com/index.php?timestamp=1634622040896&name=com.expleo.60c8b19e70b3c" target="_blank" rel="noreferrer">
            <button
              className="reindexpop"
            >
              Request Access
            </button>
          </a>
        </DialogActions>
      </Dialog> */}

      {/* Idle time expired popup */}
      {/* <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="accessRequiredPopup"
        >
        <DialogContent>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className="accessRequiredPopupImg">
                <IdleTimeExpired />
             </div>
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <DialogTitle className="success-title">
                Idle timer expired
              </DialogTitle>
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              There was no activity for a while so we closed the session
            </DialogContentText>
          </DialogContent>
        </DialogContent>
        <DialogActions className="IdleTimeButton ssoPopupsButton">
            <button className="reindexpop" onClick={handleClose}>
              Ok
            </button>
        </DialogActions>
      </Dialog> */}

      {/* Logout popup */}
      <Dialog
        open={isLogoutPopOpen}
        onClose={handleLogoutPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="accessRequiredPopup logoutPopup"
      >
        <DialogContent>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className="accessRequiredPopupImg">
                <LogoutImg />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <DialogTitle className="success-title">
                Come Back Soon!
              </DialogTitle>
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              Are you sure want to Logout
            </DialogContentText>
          </DialogContent>
        </DialogContent>
        <DialogActions className="ssoPopupsButton">
          <button onClick={handleLogoutPopup}>Cancel</button>
          <button onClick={handleLogout} className="reindexpop">
            Logout
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default MainAppbar;
