import * as React from "react";
import { useIntl } from "react-intl";

import { releaseNotes } from "../../config/releaseNotes";
import { Typography } from "@material-ui/core";
import ReleaseNoteAccordion from "./releaseNoteAccordion";

const NewVersion = () => {
  const intl = useIntl();

  return (
    <>
      <section className="versionPage">
        <div className="TestDBTitle">
          <Typography className="tabtextTestDB">
            {intl.formatMessage({ id: "VersionHistory" })}
          </Typography>
        </div>
        <div className="px-3 mt-2">
          <div className="d-flex flex-wrap" style={{ position: "relative" }}>
            <div className="VersionTimeline col-xl-8 col-12  p-0 w-100">
              {releaseNotes.map((release, index) => (
                <ReleaseNoteAccordion key={index} release={release} index={index} />
              ))}
            </div>
            <div className="col-xl-4 col-12 CurrentVersionStatusOrder mb-3 mb-xl-0 p-0">
              <div className="CurrentVersionStatus">
                <div className="p-text">Current Version</div>
                <div className="h1-text">{releaseNotes[0].label}</div>
                <div className="p-text">Released on</div>
                <div className="h1-text">{releaseNotes[0].date}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewVersion;
