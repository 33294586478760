import {
  CURRENT_WORKSPACE,
  GET_WORKSPACE,
  GET_DATASOURCE,
  SET_WORKSPACE,
  SELECTED_APIKEY,
  ISVERIFIED_APIKEY,
} from "../actiontypes";
import fetch from "../../../utils/leoFetch";
import { config } from "../../../config/clientConfig";

// Setting Current WorkSpace
export const inmemoryCurrentWorkspace = (workspace: any) => (dispatch: any) => {
  dispatch({
    payload: workspace,
    type: CURRENT_WORKSPACE,
  });
};

// Get Workspace list based on Apikey
export const getWorkspace = (apikey: any) => (dispatch: any) => {
  const options = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: apikey,
    },
  };
  return new Promise(function (resolve, reject) {
    fetch(config.apiRootPath + config.getworkspaceURL, options)
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          payload: res,
          type: GET_WORKSPACE,
        });
        return resolve(res);
      });
  });
};

//Get DataSource Settings Based on Workspace Id
export const getDataSource =
  (workspaceId: any, apikey: any) => (dispatch: any) => {
    return new Promise(function (resolve, reject) {
      fetch(
        config.apiRootPath +
          config.getdatasourceURL +
          "?workspaceId=" +
          workspaceId,
        {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: apikey,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          dispatch({
            payload: res.data,
            type: GET_DATASOURCE,
          });
          return resolve(res);
        });
    });
  };

// Setting inmemory for Enable the Data Opertaions
export const inmemorySetWorkspace = (setworkspace: any) => (dispatch: any) => {
  dispatch({
    payload: setworkspace,
    type: SET_WORKSPACE,
  });
};

/** Inmemory for api key */
export const inmemoryApikey = (apikey: any) => (dispatch: any) => {
  dispatch({
    payload: apikey,
    type: SELECTED_APIKEY,
  });
};

/** Inmemory for verify state */
export const inmemoryVerifyState = (isVerify: any) => (dispatch: any) => {
  dispatch({
    payload: isVerify,
    type: ISVERIFIED_APIKEY,
  });
};
