// Imports of React libraries
import React from "react";

// Import Pro sidebar
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";

// Import Icons from material UI
import { ReactComponent as HomeIcon } from "../../assets/images/avatars/sidebar-home-icon.svg";

// Import navigation link
import { NavLink, useLocation } from "react-router-dom";

// Import sidebar menus
import SidebarMenuHead from "./sidebarMenuHead";

/**Import of Config files */
import { releaseNotes } from "../../config/releaseNotes";

interface Props {
  isCollapsed: boolean;
  setIsCollapsed: (value: boolean) => void;
}

function ProSidebarMenu({ isCollapsed, setIsCollapsed }: Props) {
  const location = useLocation();

  // sidebar collapse toggle function
  const collapseHandler = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="sidebar">
      <button className="sidebarCollapsed" onClick={collapseHandler}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.07342 7.96495C1.07737 7.70879 1.17912 7.45417 1.37734 7.26187L7.61784 1.21074C8.01481 0.825814 8.64867 0.835577 9.03344 1.23255C9.41822 1.62936 9.40846 2.26309 9.01164 2.64806L3.48971 8.00217L8.84395 13.5238C9.22872 13.9208 9.21896 14.5545 8.82215 14.9392C8.42533 15.3243 7.79147 15.3146 7.40654 14.9174L1.35535 8.67706C1.16312 8.47866 1.06948 8.22102 1.07342 7.96495Z"
            fill="white"
          />
          <path
            d="M7.07245 8.05724C7.07639 7.80108 7.17815 7.54645 7.37636 7.35415L13.6169 1.30303C14.0138 0.9181 14.6477 0.927862 15.0325 1.32483C15.4172 1.72165 15.4075 2.35538 15.0107 2.74034L9.48873 8.09445L14.843 13.6161C15.2277 14.0131 15.218 14.6467 14.8212 15.0315C14.4244 15.4166 13.7905 15.4068 13.4056 15.0097L7.35438 8.76935C7.16215 8.57094 7.0685 8.31331 7.07245 8.05724Z"
            fill="#AAAAAA"
          />
        </svg>
      </button>
      <ProSidebar collapsed={isCollapsed}>
        <SidebarHeader>
          <Menu>
            <MenuItem
              active={
                location.pathname === "/" || location.pathname === "/home"
              }
              icon={<HomeIcon />}
            >
              Home
              <NavLink exact to="/" activeClassName="activeSidebar" />
            </MenuItem>
          </Menu>
        </SidebarHeader>
        <SidebarContent>
          <SidebarMenuHead />
        </SidebarContent>
        <SidebarFooter className={`${isCollapsed ? "collapsed" : ""}`}>
          <div className="sidebarFooterFlex">
            <div className="sidebarFooterHelp">
              <NavLink to="/help" className="d-flex">
                <div className="sidebarFooterIcon">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.1124 0.112305C3.63908 0.112305 0 3.75138 0 8.2247C0 12.698 3.63908 16.3371 8.1124 16.3371C12.5857 16.3371 16.2248 12.698 16.2248 8.2247C16.2248 3.75138 12.5857 0.112305 8.1124 0.112305ZM8.1124 14.985C4.38475 14.985 1.35208 11.9523 1.35208 8.2247C1.35208 4.49706 4.38475 1.46438 8.1124 1.46438C11.84 1.46438 14.8727 4.49706 14.8727 8.2247C14.8727 11.9523 11.84 14.985 8.1124 14.985Z"
                      fill="white"
                    />
                    <path
                      d="M7.94268 11.0439C7.50122 11.0514 7.14564 11.407 7.13819 11.8484C7.13753 11.862 7.13753 11.8762 7.13819 11.8897C7.15375 12.3338 7.52556 12.6813 7.96972 12.6664H7.94268C8.38887 12.659 8.74717 12.2953 8.74717 11.8484C8.73634 11.4083 8.38278 11.0548 7.94268 11.0439Z"
                      fill="white"
                    />
                    <path
                      d="M8.09838 3.81009C7.14517 3.7817 6.22441 4.15756 5.56325 4.84442C5.48685 4.94041 5.44629 5.06007 5.44831 5.18245C5.45171 5.48194 5.68966 5.72598 5.98915 5.73679C6.12843 5.73745 6.26295 5.68675 6.36774 5.59482C6.78689 5.16014 7.36623 4.91607 7.96994 4.9188C8.88259 4.9188 9.32202 5.41229 9.32202 5.99369C9.32202 7.23758 7.11139 7.39308 7.11139 9.00881C7.11139 9.40089 7.35476 10.1243 7.86179 10.1243C7.86584 10.1243 7.87057 10.1243 7.87532 10.1243C8.14775 10.1243 8.36881 9.9032 8.36881 9.63077C8.36881 9.44824 8.24038 9.29274 8.24038 9.11021C8.24038 7.89335 10.62 7.72435 10.6741 5.83822C10.6741 4.68896 9.70058 3.81009 8.09838 3.81009Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="sidebarFooterTitle">Help</div>
              </NavLink>
            </div>
            <div className="sidebarFooterVersion">
              <NavLink to="/version">
                <span>{releaseNotes[0].label}</span>
              </NavLink>
            </div>
          </div>
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
}

export default ProSidebarMenu;
