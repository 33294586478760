/**Imports of React libraries */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
/**Imports of Reusable Components */
import { RootState } from "../../../store/reducers/rootReducer";
/**Import of Config file */
import { config } from "../../../config/clientConfig";
import SchemaTypes from "../../../config/configSchemaType.json";
/** Import of Icons */
import { ReactComponent as ExcelIcon } from "../../../assets/images/avatars/excel-icon.svg";
import { ReactComponent as Warning } from "../../../assets/images/avatars/Invalid.svg";
/** Import of Reuseable component */
import SchemaForm from "./schemaForm";
import { useIntl } from "react-intl";
/**Import of Logger files */
import { logFunctionStart, logFunctionEnd, writeLog } from "../../../logManager";
import { schemaCreationLog, logLevel } from "../../../config/logConfig";
/**Imports of Material UI Elements */
import { FormControl, Grid, Link, makeStyles, Snackbar, TextField, Tooltip, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
/** Imports of Rect Beautiful DND for the draggable component */
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { CSVLink } from "react-csv";
/**Imports of API Actions */
import {
    downloadSchemaData,
    saveNewSchema,
    getListOfSchemaNames
} from "../../../store/actions/dataOperation/dataOperationAction";
/** Import of Utils file import */
import { objSlice, parseArrayOfNestedObject } from "../../../utils/parseObjects";


/** Material UI classes */
const useStyles = makeStyles({
    underline: {
        "&:after": {
            borderBottom: `2px solid #6946C6`
        }
    }
});

/** Schema object type declaration */
export interface SchemaDataDetailsTypes {
    name: string
    function: string
    param1?: string
    param2?: string
    param3?: string
    param4?: string
    param5?: string
    param6?: string
}

/** Inital schema form details */
const schemaDataDetails: SchemaDataDetailsTypes = {
    name: "",
    function: "",
    param1: "",
    param2: "",
    param3: "",
    param4: "",
    param5: "",
    param6: ""
}

/** Main Function */
function SchemaCreation(): JSX.Element {
    const csvLinkRef = React.useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    /** Material UI classes */
    const classes = useStyles();
    const intl = useIntl();
    const dispatch = useDispatch();
    // Get schema names from the redux
    const allSchemaNames = useSelector(
        (state: RootState) => state.searchOperation.schemaNames
    );
    /** Storing Schema type config file into local state */
    const typesOfSchema = SchemaTypes || [];
    /**
     * State for storing the user schema name 
     * State for validating existing schema name with current schema
     */
     const [schemaNameValidation, setSchemaNameValidation] = useState({
        schemaName: "",
        isSameSchemaName: false,
        isSchemaNameEmpty: false
    });
    /** State for storing the user total schema data count */
    const [dataCount, setDataCount] = useState(0);
    /** State for storing recently deleted data */
    const [currentDeletedData, setCurrentDeletedData] = useState<SchemaDataDetailsTypes>(schemaDataDetails);
    /** State for storing recently deleted data index */
    const [currentDeletedDataIndex, setCurrentDeletedDataIndex] = useState(0);
    /** State for handle field level delete Snackbar visibility */
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    /** State for handle schema save message Snackbar visibility */
    const [isSchemaSavedSnackbarOpen, setIsSchemaSavedSnackbarOpen] = useState(false);
    /** State for handle schema data has downloaded or not */
    const [successMsgIndication, setSuccessMsgIndication] = useState({
        isSchemaSaved: true,
        isSchemaDataDownloaded: true
    });
    /** State for check all required fields are filled or not
     * This state to check validation while user changing the value in form
     */
    const [isAllFieldFilled, setIsAllFieldFilled] = useState(false);
    /** State will trigger when user clicks the download or save button
     * This state to show error on the schema form
     */
     const [isFilledRequiredFields, setIsFilledRequiredFields] = useState(false);
    /** State for change download button inside content */
    const [isFileDownloading, setIsFileDownloading] = useState(false);
    /** State for storing downloadable data */
    const [syntheticData, setsyntheticData] = useState([]);
    /** Error message */
    const [errorMessage, setErrorMessage] = useState("");
     /** Error message */
     const [errorMessageSnackbar, setErrorMessageSnackbar] = useState(false);

    const currentLogLevel = useSelector(
        (state: RootState) => state.landingPage.currentLogLevel
    );
    /** This is the main schema value state. 
     * We're storing user entering all the values inside this state
     * Initially we're showing only one empty Schema form for the user */
    const [schemaValues, setSchemaValues] = useState<Array<SchemaDataDetailsTypes>>([schemaDataDetails]);

    /** This funtion coming from the React Beautify DND package.
     * After the Drag event we're changing the order of the form fields using splice.
     */
    const handleDragEnd = (result: DropResult) => {
        //if no destination exits(cancel event), exit this function
        if (!result.destination) return;
        const items = Array.from(schemaValues);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setSchemaValues(items);
    };

    /** Schema from add functionality */
    const addSchemaHandler = () => {
        setIsFilledRequiredFields(false);
        setSchemaValues([
            ...schemaValues,
            schemaDataDetails,
        ])
    }

    /** 
     * Here we're slicing the Schema data object as per the Function selection
     */
    React.useEffect(() => {
        typesOfSchema.filter((listOfSchemaTypes) => !schemaValues.find((schemaObjs: SchemaDataDetailsTypes, index: number) => {
            /** Comparing config function file with user selected function */
            if(listOfSchemaTypes.function === schemaObjs.function){
                    const items = schemaValues;
                    /**
                     * Here we're calling the objSlice function for slicing empty param as per the function selection
                     * listOfSchemaTypes.paramCount + 2 - it means, we've name and function in the object so added 2 for ignore those 
                     * */ 
                    const newObj: SchemaDataDetailsTypes = objSlice(schemaObjs, listOfSchemaTypes.paramCount + 2);
                    // Replacing the old data to above sliced object
                    items.splice(index, 1, newObj);
                    setSchemaValues(items);
            }
        } )) 
    }, [schemaValues]);

    /** Schema form delete functionality */
    const deleteItem = (item: SchemaDataDetailsTypes, indexId: number) => {
        setSchemaValues(schemaValues.filter((x, index) => index !== indexId));
        // Storing recently deleted schema form detail in the state
        setCurrentDeletedData(item);
        // Storing recently deleted schema form index in the state
        setCurrentDeletedDataIndex(indexId);
        // calling the Snackbar with undo option
        setIsSnackbarOpen(true);
    };

    /** Type and its parameters validation function */
    const schemaInputValidationFunction = () => isAllFieldFilled ? true : false;

    /** Here we're checking Name, Function and Its params filled or not
     * If all filled updating the validation state to True else False
     */
    React.useEffect(()=>{
        setIsAllFieldFilled(false);
        schemaValues.map((data: SchemaDataDetailsTypes)=> {
            for (const property in data) {
                if (data[property] === "") {
                    setIsAllFieldFilled(true);
                }
            }  
        })
    }, [schemaValues]);

    /** Schema form download functionality
     * We're calling the schemaInputValidationFunction() for the validation
     * here we're calling the SD API with the schema form data
     */
    const handleDownloadSchemaData = async () => {
        const valid: boolean = await schemaInputValidationFunction();
        setIsFilledRequiredFields(valid);
        // Write log
        const startTime = logFunctionStart(
            currentLogLevel,
            "handleDownloadSchemaData"
          );
        try{
            if(!valid){
                setIsFileDownloading(true);
                const data: any = await dispatch(downloadSchemaData(dataCount, schemaValues));
                if(data.messageCode === config.successCode && data.response){
                    // Here we're converting the nested object into a flat object.
                    const convertedData = parseArrayOfNestedObject(data.response);
                    setsyntheticData(convertedData.length > 0 ? convertedData : data.response);
                    writeLog(
                        logLevel.Information,
                        currentLogLevel,
                        "schemDownloaded",
                        schemaCreationLog.schemDownload
                    );
                }else{
                    /** This state for open snackbar with Error Message */
                    setErrorMessage(data.userMessage);
                    setErrorMessageSnackbar(true);
                    setIsFileDownloading(false);
                    writeLog(
                        logLevel.Error,
                        currentLogLevel,
                        "schemDownload error",
                        schemaCreationLog.schemDownload
                    );
                }
            }
        } finally {
            logFunctionEnd(currentLogLevel, startTime, "handleDownloadSchemaData");
          }
    }

    /** once we got synthetic data from the SD server this useEffect calling the download functionality */
    React.useEffect(() => {
        if (syntheticData.length > 0) {
            csvLinkRef.current?.link?.click();
            setIsFileDownloading(false);
            setSuccessMsgIndication({isSchemaSaved: false, isSchemaDataDownloaded: true});
            setIsSchemaSavedSnackbarOpen(true);
        }
    }, [syntheticData]);

    /**
     * this check for, Is the schema name already exist or not for save functionlity
     */
    const isSameSchemaNameValidationFucntion = ()=> {
        for(let i = 0; i < allSchemaNames.length; i++){
            if(allSchemaNames[i].toLowerCase() === schemaNameValidation.schemaName.trim().toLowerCase()){
                return true;
            }
        }
    }
    /** This function for saving a new schema to the SD server
     * Check for, Is the schema name empty or not. If Empty currently showing browser alert for the user
     */
    const handleSaveSchema = async () => {
        // This method for check, all required fields are filled or not
        const valid: boolean = await schemaInputValidationFunction();
        // This method for check, is the name already existed or not
        const sameSchemaName: boolean | undefined = await isSameSchemaNameValidationFucntion();
        // This method for call the error messages to the schema
        setIsFilledRequiredFields(valid);
        // Write log
        const startTime = logFunctionStart(
            currentLogLevel,
            "handleSaveSchema"
          );
          try {
            if(!valid){
                setSchemaNameValidation({...schemaNameValidation, isSchemaNameEmpty: false, isSameSchemaName: false});
                if(schemaNameValidation.schemaName.trim() !== ""){
                    if(sameSchemaName === undefined){
                        const data: any = await dispatch(saveNewSchema(schemaNameValidation.schemaName, schemaValues));
                        if(data.messageCode === config.successCode){
                            /** This function for open snackbar */
                            setIsSchemaSavedSnackbarOpen(true);
                            setSuccessMsgIndication({isSchemaDataDownloaded: false, isSchemaSaved: true});
                            // Fetching List of Schema names for the same name validation
                            dispatch(getListOfSchemaNames());
                            writeLog(
                                logLevel.Information,
                                currentLogLevel,
                                "schemaSave",
                                schemaCreationLog.schemaSave
                            );
                        }else{
                            /** This state for open snackbar with Error Message */
                            setErrorMessage(data.userMessage);
                            setErrorMessageSnackbar(true);
                            writeLog(
                                logLevel.Error,
                                currentLogLevel,
                                "schemaSave error",
                                schemaCreationLog.schemaSave
                            );
                        }
                    }else{
                        setSchemaNameValidation({...schemaNameValidation, isSameSchemaName: true, isSchemaNameEmpty: false});
                    }
                }else{
                    setSchemaNameValidation({...schemaNameValidation, isSchemaNameEmpty: true, isSameSchemaName: false});
                }
            }
          } finally {
            logFunctionEnd(currentLogLevel, startTime, "handleSaveSchema");
          }
    }

    /** Storing the user Schema name into state */
    const schemaNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSchemaNameValidation({...schemaNameValidation, schemaName: e.target.value});
    }

    /** Storing the user schema data number of count into state */
    const dataCountHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDataCount(parseInt(e.target.value));
    }

    /** This function for close snackbar */
    const handleIsSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsSnackbarOpen(false);
        setIsSchemaSavedSnackbarOpen(false);
        setErrorMessageSnackbar(false);
    };

    /** We're doing undo schema from snackbar 
     * Deleted form back to store the schema form data state on the same placement
     * Closing snackbar
     */
    const undoHandler = () => {
        const items = Array.from(schemaValues);
        items.splice(currentDeletedDataIndex, 0, currentDeletedData);
        setSchemaValues(items);
        setIsSnackbarOpen(false);
    };


    return (
        <section className="schemaCreationSection testDBHead">
            <Grid container alignItems="center">
                <Grid item xs={12} md={5}>
                    <div className="schemaCreationName">
                        <label className="label1">
                            {intl.formatMessage({ id: "SchemaName"})} *
                        </label>
                        <TextField
                            placeholder={intl.formatMessage({ id: "PlaceHolderSchemaName"})}
                            value={schemaNameValidation.schemaName}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => schemaNameHandler(e)}
                            InputProps={{ classes: { root: classes.underline } }}
                        />
                        {schemaNameValidation.isSameSchemaName && <div className="key-helper-text">
                            <Grid container className="invalid-key" alignItems="center">
                                <Warning />
                                <p>{intl.formatMessage({ id: "SchemaNameExisit"})}</p>
                            </Grid>
                        </div>}
                        {schemaNameValidation.isSchemaNameEmpty && <div className="key-helper-text">
                            <Grid container className="invalid-key" alignItems="center">
                                <Warning />
                                <p>{intl.formatMessage({ id: "SchemNameRequired"})}</p>
                            </Grid>
                        </div>}
                    </div>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Grid container justifyContent="space-around" alignItems="center">
                        <Grid item>
                        {intl.formatMessage({ id: "Count"})}
                        </Grid>
                        <Grid item xs={5}>
                            <div className="count">
                                <FormControl>
                                    <TextField
                                        variant="outlined"
                                        value={dataCount}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => dataCountHandler(e)}
                                        type="number"
                                        inputProps={{ min: 0 }}
                                    />
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <div className="newDBConnectionBtns">
                                <button onClick={handleDownloadSchemaData} className={`${dataCount === 0 || isFileDownloading ? "disabledBtn": ""}`} disabled={dataCount === 0 || isFileDownloading}>
                                    <Typography>

                                        {isFileDownloading ? intl.formatMessage({ id: "Downloading"}) :  intl.formatMessage({ id: "DownloadData"})}
                                    </Typography>
                                </button>
                                <CSVLink
                                    data={syntheticData}
                                    ref={csvLinkRef}
                                    filename={"SyntheticData.csv"}
                                    target="_blank"
                                >
                                </CSVLink>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={2}>
                    <div className="schemaSaveBtn newDBConnectionBtns">
                        <button onClick={handleSaveSchema}>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                    <g data-name="Layer 8">
                                        <path d="M9,7V2H19V7a1,1,0,0,1-1,1H10A1,1,0,0,1,9,7ZM8,30H24V26.56H8Zm0-5.44H24v-2H8ZM29,8.7,24.37,3.09A3,3,0,0,0,22.06,2H21V7a3,3,0,0,1-3,3H10A3,3,0,0,1,7,7V2H5A3,3,0,0,0,2,5V27a3,3,0,0,0,3,3H6V20a3,3,0,0,1,3-3H23a3,3,0,0,1,3,3V30h1a3,3,0,0,0,3-3V10.92A3,3,0,0,0,29,8.7ZM23,19H9a1,1,0,0,0-1,1v.56H24V20A1,1,0,0,0,23,19Z" />
                                    </g>
                                </svg>
                                <Typography>
                                    Save
                                </Typography>
                            </div>
                        </button>
                    </div>
                </Grid>
            </Grid>

            {/* Drggable component and the schema form inputs section */}
            {schemaValues.length > 0 ?
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="schemaDragForm">
                        {(provided): JSX.Element => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {schemaValues.map((item: SchemaDataDetailsTypes, index: number): JSX.Element => (
                                    <Draggable
                                        key={index}
                                        draggableId={index.toString()}
                                        isDragDisabled={schemaValues.length === 1}
                                        index={index}
                                    >
                                        {(provided, snapshot): JSX.Element => (
                                            <div
                                                {...provided.draggableProps}
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                key={index}
                                                className={
                                                    snapshot.isDragging ? "selected" : "not-selected"
                                                }>
                                                <SchemaForm
                                                    item={item}
                                                    indexId={index}
                                                    deleteItem={deleteItem}
                                                    setSchemaValues={setSchemaValues}
                                                    isFilledRequiredFields={isFilledRequiredFields}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                :
                <>
                <br />
                <br />
                    <div>
                        Schema form is empty. Please use add field button to add the schema form
                    </div>
                </>
            }

            {/* Add Button */}
            <div className="addSchemaBtn">
                <button style={{ marginTop: "40px" }} onClick={addSchemaHandler}>
                    <div>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.3929 7.39286H10.9286C10.7511 7.39286 10.6071 7.24894 10.6071 7.07143V1.60714C10.6071 0.719604 9.88754 0 9 0C8.11246 0 7.39286 0.719604 7.39286 1.60714V7.07143C7.39286 7.24894 7.24894 7.39286 7.07143 7.39286H1.60714C0.719604 7.39286 0 8.11246 0 9C0 9.88754 0.719604 10.6071 1.60714 10.6071H7.07143C7.24894 10.6071 7.39286 10.7511 7.39286 10.9286V16.3929C7.39286 17.2804 8.11246 18 9 18C9.88754 18 10.6071 17.2804 10.6071 16.3929V10.9286C10.6071 10.7511 10.7511 10.6071 10.9286 10.6071H16.3929C17.2804 10.6071 18 9.88754 18 9C18 8.11246 17.2804 7.39286 16.3929 7.39286Z" fill="white" />
                        </svg>
                    </div>
                    <Typography>
                        Add Field
                    </Typography>
                </button>
            </div>

            {/* Available Function download button */}
            <Link href="https://xplatformcustomuib2c.blob.core.windows.net/root/Available-functions.xlsx" download className="AvailableFunctions">
                <Tooltip title="Download the Excel spreadsheet for the details of various functions & its parameters" arrow>
                    <Grid container alignItems="center">
                        <Grid item>
                            <ExcelIcon />
                        </Grid>
                        <Grid item>
                            <Typography>{intl.formatMessage({ id: "AvailableFunctions" })}</Typography>
                        </Grid>
                    </Grid>
                </Tooltip>
            </Link>

            {/* Material Snackbar  */}
            <Snackbar
                open={isSnackbarOpen}
                autoHideDuration={3000}
                onClose={handleIsSnackbarClose}
                message={intl.formatMessage({ id: "SchemaFormFieldRemoved" })}
                className="schemaUndoSection"
                action={
                    <React.Fragment>
                        <div className="newDBConnectionBtns">
                            <button onClick={undoHandler}>
                                <div>
                                    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.57484 10.4511L1.90297 6.24788L5.57484 2.04468C5.87156 1.70464 5.83695 1.18828 5.49691 0.891286C5.15687 0.594568 4.64078 0.62945 4.34352 0.969216L0.201914 5.71027C-0.0673047 6.01816 -0.0673047 6.47784 0.201914 6.78574L4.34355 11.5268C4.50539 11.7116 4.73184 11.8064 4.95961 11.8064C5.15035 11.8064 5.34187 11.7399 5.49691 11.6045C5.83699 11.3075 5.87184 10.7911 5.57484 10.4511Z" fill="white" />
                                        <path d="M14.0598 5.43047H0.817422C0.365938 5.43047 0 5.79637 0 6.24789C0 6.69937 0.365938 7.06531 0.817422 7.06531H14.0598C16.4338 7.06531 18.3652 8.9966 18.3652 11.3704C18.3652 13.7443 16.4338 15.6755 14.0598 15.6755H1.90734C1.45586 15.6755 1.08992 16.0415 1.08992 16.493C1.08992 16.9444 1.45586 17.3104 1.90734 17.3104H14.0598C17.3349 17.3104 20 14.6456 20 11.3704C20 8.09527 17.3352 5.43047 14.0598 5.43047Z" fill="white" />
                                    </svg>
                                    <Typography>
                                        {intl.formatMessage({ id: "Undo" })}
                                    </Typography>
                                </div>
                            </button>
                        </div>
                    </React.Fragment>
                }
            />
            {/* Data Downloaded indicator snackbar */}
            <Snackbar
                open={isSchemaSavedSnackbarOpen}
                autoHideDuration={3000}
                onClose={handleIsSnackbarClose}
                className="schemaSavedSnackbarSection"
                action={
                    <React.Fragment>
                        <Grid container alignItems="center">
                            <Grid item>
                                <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 0C6.76113 0 0 6.76113 0 15C0 23.2389 6.76113 30 15 30C23.2389 30 30 23.2389 30 15C30 6.76113 23.2389 0 15 0ZM13.1847 21.8227L6.61605 15.2541L9.10172 12.7684L13.2997 16.9664L21.7274 9.30516L24.0929 11.9058L13.1847 21.8227Z" fill="white" />
                                </svg>
                            </Grid>
                            <Grid>
                                <Typography>
                                    {successMsgIndication.isSchemaSaved ? <> <strong>{schemaNameValidation.schemaName}</strong> {intl.formatMessage({ id: "SchemSavedMsg" })} </>
                                    : successMsgIndication.isSchemaDataDownloaded ? intl.formatMessage({ id: "SchemDownloadedMsg" }) : null }
                                </Typography>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
            />
            <Snackbar autoHideDuration={6000} open={errorMessageSnackbar} onClose={handleIsSnackbarClose} className="errorMessageSnachbar">
                <Alert onClose={handleIsSnackbarClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
        </section>
    );
}

export default SchemaCreation;
