/**Imports of React libraries */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
/**Imports of Reusable Components */
import { RootState } from "../../store/reducers/rootReducer";
import Logger from "../logger/logger";
/**Imports of Material UI Elements */
import { Grid, Paper, Typography } from "@material-ui/core";
import { inmemoryLoading } from "../../../src/store/actions/landingPage/landingPage";
/**Imports of SVG Icons */
import { ReactComponent as BannerSvg } from "../../assets/images/avatars/banner.svg";
import { ReactComponent as AdminSVG } from "../../assets/images/avatars/admin-settings.svg";
import { ReactComponent as DBOperationSvg } from "../../assets/images/avatars/db-enable.svg";
import { ReactComponent as DBDisableSvg } from "../../assets/images/avatars/db.svg";
import { ReactComponent as DataOperationSvg } from "../../assets/images/avatars/do-enable.svg";
import { ReactComponent as ArrowSvg } from "../../assets/images/avatars/arrow.svg";
import { ReactComponent as ArrowDisableSvg } from "../../assets/images/avatars/arrow-inactive.svg";
import CheckedIconSvg from "../../assets/images/avatars/checked.svg";
/**Import of Config files */
import { landingPageLog, logLevel } from "../../config/logConfig";
// import { loginRequest } from "../../config/authConfig";
/**Import of Logger files */
import { logFunctionStart, logFunctionEnd, writeLog } from "../../logManager";

function LandingPage() {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  // const { instance } = useMsal();
  const currentLogLevel = useSelector(
    (state: RootState) => state.landingPage.currentLogLevel
  );
  //To check the loading condition of auth login
  // const authLoading = useSelector((state: RootState) => state.landingPage);

  // useEffect(() => {
  //   /** Condition of authlogin to stop redirecting to authlogin again */
  //   if (Object.keys(sessionStorage).length > 0) {
  //     Object.keys(sessionStorage).map((data: any) => {
  //       if (
  //         data.startsWith("msal.") &&
  //         data.endsWith(".request.correlationId")
  //       ) {
  //         dispatch(inmemoryLoading(false));
  //       }
  //     });
  //   }
  // }, []);
  const connectionStatus = useSelector(
    (state: RootState) => state.testDBdata.connectionButtonStatus
  );
  const currentConfigValue = useSelector(
    (state: RootState) => state.testDBdata.dbConfig
  );


  /**Function of AuthLogin for redirection*/
  // const handleLoading = async () => {
  //   await instance.loginRedirect();
  // };

  /** Function to Redirect to admin page while clicking Admin option */
  const handleAdmin = async () => {
    const startTime = logFunctionStart(currentLogLevel, "handleAdmin");

    try {
      history.push(`/admin`);
      writeLog(
        logLevel.Information,
        currentLogLevel,
        `Redirecting to Administration Page`,
        landingPageLog.handleAdmin
      );
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleAdmin");
    }
  };

  /**Function to Redirect to Database operation page while clicking Database Operation option*/
  const handleDB = async () => {
    const startTime = logFunctionStart(currentLogLevel, "handleDB");
    try {
      history.push(`/db`);
      writeLog(
        logLevel.Information,
        currentLogLevel,
        "Redirecting to Database Operation Page",
        landingPageLog.handleDB
      );
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleDB");
    }
  };
  /**Function to Redirect to Data operation page while clicking Data Operation option*/
  const handleData = () => {
    const startTime = logFunctionStart(currentLogLevel, "handleData");
    try {
      history.push(`/data`);
      writeLog(
        logLevel.Information,
        currentLogLevel,
        "Redirecting to Data Operation Page",
        landingPageLog.handleData
      );
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleData");
    }
  };

  // When user enters dataguru home page url, redirect to login interface
  // if (authLoading.isLoading === true) {
  //   return (
  //     <UnauthenticatedTemplate>
  //       <div onLoad={() => handleLoading()}>
  //         <div className="preloader">
  //           <div className="loaderVector"></div>
  //           <div className="loaderBrand">
  //             Data <br /> <span>Guru</span>
  //           </div>
  //         </div>
  //         <img
  //           hidden
  //           src={CheckedIconSvg}
  //           alt="hidden image"
  //           className="hide-landingimg"
  //         />
  //       </div>
  //     </UnauthenticatedTemplate>
  //   );
  // } else {
  return (
    <div className="landingpage-main">
      <div className="landingpageMainCon">
        <Typography className="banner-title">
          {" "}
          {intl.formatMessage({ id: "Banner-title" })}{" "}
        </Typography>
        <Typography className="banner-content bannerContentNew">
          {" "}
          {intl.formatMessage({ id: "Banner-content" })}{" "}
        </Typography>
      </div>
      <Grid container className="landingpage-items">
        <Grid item md={6} className="landing-banner">
          <div className="banner-img">
            <BannerSvg />
          </div>
        </Grid>
        <Grid item md={6} className="landing-menus">
          <div className="landing-options">
            <div className="landingSubTitle">
              <Typography>
                {intl.formatMessage({ id: "LandingPageSubTitle" })}
              </Typography>
            </div>
            <ul>
              <li>
                {intl.formatMessage({ id: "LandingPagePointOne" })}{" "}
                <span>
                  <strong>
                    {intl.formatMessage({ id: "LandingPagePointOneLineTwo" })}
                  </strong>
                </span>{" "}
                {intl.formatMessage({ id: "LandingPagePointOneLineThree" })}.
              </li>
              <li>
                {intl.formatMessage({ id: "LandingPagePointTwo" })}{" "}
                <span>
                  <strong>
                    {intl.formatMessage({ id: "LandingPagePointTwoLineTwo" })}
                  </strong>
                </span>
              </li>
              <li>
                {intl.formatMessage({ id: "LandingPagePointThree" })}{" "}
                <span>
                  <strong>
                    {intl.formatMessage({
                      id: "LandingPagePointThreeLineTwo",
                    })}
                  </strong>
                </span>
              </li>
              <li>
                <span>
                  <strong>
                    {intl.formatMessage({ id: "LandingPagePointFour" })}
                  </strong>
                </span>{" "}
                {intl.formatMessage({ id: "LandingPagePointFourLineTwo" })}
              </li>
              <li>
                <span>
                  <strong>
                    {intl.formatMessage({ id: "LandingPagePointFive" })}
                  </strong>
                </span>{" "}
                {intl.formatMessage({ id: "LandingPagePointFiveLineTwo" })}
              </li>
            </ul>
          </div>
        </Grid>
      </Grid>
      <Logger />
    </div>
  );
  //   }
}

export default LandingPage;
