import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter as Router } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./store/store";

import { PublicClientApplication } from "@azure/msal-browser";
// import { msalConfig } from "./config/authConfig";

import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core";

import App from "./App";
import "./css/index.css";

// Generate the msalInstance based on msalConfig
// export const msalInstance = new PublicClientApplication(msalConfig);

let theme = createTheme({
  palette: {
    primary: {
      main: "#006400",
    },
    secondary: {
      main: "#ffa500",
    },
  },
});

theme = responsiveFontSizes(theme);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <Provider store={store}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Provider>
    </Router>
  </ThemeProvider>,
  document.getElementById("root")
);
