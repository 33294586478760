// Log Type
export const logType = ["OFF", "Level 1", "Level 2  ", "All"];

// Log Levels
export const logLevel = {
  Debug: 4,
  Information: 3,
  Warning: 2,
  Error: 1,
  Critical: 0,
};
// Log Errors
export const logErr = {
  initializError: "Could not initialize Telemetry Service",
  KeyError: "Instrumentation key not provided in ./src/telemetry-provider.jsx",
};

// Debug Messages
export const landingPageLog = {
  handleAdmin: "File: LandingPage.tsx, Function: handleAdmin, LineNumber: 43",
  handleDB: "File: LandingPage.tsx, Function: handleDB, LineNumber: 59",
  handleData: "File: LandingPage.tsx, Function: handleData, LineNumber: 85",
};

export const selectWorkspaceLog = {
  PasswordInputChange:
    "File: selectworkspace.tsx, Function: handlePasswordInputChange, LineNumber: 62",
  ShowPassword:
    "File: selectworkspace.tsx, Function: handleShowPassword, LineNumber: 69",
  VerifyButton:
    "File: selectworkspace.tsx, Function: handleVerifyButton, LineNumber: 88",
  handleWorkspace:
    "File: selectworkspace.tsx, Function: handleWorkspace, LineNumber: 120",
  handleSetWorkspace:
    "File: selectworkspace.tsx, Function: handleWorkspace, LineNumber: 157",
};

export const administrationLog = {
  handleHome: "File: Administration.tsx, Function: handleHome, LineNumber: 81",
  handleTab: "File: Administration.tsx, Function: handleTab, LineNumber: 98",
};

export const viewJobsLog = {
  PageChange:
    "File: viewJobs.tsx, Function: handleTestDBPageChange, LineNumber: 39",
};

export const indexDataLog = {
  indexConfirmation:
    "File: reindexTestData.tsx, Function: indexConfirmation, LineNumber: 47",
  startIndex:
    "File: reindexTestData.tsx, Function: handleStartIndex, LineNumber: 63",
  closeIndex:
    "File: reindexTestData.tsx, Function: handleCloseIndex, LineNumber: 105",
};

export const generateDataLog = {
  generateData:
    "File: generateData.tsx, Function: handleGenerateData, LineNumber: 59",
  closepopup:
    "File: generateData.tsx, Function: closeGenerateData, LineNumber: 96",
  GDconfirmation:
    "File: generateData.tsx, Function: closeGenerateData, LineNumber: 114",
};

export const dataOperationLog = {
  handleHome: "File: dataOperation.tsx, Function: handleHome, LineNumber: 70",
  tabChange:
    "File: dataOperation.tsx, Function: handleTabChange, LineNumber: 87",
};

export const dbOperationLog = {
  handleHome: "File: dbOperation.tsx, Function: handleHome, LineNumber: 63",
  tabChange:
    "File: dbOperation.tsx, Function: handleDbOperationTabChange, LineNumber: 82",
};

export const workspaceLog = {
  SelectWorkspace:
    "File: Workspace.tsx, Function: handleSelectWorkspace, LineNumber: 32",
};

export const MainAppbarLog = {
  handleChange:
    "File: MainAppbarLog.tsx, Function: handleChange, LineNumber: 42",
  handleLogout:
    "File: MainAppbarLog.tsx, Function: handleLogout, LineNumber: 60",
};

export const setTestDBLog = {
  connectDB: "File: SetTestDB.tsx, Function: handleConnectDB, LineNumber: 45",
  handleClose: "File: SetTestDB.tsx, Function: handleClose, LineNumber: 72",
  handleSave: "File: SetTestDB.tsx, Function: handleSave, LineNumber: 125",
  handleTestDBConnection:
    "File: SetTestDB.tsx, Function: handleTestDBConnection, LineNumber: 80",
};

export const testDBLog = {
  testDBData:
    "File: testDB.tsx, Function: handleTestDBDataTable, LineNumber: 80",
  clearAll:
    "File: testDB.tsx, Function: handleClearTestDBSelectedbutton, LineNumber: 113",
  deleteConfirmation:
    "File: testDB.tsx, Function: handleTestDbDeletePopupOpenbutton, LineNumber: 140",
  selectAllItems:
    "File: testDB.tsx, Function: handleSelectAllTestDBRecords, LineNumber: 163",
  closeConfirmation:
    "File: testDB.tsx, Function: handleClosePopupButton, LineNumber: 189",
  deleteRecords:
    "File: testDB.tsx, Function: handleDeleteTestRecords, LineNumber: 208",
  closeWarning:
    "File: testDB.tsx, Function: handleDeleteConfirmationClosePopupButton, LineNumber: 254",
  closeSuccess:
    "File: testDB.tsx, Function: handleSuccessPopupClose, LineNumber: 281",
  selectPerPageRecords:
    "File: testDB.tsx, Function: onSelectAllClick, LineNumber: 300",
  selectRecords:
    "File: testDB.tsx, Function: onSelectTestDBRecord, LineNumber: 382",
  connectDB: "File: testDB.tsx, Function: handleConnectDB, LineNumber: 463",
  disconnectDB: "File: testDB.tsx, Function: handleDisConnectDB, LineNumber: 499",
};

export const searchLog = {
  handleCategories:
    "File: SearchOperation.tsx, Function: handleCategories, LineNumber: 80",
  handleExpansion:
    "File: SearchOperation.tsx, Function: handleExpansion, LineNumber: 116",
  onChangeSearch:
    "File: SearchOperation.tsx, Function: onChangeSearch, LineNumber: 149",
  handleFacet:
    "File: SearchOperation.tsx, Function: handleFacet, LineNumber: 181",
  handleSearch:
    "File: SearchOperation.tsx, Function: handleSearch, LineNumber: 270",
};

export const syntheticLog = {
  businessObjects:
    "File: SyntheticOperation.tsx, Function: handleSyntheticDataObjects, LineNumber: 65",
  dataCount:
    "File: SyntheticOperation.tsx, Function: handleSyntheticDataCount, LineNumber: 85",
  generateData:
    "File: SyntheticOperation.tsx, Function: handleSyntheticDataGenerate, LineNumber: 105",
};

export const authenticationLog = {
  getTokenAndValidateUser:
    "File: App.tsx, Function: getTokenAndValidateUser, LineNumber: 79",
};

export const AutoLogoutLog = {
  handleLogout: "File: AutoLogout.tsx, Function: handleLogout, LineNumber: 51",
};

export const HandleExtendLog = {
  handleExtend:
    "File: TokenExpiryLogout.tsx, Function: handleExtend, LineNumber: 66",
};

export const dbProfileLog = {
  dbProfileGenerateData:
    "File: dataProfiling.tsx, Function: handleDBProfileGenerateData, LineNumber: 108",
};

export const dbRecordEditLog = {
  dbRecordEditData:
    "File: listsOfDBs.tsx, Function: handleDBConnectionEdit, LineNumber: 72",
};

export const dbRecordDeleteLog = {
  dbRecordDeleteData:
    "File: listsOfDBs.tsx, Function: handleIsOpenDeletePopup, LineNumber: 98",
};

export const schemaCreationLog = {
  schemDownload: 
  "File: schemaCreation.jsx, Function: handleDownloadSchemaData, LineNumber: 209",
  schemaSave:
  "File: schemaCreation.jsx, Function: handleSaveSchema, LineNumber: 246"
}

export const queryManagerLog = {
  addOrEditQuery: 
  "File: addQueryPopup.jsx, Function: handleSave, LineNumber: 216",
  deleteQuery:
  "File: listOfQueries.jsx, Function: handleQueryDelete, LineNumber: 78",
  testQuery:
  "File: addQueryPopup.jsx, Function: handleTest, LineNumber: 150"
}