import {
  SEARCH_DATA_RESULT,
  SYNTHETIC_DATA_RESULT,
  SELECTED_BUSINESS_OBJECT_VALUE,
  COUNT_VALUE,
  CLEAR_SEARCH_RECORD,
  GET_SCHEMA_NAME
} from "../../actions/actiontypes";

const initialState = {
  searchResult: [],
  syntheticData: [],
  businessObjectValue: "",
  countValue: 0,
  schemaNames: []
};

export default function dataOperationReducer(
  state = initialState,
  action: any
) {
  switch (action.type) {
    case SEARCH_DATA_RESULT:
      return {
        ...state,
        searchResult: action.payload,
      };
    case SYNTHETIC_DATA_RESULT:
      return {
        ...state,
        syntheticData: action.payload,
      };
    case SELECTED_BUSINESS_OBJECT_VALUE:
      return {
        ...state,
        businessObjectValue: action.payload,
      };
    case COUNT_VALUE:
      return {
        ...state,
        countValue: action.payload,
      };
    case CLEAR_SEARCH_RECORD:
      return {
        ...state,
        searchResult: [],
      };
      case GET_SCHEMA_NAME:
        return {
          ...state,
          schemaNames: action.payload
        }
    default:
      return state;
  }
}
