import { logLevel } from "./config/logConfig";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

/**Global Variables Declaration*/
let appInsights: any;

/// Asssigning appInsights object to global variable
export function aiFunction(ai: any) {
  appInsights = ai;
}

/// logging message at the entry point to the function
export function logFunctionStart(
  currentLogLevel: number,
  functionName: string
) {
  const startTime = new Date();
  if (currentLogLevel >= logLevel.Information) {
    appInsights.trackTrace({
      message: `${functionName} started`,
      severityLevel: SeverityLevel.Information,
    });
  }
  return startTime;
}

// Log the end of the function including the total time spent in the function
export function logFunctionEnd(
  currentLogLevel: number,
  startTime: any,
  functionName: string
) {
  if (currentLogLevel >= logLevel.Information) {
    const lastTime: any = new Date();
    const seconds = Math.round((lastTime - startTime) / 1000);
    appInsights.trackTrace({
      message: `${functionName} finished, total time = ${seconds} seconds`,
      severityLevel: SeverityLevel.Information,
    });
  }
}

/// This function will write an exception to the log at Error level including the inner exception and the stack trace.
export function writeExceptionLog(currentLogLevel: number, Exception: any) {
  if (currentLogLevel >= logLevel.Error) {
    appInsights.trackException({
      error: new Error(Exception),
      severityLevel: SeverityLevel.Error,
    });
  }
}

// Log the function Details based on log levels
export function writeLog(
  appLogLevel: number,
  currentLogLevel: number,
  message: string,
  stackTrace: string
) {
  let debugMessage: string;
  if (currentLogLevel >= appLogLevel && appInsights !== undefined) {
    switch (currentLogLevel) {
      case logLevel.Critical:
        appInsights.trackException({
          severityLevel: SeverityLevel.Critical,
        });
        break;
      case logLevel.Error:
        appInsights.trackException({
          severityLevel: SeverityLevel.Error,
        });
        break;
      case logLevel.Warning:
        appInsights.trackTrace({
          message: message,
          severityLevel: SeverityLevel.Warning,
        });
        break;
      case logLevel.Information:
        appInsights.trackTrace({
          message: message,
          severityLevel: SeverityLevel.Information,
        });
        break;
      // stackTrace - Details from logConfig file (File Name,Function,Line Number)
      case logLevel.Debug:
        debugMessage =
          "Message :" + message + " " + "StackTrace :" + stackTrace;
        appInsights.trackTrace({
          message: debugMessage,
          severityLevel: SeverityLevel.Verbose,
        });
        break;
    }
  }
}
