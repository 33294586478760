import { DB_PROFILE } from "../actiontypes";
import fetch from "../../../utils/leoFetch";
import { config } from "../../../config/clientConfig";
import { ITokenAction } from "../../../interfaces";
import axios, { AxiosError } from "axios";
import { Dispatch } from "redux";

/** Call Backend API and pass user selected db */

export const dbProfileGenerateDataAction =
  (dbConnectionValue: string) => () => {
    return new Promise((resolve, reject) => {
      fetch(
        config.userDBPowerBIurl +
          "&dbconnection=" +
          dbConnectionValue +
          "&username=" +
          localStorage.getItem("username"),
        {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );
      // .then((res) => res.json())
      // .then((res) => {
      //   dispatch({
      //     payload: res.data,
      //     type: DB_PROFILE,
      //   });
      //   return resolve(res);
      // });
    });
  };

/** generate data profile */
export const DataProfile = (payload: any) => async (dispatch: Dispatch) => {
  const response = await axios({
    method: "POST",
    url: config.apiRootPath + config.generateDataProfile,
    data: payload,
  })
    .then((res) => res.data)
    .catch((err: AxiosError) => err.response?.data);
  return response;
};
