/**Imports of React libraries */
import { useIntl } from "react-intl";
/**Imports of Material UI Elements */
import { Typography } from "@material-ui/core";

/** Impoty of data profilingcompoenet */
import DataProfiling from "../DataOperation/dataProfiling";

function DataProfilingIndex() {
  const intl = useIntl();

  return (
    <div>
      <div className="TestDBTitle">
        <Typography className="tabtextTestDB">
          {intl.formatMessage({ id: "Report" })}
        </Typography>
      </div>
      <div className="midContentShadowTestDB">
        <DataProfiling />
      </div>
    </div>
  );
}

export default DataProfilingIndex;
