/**Imports of React libraries */
import { useIntl } from "react-intl";
/**Imports of Material UI Elements */
import { Link, Typography } from "@material-ui/core";
// Icons
import { ReactComponent as SupportIcon } from "../../assets/images/avatars/support-graphic.svg";

function Support() {
  const intl = useIntl();
  return (
    <section className="supportSection">
      <div className="supportSectionBox">
        <div className="supportSectionHead">
          <Typography>{intl.formatMessage({ id: "SupportTitle" })}</Typography>
        </div>
        <div className="supportSectionBody">
          <Typography>
            {intl.formatMessage({ id: "SupportFirstContent" })}{" "}
            <Link href="mailto:xplatform@expleogroup.com">
              xplatform@expleogroup.com
            </Link>{" "}
            {intl.formatMessage({ id: "SupportSecondContent" })}
          </Typography>
          <div className="supportSectionBodyIns">
            <Typography>
              {intl.formatMessage({ id: "SupportThreeContent" })}
            </Typography>
          </div>
          <div className="supportSectionBodyBtn">
            <Link
              href="https://expleosolutions.freshdesk.com/support/login"
              target="_blank"
            >
              {intl.formatMessage({ id: "SupportButton" })}
            </Link>
          </div>
        </div>
      </div>
      <div className="supportSectionIcon">
        <SupportIcon />
      </div>
    </section>
  );
}

export default Support;
