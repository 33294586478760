/**Imports of React libraries */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
/**Imports of Reusable Components */
import { RootState } from "../../store/reducers/rootReducer";
/**Imports of Material UI Elements */
import {
  TextField,
  Typography,
  Grid,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  IconButton,
  Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
/**Imports of API Actions */
import {
  getWorkspace,
  getDataSource,
  inmemorySetWorkspace,
  inmemoryCurrentWorkspace,
  inmemoryApikey,
  inmemoryVerifyState,
} from "../../store/actions/selectWorkspace/selectWorkspaceAction";
/**Imports of Material UI Icons */
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
/**Imports of SVG Icons */
import { ReactComponent as Warning } from "../../assets/images/avatars/Invalid.svg";
import { ReactComponent as Verified } from "../../assets/images/avatars/verify.svg";
/**Import of Config files */
import { config } from "../../config/clientConfig";
import { logLevel, selectWorkspaceLog } from "../../config/logConfig";
/**Import of Logger files */
import {
  logFunctionStart,
  logFunctionEnd,
  writeExceptionLog,
  writeLog,
} from "../../logManager";

function SelectWorkspace() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const verifiedData = useSelector((state: RootState) => state.selectWorkspace);
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
    workspaceDetails: {},
    showDataSource: false,
  });
  const currentLogLevel = useSelector(
    (state: RootState) => state.landingPage.currentLogLevel
  );

  /** Method of API Key OnChange */
  const handlePasswordInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValues({ ...values, password: e.target.value });
  };

  /** Method to Show API Key */
  const handleShowPassword = () => {
    const startTime = logFunctionStart(
      currentLogLevel,
      "handlePasswordInputChange"
    );
    try {
      setValues({ ...values, showPassword: !values.showPassword });
      writeLog(
        logLevel.Information,
        currentLogLevel,
        "Hide/Unhide Api Key",
        selectWorkspaceLog.ShowPassword
      );
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handlePasswordInputChange");
    }
  };

  /** Function to get workspace list by dispatching API Call */
  const handleVerifyButton = async () => {
    const startTime = logFunctionStart(currentLogLevel, "handleVerifyButton");
    dispatch(inmemoryApikey(values.password));
    try {
      const verifiedstatus: any = await dispatch(getWorkspace(values.password));
      if (verifiedstatus !== undefined) {
        if (
          Object.keys(verifiedstatus).length !== 0 &&
          verifiedstatus.messageCode === config.successCode
        ) {
          dispatch(inmemoryVerifyState(true));
          writeLog(
            logLevel.Information,
            currentLogLevel,
            verifiedstatus.userMessage,
            selectWorkspaceLog.VerifyButton
          );
        } else {
          dispatch(inmemoryVerifyState(false));
          writeLog(
            logLevel.Error,
            currentLogLevel,
            verifiedstatus.userMessage,
            selectWorkspaceLog.VerifyButton
          );
        }
      }
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleVerifyButton");
    }
  };
  /** Function to get Datasource Values by dispatching API Call */
  const handleWorkspace = async (
    e: Record<string, unknown>,
    value: Record<string, unknown>
  ) => {
    const startTime = logFunctionStart(currentLogLevel, "handleWorkspace");
    try {
      if (value && value !== undefined) {
        const DataSource: any = await dispatch(
          getDataSource(value.id, verifiedData.apikey)
        );
        if (DataSource !== undefined) {
          setValues({
            ...values,
            workspaceDetails: value,
            showDataSource: true,
          });
          dispatch(inmemorySetWorkspace(false));
          writeLog(
            logLevel.Information,
            currentLogLevel,
            DataSource.userMessage,
            selectWorkspaceLog.handleWorkspace
          );
        } else {
          writeLog(
            logLevel.Error,
            currentLogLevel,
            DataSource.userMessage,
            selectWorkspaceLog.handleWorkspace
          );
        }
      }
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleWorkspace");
    }
  };

  /** Function to Set Workspace and get Jobs details */
  const handleSetWorkspace = async (event: React.FormEvent) => {
    const startTime = logFunctionStart(currentLogLevel, "handleSetWorkspace");
    try {
      event.preventDefault();
      dispatch(inmemorySetWorkspace(true));
      dispatch(inmemoryCurrentWorkspace(values.workspaceDetails));
      setValues({ ...values, workspaceDetails: event, showDataSource: true });
      writeLog(
        logLevel.Information,
        currentLogLevel,
        "Workspace Selected",
        selectWorkspaceLog.handleSetWorkspace
      );
    } finally {
      logFunctionEnd(currentLogLevel, startTime, "handleSetWorkspace");
    }
  };

  return (
    <Grid className="setworkspace-header">
      <Grid className="setworkspace-title">
        <Typography
          className={
            verifiedData.Workspacelist.messageCode === config.successCode
              ? "key-header"
              : "key-header-validate"
          }
        >
          {intl.formatMessage({ id: "API" })}
        </Typography>
        <Grid className="key-container">
          <Grid className="key-container-header">
            <FormControl className="key-header-control">
              <Input
                type={values.showPassword ? "text" : "password"}
                value={
                  verifiedData.apikey && verifiedData.isVerify === true
                    ? verifiedData.apikey
                    : values.password
                }
                inputProps={{ "aria-label": "APIKey" }}
                onChange={handlePasswordInputChange}
                className={
                  verifiedData.Workspacelist.messageCode === config.failureCode
                    ? "key-header-error"
                    : "key-header-input"
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText className="key-helper-text">
                {verifiedData.Workspacelist.messageCode ===
                config.failureCode ? (
                  <Grid className="invalid-key">
                    <Warning />
                    {intl.formatMessage({ id: "Invalid" })}
                  </Grid>
                ) : null}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid className="key-verify-title">
            <Button
              className={
                verifiedData.Workspacelist.messageCode === config.successCode
                  ? "key-verify-enable"
                  : "key-verify"
              }
              variant="contained"
              onClick={handleVerifyButton}
              endIcon={
                verifiedData.Workspacelist.messageCode ===
                config.successCode ? (
                  <Verified />
                ) : null
              }
              disabled={
                verifiedData.Workspacelist.messageCode === config.successCode
                  ? true
                  : false
              }
            >
              {verifiedData.Workspacelist.messageCode === config.successCode
                ? intl.formatMessage({ id: "Verified" })
                : intl.formatMessage({ id: "Verify" })}
            </Button>
          </Grid>
        </Grid>
        <Typography
          className={
            verifiedData.Workspacelist.messageCode === config.successCode
              ? "key-header"
              : "key-header-disable"
          }
        >
          {intl.formatMessage({ id: "Workspace" })}
        </Typography>
        <Grid className="key-select-container">
          <Autocomplete
            className="key-select"
            disabled={
              verifiedData.Workspacelist.messageCode === config.successCode
                ? false
                : true
            }
            options={verifiedData.Workspacelist.data}
            defaultValue={
              verifiedData.Workspacelist.messageCode === config.successCode
                ? verifiedData.currentWorkspace
                : null
            }
            disableClearable
            getOptionLabel={(option) => option.fingerprintName}
            onChange={(event: any, data: any) => {
              handleWorkspace(event, data);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  "data-testid": "workspace",
                }}
              />
            )}
          />
        </Grid>
        <Typography
          className={
            verifiedData.Workspacelist.messageCode === config.successCode &&
            values.showDataSource === true
              ? "key-headers"
              : "key-headers-disable"
          }
        >
          {intl.formatMessage({ id: "Datasource" })}
        </Typography>
        {verifiedData.dataSourceValue.length > 0 &&
        verifiedData.dataSourceValue !== undefined &&
        (Object.keys(values.workspaceDetails).length > 0 ||
          Object.keys(verifiedData.currentWorkspace).length > 0) ? (
          <Grid className="key-comments-data">
            <Typography className="key-comments-value">
              Source Database
            </Typography>
            <Typography className="key-comments-key">
              ID :{" "}
              <span className="key-comments-value">
                {verifiedData.dataSourceValue[0].sourceDatabase.id}
              </span>
            </Typography>
            <Typography className="key-comments-key">
              Server :{" "}
              <span className="key-comments-value">
                {verifiedData.dataSourceValue[0].sourceDatabase.server}
              </span>
            </Typography>
            <Typography className="key-comments-key">
              Username :{" "}
              <span className="key-comments-value">
                {
                  verifiedData.dataSourceValue[0].sourceDatabase
                    .tonic_source_username
                }
              </span>
            </Typography>
            <Typography className="key-comments-key">
              Password :{" "}
              <span className="key-comments-value">
                {
                  verifiedData.dataSourceValue[0].sourceDatabase
                    .tonic_source_password
                }
              </span>
            </Typography>
            <Typography className="key-comments-key">
              Port :{" "}
              <span className="key-comments-value">
                {verifiedData.dataSourceValue[0].sourceDatabase.port}
              </span>
            </Typography>
            <Typography className="key-comments-key">
              Database :{" "}
              <span className="key-comments-value">
                {verifiedData.dataSourceValue[0].sourceDatabase.database}
              </span>
            </Typography>
            <Typography className="key-comments-key">
              DatabaseType :{" "}
              <span className="key-comments-value">
                {verifiedData.dataSourceValue[0].sourceDatabase.databaseType}
              </span>
            </Typography>
            <Typography className="key-comments-value">
              Destination Database
            </Typography>
            <Typography className="key-comments-key">
              ID :{" "}
              <span className="key-comments-value">
                {verifiedData.dataSourceValue[0].destinationDatabase.id}
              </span>
            </Typography>
            <Typography className="key-comments-key">
              Server :{" "}
              <span className="key-comments-value">
                {verifiedData.dataSourceValue[0].destinationDatabase.server}
              </span>
            </Typography>
            <Typography className="key-comments-key">
              Username :{" "}
              <span className="key-comments-value">
                {
                  verifiedData.dataSourceValue[0].destinationDatabase
                    .tonic_destination_username
                }
              </span>
            </Typography>
            <Typography className="key-comments-key">
              Password :{" "}
              <span className="key-comments-value">
                {
                  verifiedData.dataSourceValue[0].destinationDatabase
                    .tonic_destination_password
                }
              </span>
            </Typography>
            <Typography className="key-comments-key">
              Port :{" "}
              <span className="key-comments-value">
                {verifiedData.dataSourceValue[0].destinationDatabase.port}
              </span>
            </Typography>
            <Typography className="key-comments-key">
              Database :{" "}
              <span className="key-comments-value">
                {verifiedData.dataSourceValue[0].destinationDatabase.database}
              </span>
            </Typography>
            <Typography className="key-comments-key">
              DatabaseType :{" "}
              <span className="key-comments-value">
                {
                  verifiedData.dataSourceValue[0].destinationDatabase
                    .databaseType
                }
              </span>
            </Typography>
          </Grid>
        ) : (
          <TextField
            disabled={
              verifiedData.Workspacelist.messageCode === config.successCode &&
              values.showDataSource
                ? false
                : true
            }
            className="key-comments"
            multiline
            rows={10}
          />
        )}
        <Grid className="set-workspace-container">
          <Button
            className={
              verifiedData.Workspacelist.messageCode === config.successCode &&
              values.showDataSource &&
              !verifiedData.setWorkspace
                ? "set-workspace"
                : "set-workspace-disable"
            }
            variant="contained"
            disabled={
              verifiedData.Workspacelist.messageCode === config.successCode &&
              values.showDataSource &&
              !verifiedData.setWorkspace
                ? false
                : true
            }
            onClick={handleSetWorkspace}
          >
            {intl.formatMessage({ id: "Setworkspace" })}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SelectWorkspace;
